
import Vue from "vue";
import SetLocation from '@/components/Map/SetLocation.vue'
import MainDialogCard from "../MainDialogComponents/MainDialogCard.vue"
import { mapState } from "vuex";


export default Vue.extend({
  components: {
    "main-dialog-card": MainDialogCard,
    "set-location": SetLocation
  },

  computed: {
    ...mapState('ui', ['isMobileViewDisplayed'])
  }
});
