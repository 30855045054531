import moment from "moment";

export default class ScheduleSlot {
    
  id: number = 0;                                      // slot id Int value share with server
  day: number = 0;                                     // slot day of the week id
  date: string = "";                                     // slot day of the week id
  startTime: string = "";                          // slot start time
  endTime: string = "";                            // slot start time
  isAvailable: boolean = false;                            // number of current scheduled orders 
  remainingCapacity: number = 0;                       // max number of current scheduled orders 
  isFistAvailableSlot: boolean = false;            // defines if the current slot is the first available one 
  isInstant: boolean = false;
  instantThreshold: number = 0;
  e2EProcessingTimeInMin: number = 0;
  e2EProcessingTimeInMinLow: number = 0;
  e2EProcessingTimeInMinHigh: number = 0;
  eta: number = 0;


  static fetchScheduleSlotObjectFromJson(slot: any): ScheduleSlot {
    var scheduleSlot = {} as ScheduleSlot;

    scheduleSlot.id = slot.id;
    scheduleSlot.day = slot.day;
    scheduleSlot.date = slot.date;
    scheduleSlot.startTime = slot.start;
    scheduleSlot.endTime = slot.end;
    scheduleSlot.isAvailable = slot.isAvailable;
    scheduleSlot.remainingCapacity = slot.remainingCapacity;
    scheduleSlot.isFistAvailableSlot = slot.isFistAvailableSlot;
    scheduleSlot.isInstant = slot.isInstant;
    scheduleSlot.instantThreshold = slot.instantThreshold;
    scheduleSlot.e2EProcessingTimeInMin = slot.e2EProcessingTimeInMin;
    scheduleSlot.e2EProcessingTimeInMinLow = slot.e2EProcessingTimeInMinLow;
    scheduleSlot.e2EProcessingTimeInMinHigh = slot.e2EProcessingTimeInMinHigh;
    scheduleSlot.eta = slot.eta;

    return scheduleSlot;
  }
}