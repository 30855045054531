
import ProductMappingBySubcategoryAndType from "@/models/ProductMappingBySubcategoryAndType";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({

  props: {
    items: {
      required: true,
      type: Array
    },

    isItemsByGroup: {
      required: false,
      default: true,
      type: Boolean
    },

    loading: {
      required: false,
      default: false,
      type: Boolean
    },

    selectedgroupId: {
      required: true,
    }
  },

  
  data: () => ({
    type: 0 as number
  }),

  computed: {
    ...mapState("loading", ["listOfItemsLoading"]),
    ...mapState("ui", ["isMobileViewDisplayed"]),

    isLoading(): boolean {
      return this.loading == true || (this as any).listOfItemsLoading == true
    },

    selectedgroupIdModel: {
      get(): any {
        return this.selectedgroupId;
      },

      set(value: any) {
        this.$emit("changed", value);
      }
    }
  },

  model: {
    event: "changed",
    prop: "selectedgroupId"
  },


  methods: {
    getTypesFromItem(): Array<object> {
      if(this.isItemsByGroup == true)
        return (this.items as ProductMappingBySubcategoryAndType[]).map((item: ProductMappingBySubcategoryAndType) => { return {id: item.groupId, name: item.groupName} } );

      else {
        var typesToReturn = [] as object[];

        (this.items as any[]).forEach(item => {
          if(!typesToReturn.find((x: any) => x.id ==  item.itemTypeId )) {
            typesToReturn.push({id: item.itemTypeId, name: item.itemTypeName})
          }
        });


        return typesToReturn;
      }
    },

    onItemGroupSelected(groupId: number) {
      this.selectedgroupIdModel = groupId;   
    }
      
  },

  watch: {
    items() {
      this.selectedgroupIdModel = 0;
      this.type = 0;
    }
  }

});
