import { Module } from "vuex";
import { RootState } from "..";
import _requestHelper from "@/apiManager/_requestHelper";
import { Apis } from "@/apiManager/Apis";

export interface EWalletState extends RootState {
    eWallet: object
}

export default <Module<EWalletState, RootState>>{
    namespaced: true,
    state: {
        eWallet: {} as any
    },

    mutations: {
        FETCH_EWALLET(state, eWallet: any) {
            if(typeof eWallet == "undefined")
                state.eWallet = {};
            
            state.eWallet = eWallet;
        }
    },

    actions: {
        
        getEWallet({ commit }, params) {
            _requestHelper({
                method: "POST",
                url: Apis.ewalletBalance,
                data: params,
                loaderKey: "",
                successCallback: (eWallet: any) => {
                    commit("FETCH_EWALLET", eWallet)
                }
            })
        }

    }
}