
import Vue from 'vue'
export default Vue.extend({
    props: {
        item: {
            required: true
        },

        fontSize: {
            required: false,
            default: 10,
            type: Number
        }
    },


    computed: {
        style(): string {
            return `font-size: ${this.fontSize}px`
        }
    }
})
