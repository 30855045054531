
import Vue from "vue";
import HeaderBar from "@/components/UI/HeaderBar/UIHeaderBar.vue";
import AccountSettings from "@/components/Account/AccountSettings.vue";
import DeliverySettings from "@/components/Account/DeliverySettings.vue";
import UITitle from "@/components/UI/Text/UITitle.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import FirebaseAnalyticsScreensNames from "@/firebase/firebaseAnalyticsScreensNames";
import User from "@/models/User";

export default Vue.extend({
  components: {
    "header-bar": HeaderBar,
    AccountSettings,
    DeliverySettings,
    "ui-title": UITitle,
  },

  data: () => ({    
    listItemChosen: 0,
    listItemChosenItemName: "Account Settings",
  }),

  computed: {
    ...mapState("partner", ["appDataContent"]),
    ...mapGetters("auth", ["currentSelectedUser"]),

    user(): User {
      return this.currentSelectedUser as User;
    },

    listItemsTitle(): any[] {
      return [
        {text: this.appDataContent.personalInfoTitle, icon: "account-circle-outline", value: 0},
        // {text: "Delivery Settings", icon: "account-circle-outline", value: 1},
        // {text: "Loyalty Program", icon: "account-circle-outline", value: 2},
      ];   
    }
  },

  methods: {
    ...mapMutations("firebase", ["LOG_FIREBASE_SCREEN"]),
  },

  mounted() {
    // firebase analytics
    this.LOG_FIREBASE_SCREEN(FirebaseAnalyticsScreensNames.profilePage);
  }
});
