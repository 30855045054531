import FirebaseAnalyticsScreensNames from "@/firebase/firebaseAnalyticsScreensNames";

export interface routeInterface {
    name: string,
    path: string,
    firebaseScreenName: string
}

export default class Routes {
    static index: routeInterface = {name: "index", path: "index", firebaseScreenName: FirebaseAnalyticsScreensNames.indexPage};
    static loginOrGuest: routeInterface = {name: "loginOrGuest", path: "login-guest", firebaseScreenName: FirebaseAnalyticsScreensNames.indexPage }
    static login: routeInterface = {name: "login", path: "login", firebaseScreenName: FirebaseAnalyticsScreensNames.loginPage};
    static register: routeInterface = {name: "register", path: "register", firebaseScreenName: FirebaseAnalyticsScreensNames.registerPage};
    static forgotPassword: routeInterface = {name: "forgotPassword", path: "forgot-password", firebaseScreenName: FirebaseAnalyticsScreensNames.forgotPasswordPage};
    static location: routeInterface = {name: "location", path: "location", firebaseScreenName: FirebaseAnalyticsScreensNames.forgotPasswordPage};
    static profile: routeInterface = {name: "profile", path: "profile", firebaseScreenName: FirebaseAnalyticsScreensNames.profilePage};
    static contactUs: routeInterface = {name: "contactUs", path: "contact-us", firebaseScreenName: FirebaseAnalyticsScreensNames.contactUsPage};
    static stores: routeInterface = {name: "stores", path: "stores", firebaseScreenName: FirebaseAnalyticsScreensNames.storesPage};
    static storeDetails: routeInterface = {name: "storeDetails", path: "/store/:branchName/details", firebaseScreenName: FirebaseAnalyticsScreensNames.storeDetailsPage};
    static orders: routeInterface = {name: "orders", path: "orders", firebaseScreenName: FirebaseAnalyticsScreensNames.ordersPage};
    static storeHomePage: routeInterface = {name: "storeHomePage", path: "/store/:branchName/:itemId?/:itemName?", firebaseScreenName: FirebaseAnalyticsScreensNames.storeHomePage};
    static productsByCategory: routeInterface = {name: "productsByCategory", path: "/categories/:categoryId/:category/:itemId?/:itemName?", firebaseScreenName: FirebaseAnalyticsScreensNames.productsByCategoryPage};
    static productsBySubCategory: routeInterface = {name: "productsBySubCategory", path: "/categories/:categoryId/:category/:subcat/:itemId?/:itemName?", firebaseScreenName:  FirebaseAnalyticsScreensNames.productsBySubCategoryPage};
    static productsByCollection: routeInterface = {name: "productsByCollection", path: "/store/:branchName/:collectionName/:itemId?/:itemName?", firebaseScreenName:  ""}
    static productsBySearch: routeInterface = {name: "productsBySearch", path: "/store/:branchName/search/:search", firebaseScreenName:  ""}
    static productsBySection: routeInterface = {name: "productsBySection", path: "/store/:branchName/section/:section", firebaseScreenName:  ""}
    static productsBySearchBySubcat: routeInterface = {name: "productsBySearchBySubcat", path: "/store/:branchName/search/:subcat", firebaseScreenName:  ""}
    static wallet: routeInterface = {name: "wallet", path: "wallet", firebaseScreenName:  ""}
    static loyaltyProgram: routeInterface = {name: "loyalty", path: "loyalty", firebaseScreenName:  ""}

    static checkout: routeInterface = {name: "checkout", path: "/store/:branchName/checkout", firebaseScreenName:  FirebaseAnalyticsScreensNames.checkoutPage};
    static orderSummary: routeInterface = {name: "orderSummary", path: "/orders/:orderId", firebaseScreenName: FirebaseAnalyticsScreensNames.orderSummaryPage};
    // static notFound: routeInterface = {name: "notFound", path: "*", firebaseScreenName: FirebaseAnalyticsScreensNames.notFoundPage};
}