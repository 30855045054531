// Enum equivalent in TypeScript
export enum ECategorizationLayer {
    Department = 1,
    Category,
    Subcategory,
    Type
}

export enum ECategorizationLayerGroup {
    GroupOne = 1,
    GroupTwo,
    GroupThree,
    GroupFour
}

// Class equivalent in TypeScript
export class BranchCategorizationsAndLayersStructureService {
    // Static properties
    public static XXPPP: string = "XXPPP"; // Categories P, subcategories P, types P
    public static XXGGP: string = "XXGGP"; // (Categories, subcategories) G, types P

    public static XXPPX: string = "XXPPX"; // Categories P, subcategories P

    public static XPPPP: string = "XPPPP"; // Departments P, Categories P, subcategories P, types P
    public static XGGPP: string = "XGGPP"; // (Departments, Categories) G, subcategories P, types P
    public static XPGGP: string = "XPGGP"; // Departments P, (Categories, subcategories) G, types P

    public static XPPPX: string = "XPPPX"; // Departments P, Categories P, subcategories P
    public static XGGPX: string = "XGGPX"; // (Departments, Categories) G, subcategories P

    

    // Group 1: categories, subcategories, types
    public static readonly GroupOne: string[] = [BranchCategorizationsAndLayersStructureService.XXPPP, BranchCategorizationsAndLayersStructureService.XXGGP];

    // Group 2: categories, subcategories
    public static readonly GroupTwo: string[] = [BranchCategorizationsAndLayersStructureService.XXPPX];

    // Group 3: departments, categories, subcategories, types
    public static readonly GroupThree: string[] = [BranchCategorizationsAndLayersStructureService.XPPPP, BranchCategorizationsAndLayersStructureService.XGGPP, BranchCategorizationsAndLayersStructureService.XPGGP];
    
    // Group 4: departments, categories, subcategories
    public static readonly GroupFour: string[] = [BranchCategorizationsAndLayersStructureService.XPPPX, BranchCategorizationsAndLayersStructureService.XGGPX];

    public categorizationString: string = '';
    public isDepartmentsAndCategoriesGrouped: boolean = false;
    public isCategoriesAndSubcategoriesGrouped: boolean = false;
    public isSubcategoriesLowestStructure: boolean = false;
    public isDepartmentsHigherStructure: boolean = false;
    public isCategoriesHigherStructure: boolean = false;
    public group: ECategorizationLayerGroup = ECategorizationLayerGroup.GroupOne;

    // Static methods
    public static IsDepartmentsAndCategoriesGrouped(categorization: string): boolean {
        return [BranchCategorizationsAndLayersStructureService.XGGPP, BranchCategorizationsAndLayersStructureService.XGGPX].includes(categorization);
    }

    public static IsCategoriesAndSubcategoriesGrouped(categorization: string): boolean {
        return [BranchCategorizationsAndLayersStructureService.XXGGP, BranchCategorizationsAndLayersStructureService.XPGGP].includes(categorization);
    }

    public static IsDepartmentsHigherStructure(categorization: string): boolean {
        return [BranchCategorizationsAndLayersStructureService.XPPPP, BranchCategorizationsAndLayersStructureService.XGGPP, BranchCategorizationsAndLayersStructureService.XPGGP, BranchCategorizationsAndLayersStructureService.XPPPX, BranchCategorizationsAndLayersStructureService.XGGPX].includes(categorization);
    }

    public static IsCategoriessHigherStructure(categorization: string): boolean {
        return [BranchCategorizationsAndLayersStructureService.XXGGP, BranchCategorizationsAndLayersStructureService.XXPPP, BranchCategorizationsAndLayersStructureService.XXPPX].includes(categorization);
    }

    public static IsCategoriesHigherStructure(categorization: string): boolean {
        return [BranchCategorizationsAndLayersStructureService.XXPPP, BranchCategorizationsAndLayersStructureService.XXGGP, BranchCategorizationsAndLayersStructureService.XXPPX].includes(categorization);
    }

    public static IsTypesLowestStructure(categorization: string): boolean {
        return [BranchCategorizationsAndLayersStructureService.XXPPP, BranchCategorizationsAndLayersStructureService.XXGGP, BranchCategorizationsAndLayersStructureService.XPPPP, BranchCategorizationsAndLayersStructureService.XGGPP, BranchCategorizationsAndLayersStructureService.XPGGP].includes(categorization);
    }

    public static IsSubcategoriesLowestStructure(categorization: string): boolean {
        return [BranchCategorizationsAndLayersStructureService.XXPPX, BranchCategorizationsAndLayersStructureService.XPPPX, BranchCategorizationsAndLayersStructureService.XGGPX].includes(categorization);
    }

    // Static function to determine which group the categorization belongs to
    public static getCategorizationGroup(categorization: string): ECategorizationLayerGroup {
        if (BranchCategorizationsAndLayersStructureService.GroupOne.includes(categorization)) {
            return ECategorizationLayerGroup.GroupOne;
        }
        if (BranchCategorizationsAndLayersStructureService.GroupTwo.includes(categorization)) {
            return ECategorizationLayerGroup.GroupTwo;
        }
        if (BranchCategorizationsAndLayersStructureService.GroupThree.includes(categorization)) {
            return ECategorizationLayerGroup.GroupThree;
        }
        if (BranchCategorizationsAndLayersStructureService.GroupFour.includes(categorization)) {
            return ECategorizationLayerGroup.GroupFour;
        }
        return ECategorizationLayerGroup.GroupOne; // If categorization does not belong to any group
    }


    // Constructor
    constructor(categorization: string) {
        this.categorizationString = categorization;
        this.group = BranchCategorizationsAndLayersStructureService.getCategorizationGroup(categorization)
        this.isDepartmentsAndCategoriesGrouped = BranchCategorizationsAndLayersStructureService.IsDepartmentsAndCategoriesGrouped(categorization);
        this.isCategoriesAndSubcategoriesGrouped = BranchCategorizationsAndLayersStructureService.IsCategoriesAndSubcategoriesGrouped(categorization);
        this.isSubcategoriesLowestStructure = BranchCategorizationsAndLayersStructureService.IsSubcategoriesLowestStructure(categorization);
        this.isDepartmentsHigherStructure = BranchCategorizationsAndLayersStructureService.IsDepartmentsHigherStructure(categorization);
        this.isCategoriesHigherStructure = BranchCategorizationsAndLayersStructureService.IsCategoriessHigherStructure(categorization);
    }
}
