
import Vue from "vue";
import moment from "moment";
import WsaTextField from "./WsaTextField.vue";

export default Vue.extend({
  components: { WsaTextField },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    chosenDate: {
      get(): string {
        return this.date;
      },
      set(value: string) {
        this.$emit("changed", value);
      },
    },
  },
  props: {
    date: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    clearable: {
      type: Boolean,
    },
    overrideAllowedDates: {
      type: Function,
    },
    haveAllowedDates: {
      type: Boolean,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: true,
    },
    isActionBar: {
      type: Boolean,
      required: false,
      default: false,
    },
    datePickerRules: {
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    cssClass: {
      type: String,
      required: false,
      default: "white w-100",
    },
  },
  model: {
    prop: "date",
    event: "changed",
  },
  methods: {
    allowedDates(val: any): any {
      if (this.overrideAllowedDates) return this.overrideAllowedDates(val);
      return (new Date(val) as any) <= moment(); //.add(1, "days");
    },
    formatDate: (val: string) => (val != undefined ? val.substring(0, 10) : ""),
  },
});
