
import UserGender from "@/models/UserGender";
import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  props: ["gender", "disabled", "dense", "hideDetails"],
  data: () => ({
    genders: [
      { text: "Male", value: 1 },
      { text: "Female", value: 2 },
    ],
  }),

  computed: {
    ...mapState("partner", ["partnerSettings", "appDataContent"]),

    listOfGenders(): any[] {
      return UserGender.listOfGenders.map((gender: UserGender) => { return  { text: gender.genderStr, value: gender.genderInt}});
    },

    chosenGender: {
      get(): number {
        return this.gender;
      },
      set(value: number) {
        this.$emit("changed", value);
      },
    },
  },
  model: {
    prop: "gender",
    event: "changed",
  },
});
