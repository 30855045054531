export default class AddressTag {
    id: number = 0;
    displayName: string = "";
    icon: string = "";

    constructor(id: number , displayName: string , icon: string ) {
        this.id = id
        this.displayName = displayName
        this.icon = icon
    }
    
    static homeTag: AddressTag = new AddressTag(0, "Home", "");
    static workTag: AddressTag = new AddressTag(1, "Work","");
    static schoolTag: AddressTag = new AddressTag(2, "School", "");
    static otherTag: AddressTag = new AddressTag(100, "Other", "");


    static getTagFromAddressTagId(tagInt: number){
        if(tagInt == AddressTag.homeTag.id)
            return AddressTag.homeTag;
        else if(tagInt == AddressTag.workTag.id)
        return AddressTag.workTag;
        else if(tagInt == AddressTag.schoolTag.id)
        return AddressTag.schoolTag;
        else
        return AddressTag.otherTag;
    }
  
}
  