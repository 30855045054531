import { Module } from "vuex";
import request, { _axios } from "../../apiManager/_requestHelper";
import { RootState } from "..";
import { Apis } from "@/apiManager/Apis";
import ScheduleService from "@/services/scheduleService";
import ScheduleSlot from "@/models/ScheduleSlot";

interface ScheduleState extends RootState {
  availableSlots: [];
  firstAvailableSlot: [];
  storeHomePageFirstAvailableSlots: Object;

  // LOADING STATE
  scheduleLoading: boolean;
  firstAvailableSlotLoading: boolean;
}
export default <Module<ScheduleState, RootState>>{
  namespaced: true,
  state: {
    loading: false,
    availableSlots: [],
    firstAvailableSlot: [],

    storeHomePageFirstAvailableSlots: {} as any,

    // LOADING STATE
    scheduleLoading: false,
    firstAvailableSlotLoading: false,
  },

  mutations: {
    /** Fetching available slots */
    FETCH_AVAILABLE_SLOTS(state, availableSlots) {
      state.availableSlots = availableSlots.slotsForWSA; // mapping slots for each day
    },
    
    FETCH_FIRST_AVAILABLE_SLOT(state, firstAvailableSlot) {
      state.firstAvailableSlot = firstAvailableSlot;
    },

    FETH_STORE_HOME_PAGE_FIRST_AVAILABLE_SLOTS(state, storeHomePageFirstAvailableSlots) {
      state.storeHomePageFirstAvailableSlots = storeHomePageFirstAvailableSlots;
    },
    
    // LOADING STATE
    CHANGE_SCHEDULE_LOADING_STATE(state, loading: boolean) {
      state.scheduleLoading = loading;
    },
    CHANGE_FIRST_SLOT_LOADING_STATE(state, loading: boolean) {
      state.firstAvailableSlotLoading = loading;
    },
  },

  actions: {
    /**  Checking available slots request*/
    getAvailableSlots({ commit }, params) {
      return request({
        commit: commit,
        method: "post",
        url: Apis.getScheduleSlots,
        data: params,
        loaderKey: "availableSlotsLoading",
        successCallback: (availableSlots: any) => {
          commit("FETCH_AVAILABLE_SLOTS", availableSlots);
        },
      })
    },

    // getting first available slot
    getFirstAvailableSlot({ commit }, params) {
       return request({
        commit: commit,
        method: "post",
        url: Apis.getFirstAvlScheduleSlot,
        data: params,
        loaderKey: "firstAvailableSlotsLoading",
        successCallback: (firstAvailableSlot: any) => {
          commit("FETCH_FIRST_AVAILABLE_SLOT", firstAvailableSlot);
        },
      })
    },

    async getStoreHomePageFirstAvailableSlots({commit}) {
      var firstAvailableSlots = await ScheduleService.getFirstAvailableSlots();
      commit("FETH_STORE_HOME_PAGE_FIRST_AVAILABLE_SLOTS", firstAvailableSlots)
    }
    
  },
};
