
import Vue from 'vue'
import { mapState } from 'vuex';
export default Vue.extend({
    computed: {

        ...mapState("ui", ["isMobileViewDisplayed"]),

        /** get breadcrumbs items */
        breadcrumbsItems(): any[] {
            var route = this.$route;
            if (typeof (route.meta as any).breadCrumb === "function") {
                return (route.meta as any).breadCrumb.call(this, route);
            }
            return (route.meta as any).breadCrumb;
        }

    }
})
