
import Vue from 'vue'
import { mapActions, mapState } from 'vuex';
export default Vue.extend({
    props: {
        // items to be displayed
        items: {
            required: true
        },
    },

    computed: {
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("ui", ["isMobileViewDisplayed"])
    },

    methods: {
        ...mapActions("product", ["getItems"]),

        getBannerSheetStyle(bannerInfo: any) : string {
            return `background-color: #${bannerInfo.bannerMainColor.replace('0xFF', '')} !important;
                    border-radius: 18px !important;
                    width: 100% !important

            `
        },

        getImageStyle(bannerInfo: any) : string {
            return `cursor: ${bannerInfo.bannerActionType == 1 ? 'pointer' : 'default' }; object-fit:contain

            `
        },

        onBannerClick(banner: any) {
            if(banner.bannerInfo.bannerActionType == 1) {
                
                this.$router.push({
                    name: "productsPageByCampaign",
                    params: {
                        campaignId: typeof banner.itemsCollectionId == "undefined" ? banner.itemsCollectionType : banner.itemsCollectionId,
                        campaignName: banner.campaignInfo.name,
                        campaignCollectionType: banner.itemsCollectionType,
                        title: banner.campaignInfo.name,
                    },
                    
                    query: {
                        type: banner.itemsCollectionType,
                        typeId: banner.itemsCollectionId,
                    }

                });
            }

            else if(banner.bannerInfo.bannerActionType == 3){
                var anchor = document.createElement('a');
                anchor.href = banner.bannerInfo.onClickGoToURL;
                anchor.target="_blank"
                anchor.click();
                anchor.remove();
            }

        },
    }
})
