
import FirebaseAnalyticsScreensNames from '@/firebase/firebaseAnalyticsScreensNames'
import User from '@/models/User'
import UserGender from '@/models/UserGender'
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import WsaButton from '../UI/Button/wsa-button.vue'
import UITitle from '../UI/Text/UITitle.vue'
import UIDatePicker from '../UI/TextFields/UIDatePicker.vue'
import GenderComponent from './GenderComponent.vue'
import OtpValidation from './OtpValidation.vue'
import phoneNumberMixin from "@/mixins/phoneNumberMixin"
import PartnerLogo from '../PartnerLogo/PartnerLogo.vue'
import WsaTextField from '../UI/TextFields/WsaTextField.vue'
import FlowFunctions from '@/flows/FlowFunctions'
import CloseIcon from '../UI/Icons/CloseIcon.vue'
import PartnerSettings from '@/models/PartnerSettings'
import WsaPhoneNumber from "../UI/TextFields/WsaPhoneNumber.vue";
import BackIcon from '../UI/Icons/BackIcon.vue'


export default Vue.extend({
    components: {
        "gender-component": GenderComponent,
        "ui-date-picker": UIDatePicker,
        "ui-title": UITitle,
        WsaButton,
        OtpValidation,
        "partner-logo": PartnerLogo,
        "wsa-text-field": WsaTextField,
        "close-icon": CloseIcon,
        "wsa-phone-number": WsaPhoneNumber,
        "back-icon": BackIcon,
    },

    props: {
        registrationPrefilledData: {
            required: false,
            type: Object
        }
    },

    mixins: [phoneNumberMixin],

    data: () => ({
        step: 1,

        params: {
            firstName: "" as string,
            lastName: "" as string,
            email: "" as string,
            gender: UserGender.noneGender,
            dateOfBirth: "" as string,
            phoneNumber: "" as string,
            userPassword: "" as string,
            tempPhoneNumber: "" as string,
            countryCode: "" as any
        },

        rule: [
            (v: any) =>
                (typeof v != "undefined" && v != "" && v.length > 1) || "This field is required",
        ],

        isPasswordVisisble: false,
        passwordRule: [
        (v: any) =>
            (typeof v != "undefined" && v != "" && v.length > 7) ||
            "This field is required",
        ],

        authenticationOtp: "",
        isPhoneNumberValid: false as boolean,
    }),

    computed: {
        ...mapState("partner", ["partnerSettings", "appDataContent"]),
        ...mapState("loading", ["accountLoading"]),
        ...mapState("store", ["selectedStoreDetails", "listOfStores"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),

        ...mapGetters("auth", ["currentSelectedUser"]),

        user(): User {
            return this.currentSelectedUser;
        }
    },

    methods: {
        ...mapActions("auth", ["createAccount", "authenticate"]),
        ...mapActions("address", ["createAddress", "getUserAddresses"]),
        ...mapActions("store", ["getStoreDetails"]),

        ...mapMutations("auth", ["SET_FIRST_TIME_OPENNED_VALUE"]),
        ...mapMutations("firebase", ["LOG_FIREBASE_SCREEN"]),

        // prevent numbers for first name and last name
        checkNumberDigit(e: any) {
            if (Number.isInteger(parseInt(e.key))) e.preventDefault();
            else return true;
        },

        regFormStepOneValidation(): boolean {
            return (this.$refs.regFormStepOne as any).validate();
        },

        regFormStepTwoValidation(): boolean {
            return (this.$refs.regFormStepTwo as any).validate();
        },  

        async proceed() {
            if(this.step == 1) { 
                if(this.params.tempPhoneNumber.trim().length == 0) {
                    this.$store.commit("ui/snackbar", {
                        error: true,
                        message: this.appDataContent.authLoginEmptyMobileNbreTxtBnr,
                        show: true
                    })
                }

                else {
                    this.step++;                   
                }
            }

            else if(this.step == 2) {
                var phoneNumberToUse = this.params.phoneNumber.replace('+', '');
                if (this.params.firstName.trim().length >= 2 
                        && this.params.lastName.trim().length >= 2 
                        && this.params.phoneNumber.trim().length > 5
                        && this.params.userPassword.trim().length > 7
                        && (this.params.email.trim().length > 0 ? this.isEmailValide() : true)       
                ) {
                    var localPhoneNumber = this.params.tempPhoneNumber.startsWith('0') == true ? this.params.tempPhoneNumber.slice(1) : this.params.tempPhoneNumber;

                    await this.createAccount({
                        firstName: this.params.firstName,
                        lastName: this.params.lastName,
                        email: this.params.email,
                        dateOfBirth: "",
                        phoneNumber: phoneNumberToUse,
                        userPassword: this.params.userPassword,
                        gender: 0,
                        localPhoneNumber: localPhoneNumber,
                        countryCode: this.params.countryCode.replace('+', '')
                    });

                    this.step = 3;
                }
                else {
                     if(this.params.firstName.trim().length < 2 || this.params.lastName.trim().length < 2 ) {
                    this.$store.commit("ui/snackbar", {
                        error: true,
                        show: true,
                        message: this.appDataContent.emptyFirstNameAndOrLastName
                    });
                }

                else if(this.params.email.trim().length > 0 && this.isEmailValide() == false) {
                    this.$store.commit("ui/snackbar", {
                        error: true,
                        show: true,
                        message: this.appDataContent.invalidEmail
                    });
                }

                // else if(this.isPhoneNumberValid == false) {
                //     this.$store.commit("ui/snackbar", {
                //         error: true,
                //         message: this.appDataContent.errorPhoneNbText,
                //         show: true
                //     })
                // }

                
                else if(this.params.userPassword.trim().length == 0) {
                    this.$store.commit("ui/snackbar", {
                        error: true,
                        message: this.appDataContent.authLoginEmptyPwdTxtBnr,
                        show: true
                    })
                }

                else if(this.params.userPassword.trim().length < 8) {
                    this.$store.commit("ui/snackbar", {
                        error: true,
                        message: this.appDataContent.authForgotPwdLenghPwdIncorrecrTxtBnr,
                        show: true
                    })
                }
                }
                
            }

            else if (this.step == 3) {
                this.verifyAccount()
            }
        },
        
        goBack() {
            if(this.step == 1) {
                this.$emit("onGoBack");
            }
            else if(this.step == 2) {
                this.step = 1;
            }
            else if( this.step == 3) {
                this.step = 1
            }


        },

        isEmailValide(): boolean {
            return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(this.params.email)
        },



        async sendSms() {
            var phoneNumberToUse = this.params.phoneNumber.replace('+', '');
            var localPhoneNumber = this.params.tempPhoneNumber.startsWith('0') == true ? this.params.tempPhoneNumber.slice(1) : this.params.tempPhoneNumber;

            await this.createAccount({
                firstName: this.params.firstName,
                lastName: this.params.lastName,
                email: this.params.email.trim().length > 0 ? this.params.email : "email",
                dateOfBirth: null,
                phoneNumber: phoneNumberToUse,
                userPassword: this.params.userPassword,
                gender: this.params.gender.genderInt,
                localPhoneNumber: localPhoneNumber,
                countryCode: this.params.countryCode.replace('+', '')
            });
    },

        async verifyAccount() {       
            var phoneNumberToUse = this.params.phoneNumber.replace('+', '');   

            this.authenticate({
                phoneNumber: phoneNumberToUse,
                password: this.authenticationOtp,
                oldFcmToken: "",
                newFcmToken: "",
                deviceType: 'wsa',
                isNewUser: true,
            })
            .then(async () => { 
                await this.createAddress({
                    address:{   
                        id: 0,
                        tag: (this.user as User).usedAddress?.tag.id,
                        tagDescription: (this.user as User).usedAddress?.tagDescription,
                        streetNumber: (this.user as User).usedAddress?.streetNbre,
                        streetName: (this.user as User).usedAddress?.streetName,
                        postalCode: (this.user as User).usedAddress?.postalcode,
                        city: (this.user as User).usedAddress?.city,
                        area: (this.user as User).usedAddress?.area,
                        state: (this.user as User).usedAddress?.state,
                        country: (this.user as User).usedAddress?.country,
                        countryCode: (this.user as User).usedAddress?.countryCode,
                        additionalAddressInformation: (this.user as User).usedAddress?.additionalAddressInfo,
                        coordinatesLongitude: (this.user as User).usedAddress?.position.longitude,
                        coordinatesLatitude: (this.user as User).usedAddress?.position.latitude,
                        IsMultiAddressSA: true
                    } 
                });
                
                // get updated addresses list of the new user
                await this.getUserAddresses();

                // set the primary address as used address
                var primaryAddress = this.user.deliveryAddressesFromServer.find(adr => adr.isPrimary == true);
                this.$store.commit("auth/SET_USER_USED_ADDRESS", primaryAddress);

                this.$emit("closeDialog");
            
            })
        },

    },

    mounted() {
        if(this.registrationPrefilledData != null && typeof this.registrationPrefilledData != "undefined") {
            this.params.email = this.registrationPrefilledData.email == "email" ? "" : this.registrationPrefilledData.email;
            this.params.firstName = this.registrationPrefilledData.firstName;
            this.params.lastName = this.registrationPrefilledData.lastname;
            this.params.phoneNumber = this.registrationPrefilledData.phoneNumber;
            this.params.tempPhoneNumber = this.registrationPrefilledData.localPhoneNumber;
            this.params.countryCode = this.registrationPrefilledData.countryCode;

            this.step = 2;

            this.$forceUpdate();
        }

        else {
            this.step = 1;            
            this.params.phoneNumber = (this.partnerSettings as PartnerSettings).defaultPhoneNumberCode;
        }


        // firebase analytics
        this.LOG_FIREBASE_SCREEN(FirebaseAnalyticsScreensNames.registerPage);
    }
})
