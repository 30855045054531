
import Vue from 'vue'
export default Vue.extend({
    // props: ["speed", "color"]
    props: {
        speed: {
            default: "normal",
            type: String,
            required: false
        },

        color: {
            default: "green",
            type: String,
            required: false
        },
    }
})
