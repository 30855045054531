
import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({ 
  props: {
    item: {
      required: true
    },

    fontSize: {
      required: false,
      default: "small",
      type: String
    },

    brandCard: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  computed: {
    ...mapState("partner", ["partnerSettings", "appDataContent"])
  }

});
