
import UserGender from '@/models/UserGender';
import Vue from 'vue'
import { mapState } from 'vuex';
export default Vue.extend({

    props: ["gender"],

    computed: {
        ...mapState("partner", ["appDataContent"]),

        listOfGenders(): any[] {
          return [  
                { value: 0, text: this.appDataContent.userGenderNoneStr},
                { value: 1, text:   this.appDataContent.userGenderMaleStr},
                { value: 2, text: this.appDataContent.userGenderFemaleStr},
          ]
        },

        genderModel: {
            get(): UserGender {
                return this.gender;
            },

            set(value: UserGender) {
                this.$emit("changed", value);
            }
        }
    },

    model: {
        prop: "gender",
        event: "changed"
    }
})
