
import Vue from 'vue';
import UITitle from "@/components/UI/Text/UITitle.vue";
import ElementGenerator from '../UI/Elements/ElementGenerator.vue';
import { mapState } from 'vuex';

export default Vue.extend({
    
    props: ["title", "items", "loading", "isCategory", "isSubcategory", "isDepartment", "isBrand", "gridView", "isShowAllButtonEnabled"],

    components: {
        "ui-title": UITitle,
        "element-generator": ElementGenerator
    },


    data: () => ({
      randomNumber: Math.floor(Math.random() * 50000000)
    }),

    computed: {
      ...mapState('ui', ["isMobileViewDisplayed"]),

      itemType(): number {
        if(this.isSubcategory == true) 
          return 3;
        else if(this.isCategory == true)
          return 1;
        else if(this.isDepartment == true)
          return 4;

        else if(this.isBrand == true)
          return 5;
        return 2;
      },  

      elementWidth(): any {
        if(this.isCategory == true) {
          return '80'
        }

        else if(this.isBrand == true) {
          return '80'
        }

        else if(this.isSubcategory == true) {
          return "100"
        }

        else { 
          if(this.isMobileViewDisplayed == true) {
            
            if(this.gridView == true)
              return "120";
            return "150"
          }

          else {
            return "180";
          }
        }
      }
    },


    methods: {
      slideNext() {
        let next = document.getElementById(`${this.randomNumber}-${this.title.replace(' ', '-')}-next`);
        next != null ? next.click() : null;
      },

      slidePrev() {
        let prev = document.getElementById(`${this.randomNumber}-${this.title.replace(' ', '-')}-prev`);
        prev?.click();
      },
  },

  mounted() {
    this.$forceUpdate();
  }

})
