import { Module } from "vuex";
import request, { _axios } from "../../apiManager/_requestHelper";
import store, { RootState } from "..";
import { Apis } from "@/apiManager/Apis";
import User from "@/models/User";
import AddressPosition from "@/models/AddressPosition";

export interface AddressState extends RootState {
    listOfUserAddresses: AddressPosition[];
}
  
export default <Module<AddressState, RootState>>{
    namespaced: true,
    state: {
        loading: false,
        listOfUserAddresses: [] as AddressPosition[]
    },

    mutations: {
        FETCH_LIST_OF_ADDRESSES(state, listOfaddresses) {
            state.listOfUserAddresses = User.updateUserListOfAddress(listOfaddresses);
            store.commit("auth/UPDATE_LIST_OF_USER_ADDRESSES_FROM_SERVER", state.listOfUserAddresses);
        }
    },

    actions: {
        createAddress({commit}, params) {
            return request({
                commit: commit,
                url: Apis.createAddressSubUrl,
                data: params,
                method: "post",
                loaderKey: "createAddressLoading"
            })
        },

        setAddressPrimary({commit}, params) {
            return request({
                commit: commit,
                url: Apis.setAddressPrimarySubUrl,
                data: params,
                method: "post",                
                loaderKey: "setAddressPrimaryLoading"
            })
        },        

        updateAddress({commit}, params) {
            return request({
                commit: commit,
                url: Apis.updateUserAddress,
                data: params,
                method: "post",
                loaderKey: "updateAddressLoading"
            })
        },        

        deleteAddress({commit}, params) {
            return request({
                commit: commit,
                url: Apis.deleteUserAddress,
                data: params,
                method: "post",
                loaderKey: "deleteAddressLoading"              
            })
        },

        getUserAddresses({commit}) {
            return request({
                commit: commit,
                url: Apis.userListOfAddressesSubUrl,
                method: "post",
                loaderKey: "userAddressesLoading",
                successCallback: (listOfaddresses: any) => {
                    commit("FETCH_LIST_OF_ADDRESSES", listOfaddresses);                    
                }
            })
        },

    
    }
}