
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import UIMoneyFormat from "@/components/UI/Money/UIMoneyFormat.vue";

export default Vue.extend({
    props: ["drawer"],

    components: {
        "money-formatter": UIMoneyFormat,
    },

    computed: {
        ...mapState("ui", ["isMobileViewDisplayed"]),
        ...mapState("auth", ["preferredOrderType"]),
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("partner", ["partnerSettings"]),

        ...mapGetters("cart", ["currentSelectedCartTotalAmount", "currentSelectedCartTotalQuantity"]),

        cartDrawer: {
            get(): boolean {
                return (this as any).drawer;
            },
            set(value: boolean) {
                this.$emit("changed", value);
            }
        }   

    },

    model: {
        prop: "drawer",
        event: "changed"
    }
})
