
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CategoriesList from '@/components/Items/CategoriesListDrawer.vue'
import BranchInformation from '@/components/Branch/BranchInformation.vue'
import OrderType from '@/models/OrderType'
import routerManager from '@/routerManager'
import Routes from '@/routerManager/routes'
import _requestHelper from '@/apiManager/_requestHelper'
import StoreSettings from '@/models/StoreSettings'
import PartnerSettings from '@/models/PartnerSettings'
import StoreService from '@/services/storeService'
import { EPartnerStoresStructureType } from '@/enums/EPartnerStoresStructureType'
import User from '@/models/User'
import { EStoreType } from '@/enums/EStoreType'

export default Vue.extend({
    components: {
      "categories-list": CategoriesList,
      "branch-details": BranchInformation
    },

    data: () => ({
      storeDetailsDialog: false as boolean,
      pickupOrderTypeInt: OrderType.pickupOrderTypeInt,
      deliveryOrderTypeInt: OrderType.deliveryOrderTypeInt,
      exchangeInterval: 0 as any,
      exchangeLoading: false as boolean,
      firstAvailableSlotsLoading: false as boolean,
      exchangeRateValue: 0 as number,
    }),

    computed: {
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("partner", ["partnerSettings", "appDataContent"]),
        ...mapState("schedule", ["storeHomePageFirstAvailableSlots"]),
        ...mapState("auth", ["preferredOrderType"]),

        ...mapGetters("auth", ["currentSelectedUser"]),

        mainDrawerListOfItems(): any[] {
          return  [
            {name: "Home", route: Routes.storeHomePage.name},
            // {name: "Promotions", route: "" /*route: {name: 'storeHomePage', params: {branchName: (this.selectedStoreDetails as StoreSettings).displayName }}*/},
          ]
        },

        currentRouteName(): string | null | undefined {
          return this.$route.name
        },

        isStoreIsConvenient(): boolean {
          return ((this as any).selectedStoreDetails as StoreSettings).storeType == EStoreType.Convenient;
        },
        
        /** check if customer is eligible for delivery order type */
        isCustomerEligibleForDelivery(): boolean {
            return StoreService.isStoreAvailableForDelivery(this.selectedStoreDetails.id, true)
        },

        /** check if customer is eligible for pickup order type */
        isCustomerEligibleForPickup(): boolean {
            return StoreService.isStoreAvailableForPickup(this.selectedStoreDetails.id)
    
        },

        isFirstAvailableDeliverySlotDisplayed(): boolean {
          if((this.partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel) {
            return this.isCustomerEligibleForDelivery == true && (this.currentSelectedUser as User).preferredOrderType.typeInt == OrderType.deliveryOrderTypeInt;
          }
          
          return this.isCustomerEligibleForDelivery
        },

        isFirstAvailablePickupSlotDisplayed(): boolean {
          if((this.partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel) {
            return this.isCustomerEligibleForPickup == true && (this.currentSelectedUser as User).preferredOrderType.typeInt == OrderType.pickupOrderTypeInt;
          }
          
          return this.isCustomerEligibleForPickup
        }
    },

    methods: {
      ...mapMutations("product", ["SET_LAST_SUBCAT_SELECTED", "SET_LAST_CAT_SELECTED"]),
                
      goToStoreDetailsPage() {
        routerManager.goToStoreDetailsPage();
      },
      
      async getBranchDetails() { 
        await StoreService.getStoreDetails(this.selectedStoreDetails.id);
      },

      /** Convert from base currency to store currency rate  */
      async convertAmountToChosenCurrency(){
        clearInterval(this.exchangeInterval)

        this.exchangeLoading = true;

        return _requestHelper({
          method: "POST",
          data: {
            fromCurrencyId: ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency.id,
            branchId: ((this as any).selectedStoreDetails as StoreSettings).id,
            toCurrencyCode: "LBP",
            amount: 1
          },
          loaderKey: "",
          url:"currency/convertRate",
          successCallback: (convertedTotal: any) => {
            this.exchangeRateValue = convertedTotal.amount.toLocaleString();
            this.exchangeLoading = false;
          }
        }).finally(() => {
            this.exchangeLoading = false;
        })
      },


      // UI => drawer top background image
      getDrawerBackgroundImageStyle(): string {
        var imageUrl = ((this as any).selectedStoreDetails as StoreSettings).storeHomePageDrawerBackgroundImageUrl;
        return `background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 35%, transparent 100%), url('${imageUrl}');`
      },
    },

    // watch: {
    //   async "preferredOrderType"() {
    //     await Promise.all([
    //       this.convertAmountToChosenCurrency()
    //     ]) 
    //   },

    //   async "selectedStoreDetails.id"(value: number) {
    //     if(value > 0) {
    //       this.convertAmountToChosenCurrency()
    //     }
    //   }
    // },

   // async mounted() {
      // await Promise.all([
      //   this.convertAmountToChosenCurrency()
      // ]) 

      // this.exchangeInterval = setInterval(this.convertAmountToChosenCurrency, 900000);      
    // },


})
