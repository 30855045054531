
import Vue from "vue";
import UIMessageDialog from "@/components/UI/Popup/UIMessageDialog.vue";
import UISubtitle from "@/components/UI/Text/UISubtitle.vue";
import Card from "@/components/UI/Card/CardRounded.vue";
export default Vue.extend({
  components: {
    UIMessageDialog,
    "ui-subtitle": UISubtitle,
    card: Card,
  },
  data: () => ({
    changeAddressDialog: false as boolean,
    changeStoreDialog: false as boolean,
  }),

  methods: {
    goToLocation() {
      this.changeAddressDialog = false;
      this.$router.push({ name: "Location" });
    },
    goToStores() {
      this.changeStoreDialog = false;
      this.$router.push({ name: "Stores" });
    },
  },
});
