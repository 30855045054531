
import Vue from 'vue'
import { mapState } from 'vuex';
export default Vue.extend({
    props: ["text", "outlined", "isTextStyle", "onClick", "disabled", "ripple", "bind" , "on", "loading", "dark"],

    computed: {
        ...mapState("partner", ["partnerSettings"])
    },

    methods: {
        click() {
            if(this.onClick)
                this.onClick();
        }
    }
})
