import { render, staticRenderFns } from "./InitLoader.vue?vue&type=template&id=51ec4062&scoped=true&"
import script from "./InitLoader.vue?vue&type=script&lang=ts&"
export * from "./InitLoader.vue?vue&type=script&lang=ts&"
import style0 from "./InitLoader.vue?vue&type=style&index=0&id=51ec4062&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ec4062",
  null
  
)

export default component.exports