
import Vue from 'vue'
import { mapState } from 'vuex';
export default Vue.extend({

    props: {
        disabled: {
            required: false,
            default: false,
            type: Boolean
        },

        text: {
            required: false,                      
        }
    },

    computed: {
        ...mapState("partner", ["partnerSettings"])
    },

    methods: {
        plusBtnClicked() {
            this.$emit("expandActions");
        }
    }
})
