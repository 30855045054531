var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"width":"100%","z-index":"1","border-radius":"20px!important"},attrs:{"heiht":_vm.height,"flat":"","disabled":_vm.disabled}},[_c('GmapMap',{ref:"googleMap",staticStyle:{"width":"100%"},style:(`height: ${_vm.height ? _vm.height : '48vh'}`),attrs:{"center":_vm.centerToDisplay,"zoom":_vm.zoom,"map-type-id":"roadmap","options":{
           zoomControl: _vm.isMobileViewDisplayed == false,
           mapTypeControl: false,
           scaleControl: false,
           streetViewControl: false,
           rotateControl: false,
           fullscreenControl: false,
           disableDefaultUi: true,
           isFractionalZoomEnabled: true,
           gestureHandling: 'greedy',
           styles: _vm.mapStyle
       }},on:{"center_changed":(v) => _vm.onCenterChange(v)},scopedSlots:_vm._u([(_vm.isMapInitialized == true)?{key:"visible",fn:function(){return [_vm._l((_vm.userMarkers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"icon":_vm.userMarker,"position":m,"clickable":false,"draggable":false}})}),_vm._l((_vm.storeMarkers),function(store,index){return _c('GmapMarker',{key:index + 1000,attrs:{"icon":_vm.getStoreMarker(store.marker),"position":{lat: store.lat, lng: store.lng},"clickable":_vm.isStoreMarkerClickable == true,"draggable":false},on:{"click":function($event){return _vm.onStoreMarkerClicked(store.id)}}})}),(_vm.polyline)?_c('gmap-polyline',{attrs:{"path":_vm.polyline,"strokeWeight":3}}):_vm._e(),(_vm.onlyForMarkerDisplay == false)?_c('div',[_c('div',{staticClass:"pulsating-circle"})]):_vm._e()]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }