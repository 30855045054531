export default class FirebaseAnalyticsScreensNames {
    static indexPage: string = "Index";
    static loginPage: string = "Login";
    static registerPage: string = "Register";
    static forgotPasswordPage: string = "Forgot Password";
    static resetPasswordPage: string = "Reset Password";
    static profilePage: string = "Profile";
    static contactUsPage: string = "Contact Us";
    static storesPage: string = "Stores";
    static storeDetailsPage: string = "Store Details";
    static ordersPage: string = "Orders";
    static storeHomePage: string = "Store Home Page";
    static productsByCategoryPage: string = "Products by Category";
    static productsBySubCategoryPage: string = "Products by SubCategory";
    static cartPage: string = "Cart";
    static checkoutPage: string = "Checkout";
    static orderSummaryPage: string = "Order Summary";
    static settingsPage: string = "Settings";
    static locationPage: string = "Location";
    static notFoundPage: string = "Not Found";
}