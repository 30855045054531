import { Module } from "vuex";
import { FirebaseApp, initializeApp } from "firebase/app";
import { Firestore, getFirestore } from  "firebase/firestore";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { RootState } from "..";

export interface FirebaseState extends RootState {
    firebaseApp: FirebaseApp;
    firebaseDb: Firestore;
    firebaseAnalytics: Analytics;
}
  
export default <Module<FirebaseState, RootState>>{
    namespaced: true,
    state: {
        firebaseApp: {} as FirebaseApp,
        firebaseDb: {} as Firestore,
        firebaseAnalytics: {} as Analytics
    },

    mutations: {
       INIT_FIREBASE(state, firebaseConfig: any) {
            state.firebaseApp = initializeApp(firebaseConfig);
            state.firebaseDb = getFirestore(state.firebaseApp);
            state.firebaseAnalytics = getAnalytics(state.firebaseApp);
       },

       LOG_FIREBASE_SCREEN(state, screenName: string) {
            // logEvent(state.firebaseAnalytics, 'screen_view', {
            //     firebase_screen: 'WSA/' + screenName,
            //     firebase_screen_class: ""
            // });
       }
    },

}