
import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
    props: ["isForWelcomePage"],

    computed: {
        ...mapState("partner", ["partnerSettings"]),
    }
})
