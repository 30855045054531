
import { Apis } from '@/apiManager/Apis'
import _requestHelper from '@/apiManager/_requestHelper'
import PartnerLogo from '@/components/PartnerLogo/PartnerLogo.vue'
import WsaButton from '@/components/UI/Button/wsa-button.vue'
import UITitle from '@/components/UI/Text/UITitle.vue'
import WsaPhoneNumber from '@/components/UI/TextFields/WsaPhoneNumber.vue'
import OtpValidation from '@/components/Authentication/OtpValidation.vue'

import Vue from 'vue'
import { mapState } from 'vuex'
import store from '@/store'
export default Vue.extend({
    name: "DeleteAccountPage",

    components: { 
        "wsa-phone-number": WsaPhoneNumber,
        "wsa-button": WsaButton,
        "partner-logo": PartnerLogo,
        "ui-title": UITitle,
        "otp-validation": OtpValidation
    },

    data: () => ({
        tempPhoneNumber: "" as string,
        phoneNumber: "" as string,
        step: 1 as number,
        smsRetryCount: 1 as number,
        otpToken: "" as string,
        loading: false as boolean
    }),

    computed: {
        ...mapState("partner", ["appDataContent"])
    },

    methods: {
        async deleteAccount() {
            this.loading = true;
            var phoneNumberToUse = this.phoneNumber.replace('+', '');

            await _requestHelper({
                url: Apis.accountDeleteRequest,
                data: {
                    phoneNumber: phoneNumberToUse,
                    smsRetryCount: this.smsRetryCount,
                },
                loaderKey: "",
                method: "POST",
                successCallback: () =>{
                    this.step = 2;
                    this.loading = false;
                }
            })
            .finally(() => {               
                this.loading = false;
                this.smsRetryCount++;
            })        
        },
 
        async validateOtp() {
            this.loading = true;
            
            var phoneNumberToUse = this.phoneNumber.replace('+', '');

            await _requestHelper({
                url: Apis.accountDelete,
                data: {
                    phoneNumber: phoneNumberToUse,
                },
                loaderKey: "",
                method: "POST",
                successCallback: () => {
                    this.loading = false;
                }
            })
            .then(() => {
                this.loading = false;
                this.$router.push({name: 'landingPage'});
            })
        }

    }
})
