var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ui-popup',{on:{"closeDialog":function($event){return _vm.$emit('closeDialog')}}},[_c('v-card-title',{staticClass:"accent d-flex flex-column justify-center"},[_c('div',[_c('logo-component')],1),_c('div',{staticClass:"mt-2 font-weight-bold d-flex align-center"},[_c('ui-subtitle',{staticStyle:{"font-size":"14px"},attrs:{"subtitle":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsToS1
            ? _vm.selectedStoreDetails.storeDetailsToS1
            : ''}}),(_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsToS2)?_c('bullet-point'):_vm._e(),_c('ui-subtitle',{staticStyle:{"font-size":"14px"},attrs:{"subtitle":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsToS2
            ? _vm.selectedStoreDetails.storeDetailsToS2
            : ''}}),(_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsToS3)?_c('bullet-point'):_vm._e(),_c('ui-subtitle',{staticStyle:{"font-size":"14px"},attrs:{"subtitle":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsToS3
            ? _vm.selectedStoreDetails.storeDetailsToS3
            : ''}}),(_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsToS4)?_c('bullet-point'):_vm._e(),_c('ui-subtitle',{staticStyle:{"font-size":"14px"},attrs:{"subtitle":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsToS3
            ? _vm.selectedStoreDetails.storeDetailsToS4
            : ''}})],1)]),_c('v-card-text',{staticClass:"pa-0"},[_c('BranchSection',{attrs:{"id":"about","icon":"store-outline","title":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsAboutStoreTitle
          ? _vm.selectedStoreDetails.storeDetailsAboutStoreTitle
          : '',"subtitle":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsAboutStoreTitle
        ? _vm.selectedStoreDetails.storeDetailsAboutStoreTitle
        : '',"content":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsAboutStoreContent
        ? _vm.selectedStoreDetails.storeDetailsAboutStoreContent
        : ''}}),_c('BranchSection',{attrs:{"id":"pricing","icon":"cash","title":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsPricingTitle
          ? _vm.selectedStoreDetails.storeDetailsPricingTitle
          : '',"subtitle":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsPricingSubTitle
          ? _vm.selectedStoreDetails.storeDetailsPricingSubTitle
          : '',"content":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsPricingContent
          ? _vm.selectedStoreDetails.storeDetailsPricingContent
          : ''}}),_c('BranchSection',{attrs:{"id":"delivery","icon":"truck-outline","title":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsDeliveryTitle
          ? _vm.selectedStoreDetails.storeDetailsDeliveryTitle
          : '',"subtitle":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsDeliverySubTitle
          ? _vm.selectedStoreDetails.storeDetailsDeliverySubTitle
          : '',"content":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsDeliveryContent
          ? _vm.selectedStoreDetails.storeDetailsDeliveryContent
          : ''}}),_c('BranchSection',{attrs:{"id":"pickup","icon":"shopping-outline","title":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsPickupTitle
          ? _vm.selectedStoreDetails.storeDetailsPickupTitle
          : '',"subtitle":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsPickupSubTitle
          ? _vm.selectedStoreDetails.storeDetailsPickupSubTitle
          : '',"content":_vm.selectedStoreDetails && _vm.selectedStoreDetails.storeDetailsPickupContent
          ? _vm.selectedStoreDetails.storeDetailsPickupContent
          : ''}}),_c('v-card',{staticClass:"pa-5 mt-2",attrs:{"tile":"","flat":"","width":"100%"}},[_c('div',{staticClass:"text-h6 font-weight-bold d-flex align-center"},[_c('v-icon',{staticClass:"me-2"},[_vm._v("mdi-clock-outline")]),_vm._v(" "),_c('span',[_vm._v(" Hours")])],1),_c('div',{staticClass:"mt-2"},_vm._l((_vm.selectedStoreDetails.weekOpeningHours),function(schedule,i){return _c('div',{key:i},[_c('div',{staticClass:"d-flex align-center poppins-normal"},[_c('div',[_vm._v(_vm._s(_vm.weekDays[i]))]),_c('v-spacer'),_c('div',[_vm._v(" "+_vm._s(_vm._f("formatTime")(schedule.openingTime))+" - "+_vm._s(_vm._f("formatTime")(schedule.closingTime))+" ")])],1)])}),0)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }