
import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  props: {
    text: { required: true, type: String },
    isText: { required: false, type: Boolean, default: false },
    outlined: { required: false, type: Boolean, default: false },
    disabled: { required: false, type: Boolean, default: false },
    loading: { required: false, type: Boolean, default: false },
    width: { required: false, type: String, default: "0" },
    height: { required: false, type: String, default: "46" },
    btnClass: { required: false, type: String },
    font: { required: false, type: String, default: "Open Sans" },
    radius: { required: false, type: String, default: "5px" },
    onClick: { required: true, type: Function },
  },

  computed:{
    ...mapState("partner", ["partnerSettings"]),
  }
});
