
import { mapActions, mapGetters } from "vuex";

import Vue from "vue";

export default Vue.extend({

  props: {
    isOutlined: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    ordersListDrawerState: false as boolean,
  }),
  computed: {
    ...mapGetters("auth", ["currentSelectedUser"]),
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    signout() {
      this.$router.push({ name: "Login" });
      (this as any).logout();
    },
  },
});
