import Vue from "vue";
import VueRouter from "vue-router";
import Routes from "./routes";
import store from "@/store";
import { AuthenticationState } from "@/store/modules/authenticationModule";
import User from "@/models/User";
import { PartnerSettingsState } from "@/store/modules/partnerModule";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: Routes,
    scrollBehavior() {
      return new Promise((resolve) => {      
        resolve({ top: 0 } as any)
      })
    },
});


router.beforeEach(async (to, from, next) => {
    window.scrollTo(0,0);
    
    var partnerSettings = ((store as any).state["partner"] as PartnerSettingsState).partnerSettings;

    if(to.matched.length == 0) {
      next({name: "NotFound"})
    }

    else {

      //#region  Check if route RequiresAuth
      var toRouteRequiresAuth = false;          
      if(typeof to.meta != "undefined" && typeof to.meta["requiresAuth"] != "undefined") {
        toRouteRequiresAuth = to.meta["requiresAuth"];
      }
      //#endregion

      //#region Get status of the user
      var listOfUsers = ((store as any).state["auth"] as AuthenticationState).listOfUsers;
      var currentUser = ((store as any).state["auth"] as AuthenticationState).currentUser;
      var user = listOfUsers[currentUser] as User;
      var isUserLoggedIn = typeof user == "undefined" ? false : user.isUserLoggedIn == true;
      //#endregion
    
      if(toRouteRequiresAuth == true && isUserLoggedIn == false) {
        next({name: "landingPage"})
      }

      else {
        next();                
      }
    }    
    
})


export default router;