
import OrderType from '@/models/OrderType';
import StoreService from '@/services/storeService';
import routerManager from "@/routerManagerNew/routerManager"
import Vue from 'vue'
import { mapState } from 'vuex'
import { EPartnerStoresStructureType } from '@/enums/EPartnerStoresStructureType';
import PartnerSettings from '@/models/PartnerSettings';
export default Vue.extend({

  props: {
    store: {
      required: true
    }
  },

  data: () => ({
    storeDetailsLoading: false as boolean
  }),

  computed: {
    ...mapState("ui", ["isMobileViewDisplayed"]),
    ...mapState("partner", ["partnerSettings", "appDataContent"]),
    ...mapState("store", ["selectedStoreDetails"]),
    ...mapState("auth", ["preferredOrderType"]),

    /** Check if store is eligibile for delivery */
    isStoreEligibleForDelivery(): boolean {
      return StoreService.isStoreAvailableForDelivery((this as any).store.id, false, true);
    },

    /** Check if store is eligibile for pickup */
    isStoreEligibleForPickup(): boolean {
      return StoreService.isStoreAvailableForPickup((this as any).store.id, true);
    },

    /** Delivery display name */
    deliveryDisplayName(): string {
      return OrderType.getOrderTypeFromInt(OrderType.deliveryOrderTypeInt).displayStr;
    },

    /** Pickup display name */
    pickupDisplayName(): string {
      return OrderType.getOrderTypeFromInt(OrderType.pickupOrderTypeInt).displayStr;
    },

    isStoreAgnostic(): boolean {
      return ((this as any).partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel;
    }
  },

  methods: {
    async setBranch() {     
      this.storeDetailsLoading = true;

      await StoreService.getStoreDetails((this as any).store.id, ((this as any).partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel )
        .then(async () => {
            // go to the store home page
            routerManager.goToStoreHomePage((this as any).selectedStoreDetails);

            this.$emit("branchSelected");
      });

      this.storeDetailsLoading = false;
    },
  }
})
