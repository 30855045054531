import { Module } from "vuex";
import request, { _axios } from "../../apiManager/_requestHelper";
import store, { RootState } from "..";
import { Apis } from "@/apiManager/Apis";
import AddressPosition from "@/models/AddressPosition";
import User from "@/models/User";
import AddressTag from "@/models/AddressTag";
import AddressCoordinates from "@/models/AddressCoordinates";
import Cart from "@/models/Cart";
import ProductInCart from "@/models/ProductInCart";
import OrderType from "@/models/OrderType";
import { StoreState } from "./storeModule";
import Vue from "vue";

export interface AuthenticationState extends RootState {
  currentUser: string;
  listOfUsers: any;
  preferredOrderType: OrderType;
  totalNbOfItems: number;
  isFirstTimeOpened: boolean;
  isWebInitializing: boolean; // used for the first loader while getting partner settings and app data content
}

export default <Module<AuthenticationState, RootState>>{
  namespaced: true,
  state: {
    currentUser: "guest",   
    listOfUsers: {} as any,
    totalNbOfItems: 0 as number,
    isFirstTimeOpened: true as boolean,
    isWebInitializing: true  as boolean,
    preferredOrderType: {typeInt: 0, displayStr: "Unknown"} as OrderType
  },

  getters: {
    currentSelectedUser(state) { 
      return state.listOfUsers[state.currentUser];
    },

    currentSelectedCart(state) {
      var storeId = ((store as any).state["store"] as StoreState).selectedStoreDetails.id;
      return (state.listOfUsers[state.currentUser].listOfCarts as Cart[]).find(cart => cart.storeId == storeId);
    }
  },

  mutations: {

    // #region auth
    SET_USER(state, user) {
      if (user != null) {
        // set loggedIn object = to guest object
        var userToSet = User.fetchUserObjectFromJson(user);

        state.preferredOrderType = state.preferredOrderType;
        userToSet.usedAddress = (state.listOfUsers[state.currentUser] as User).usedAddress;
        state.listOfUsers[user.phoneNumber] = userToSet;

        delete state.listOfUsers[state.currentUser]; // delete guest object
        state.currentUser = user.phoneNumber; // set the curr user to the loggedIn user   
        
        _axios.defaults.headers.common["Authorization"] = "Bearer " + user.access_token;
      }      
      else { 
        (state.listOfUsers[state.currentUser] as User).isUserLoggedIn = false;
        
        state.preferredOrderType = state.preferredOrderType,

        state.listOfUsers["guest"] =  { 
          isUserLoggedIn: false, 
          deliveryAddressesFromLocal: [(state.listOfUsers[state.currentUser] as User).usedAddress] as AddressPosition[], 
          usedAddress: (state.listOfUsers[state.currentUser] as User).usedAddress,
        } as User;
        
        state.currentUser = "guest";
      }


      // reset e-wallet
      (store as any).commit("eWallet/FETCH_EWALLET", {});
      // reset partner loyalty
      (store as any).commit("loyalty/FETCH_LOYALTY", {});
    },


    // TODO: to remove
    SET_GUEST_USER(state) {
      state.listOfUsers["guest"] = {} as User;
      state.preferredOrderType = OrderType.getOrderTypeFromInt(OrderType.unknowOrderTypeInt);
      (state.listOfUsers["guest"] as User).usedAddress = { position: {} as AddressCoordinates } as AddressPosition;
      (state.listOfUsers["guest"] as User).isUserLoggedIn = false as boolean;
      state.currentUser = "guest";
    },

    UPDATE_USER(state, userNewInfo) {
      (state.listOfUsers[state.currentUser] as User).personalInfo.dateOfBirth = userNewInfo.dateOfBirth;
      (state.listOfUsers[state.currentUser] as User).personalInfo.firstName = userNewInfo.firstName;
      (state.listOfUsers[state.currentUser] as User).personalInfo.lastName = userNewInfo.lastName;
      (state.listOfUsers[state.currentUser] as User).personalInfo.gender = userNewInfo.gender;
      (state.listOfUsers[state.currentUser] as User).personalInfo.phoneNumber = userNewInfo.phoneNumber;
      (state.listOfUsers[state.currentUser] as User).personalInfo.email = userNewInfo.email;
    },

    INITIALIZE_TOKEN(state) {
      if(Object.keys(state.listOfUsers) .length > 0) {
        if(typeof (state.listOfUsers[state.currentUser] as User).bearerToken != "undefined") {
          _axios.defaults.headers.common["Authorization"] =  "Bearer " + (state.listOfUsers[state.currentUser] as User).bearerToken;
        }  
      }
    }, 

    SET_FIRST_TIME_OPENNED_VALUE(state, value: boolean) {
      state.isFirstTimeOpened = value;
    },

    //#endregion

    // #region Address
    ADD_LOCAL_ADDRESS(state, location: any) {
      // remove old local address
      if(!(state.listOfUsers[state.currentUser] as User).deliveryAddressesFromLocal || (state.listOfUsers[state.currentUser] as User).deliveryAddressesFromLocal.length > 0)
        (state.listOfUsers[state.currentUser] as User).deliveryAddressesFromLocal = [] as AddressPosition[];                      
      // create address object
      var newLocalAddress: AddressPosition = {
        isPrimary: true,
        city: location.addressInfo.city == null ? "" : location.addressInfo.city,
        country: location.addressInfo.country == null ? "" : location.addressInfo.country,
        county: location.addressInfo.area == null ? "" : location.addressInfo.area,
        label: location.addressInfo.label == null ? "" : location.addressInfo.label,            
        district: location.addressInfo.district == null ? "" : location.addressInfo.district,
        state: location.addressInfo.state == null ? "" :  location.addressInfo.state,
        streetName: location.addressInfo.street == null ? "" : location.addressInfo.street,
        countryCode: location.addressInfo.countryCode == null ? "" : location.addressInfo.countryCode, 
        area: location.addressInfo.area == null ? "" : location.addressInfo.area,
        tag: AddressTag.homeTag,
        tagDescription: "",
        additionalAddressInfo: location.additionalAddressInformation,
        isDefault: location.isDefault,
        id: location.addressId
      } as AddressPosition

            
      
      newLocalAddress.position = {
        latitude: location.addressPosition.lat,
        longitude: location.addressPosition.lng
      } as AddressCoordinates;

      // add new address to local delivery address
      (state.listOfUsers[state.currentUser] as User).deliveryAddressesFromLocal.push(newLocalAddress);
      store.commit("auth/SET_USER_USED_ADDRESS", newLocalAddress)
    },
    
    SET_USER_USED_ADDRESS(state, address: AddressPosition) {
      (state.listOfUsers[state.currentUser] as User).usedAddress = {} as AddressPosition;
      (state.listOfUsers[state.currentUser] as User).usedAddress = address;
    },

    UPDATE_LIST_OF_ADDRESSES_FORM_SERVER(state, listOfAddresses){
      (state.listOfUsers[state.currentUser] as User).deliveryAddressesFromServer = User.updateUserListOfAddress(listOfAddresses);
      // store.commit("auth/SET_USER_USED_ADDRESS", {});
    },

    UPDATE_USER_COMPLETE_ADDRESS(state, completeAddress: string) {
      if((state.listOfUsers[state.currentUser] as User).usedAddress)
        (state.listOfUsers[state.currentUser] as User).usedAddress.additionalAddressInfo = completeAddress;
    },

    UPDATE_LIST_OF_USER_ADDRESSES_FROM_SERVER(state, addressesFromServer) {
      if(addressesFromServer.length > 0) {       
        (state.listOfUsers[state.currentUser] as User).deliveryAddressesFromServer = addressesFromServer;
      }
    },

    // #endregion

    
    //#region Order
    SET_ORDER_TYPE(state, orderType: number) {
      state.preferredOrderType = OrderType.getOrderTypeFromInt(orderType);
    },
    //#endregion
  
    //#region ui    


    UPDATE_WEB_INIT_STATE(state, value: boolean) {
      state.isWebInitializing = value;
    }
    //#endregion    
  },

  actions: {
    authenticate({ commit }, credentials) {      
      return request({
        commit: commit,
        method: "post",
        url: Apis.loginSubUrl,
        data: credentials,
        loaderKey: "authenticationLoading",
        successCallback: (user: any) => {
          commit("SET_USER", user);
          _axios.defaults.headers.common["Authorization"] =
            "Bearer " + user.access_token;
          commit("ui/snackbar", { show: false }, { root: true });
        },
      })      
    },

    createAccount({ commit }, params) {
      return request({
        commit: commit,
        method: "POST",
        url: Apis.registerSubUrl,
        data: params,
        loaderKey: "accountLoading"
      })
    },

    forgotPassword({ commit }, params) {
      return request({
        commit: commit,
        method: "POST",
        url: Apis.forgotPwdSubUrl,
        data: params,
        loaderKey: "forgotPasswordLoading"
      })
    },
    
    resetPassword({ commit }, params) {
      return request({
        commit: commit,
        method: "POST",
        url: Apis.resetPwdSubUrl,
        data: params,
        loaderKey: "resetPasswordLoading"
      })
    },

    editAccount({ commit }, params) {
      return request({
        commit: commit,
        method: "POST",
        url: Apis.accountInfoChange,
        data: params,
        loaderKey: "editLoading"
      })
    },

    logout({ commit }) {
      _axios.defaults.headers.common["Authorization"] = null;
      commit("SET_USER", null);
    },
  },
};
