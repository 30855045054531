import { Apis } from "@/apiManager/Apis";
import _requestHelper from "@/apiManager/_requestHelper";
import { EPartnerStoresStructureType } from "@/enums/EPartnerStoresStructureType";
import { EStoreType } from "@/enums/EStoreType";
import { ETypeOfRequest } from "@/enums/ETypeOfRequest";
import AddressCoordinates from "@/models/AddressCoordinates";
import { ETypeOfBranchServices } from "@/models/ETypeOfBranchServices";
import PartnerSettings from "@/models/PartnerSettings";
import Store from "@/models/Store";
import StoreSettings from "@/models/StoreSettings";
import User from "@/models/User";
import storeModule from "@/store";
import { AuthenticationState } from "@/store/modules/authenticationModule";
import { PartnerSettingsState } from "@/store/modules/partnerModule";
import { StoreState } from "@/store/modules/storeModule";
import OrderTypeService from "./orderTypeService";
import OrderType from "@/models/OrderType";
import { UIState } from "@/store/modules/uiModule";
import { ECategorizationLayerGroup } from "@/models/StoreCategorizationsAndLayersStructure";
import store from "@/store";
    


export default class StoreService {    

    /**
     * Get list of elligible stores
     * @param typeOfService 
     * @returns Array<Store>
     */
    public static async getElligibleStores(typeOfService: ETypeOfBranchServices = ETypeOfBranchServices.All) {
        var listOfStoresToReturn: Array<Store> = [];

        // user info
        var currentUser = ((storeModule as any).state["auth"] as AuthenticationState).currentUser;        
        var currentUserObject: User = ((storeModule as any).state["auth"] as AuthenticationState).listOfUsers[((storeModule as any).state["auth"] as AuthenticationState).currentUser];
        
        var body = {
            shopperCoordinates: {
                latitude: currentUserObject.usedAddress.position.latitude,
                longitude: currentUserObject.usedAddress.position.longitude,
              },
              typeOfBranchServices: typeOfService
        }

        var storeObject = new Store();
        
        await _requestHelper({
            method: "post",
            url: Apis.listOfDiffrentStoresURL,
            data: body,
            loaderKey: "",
            successCallback: (stores: any) => {
                listOfStoresToReturn = stores.map((store:any) => storeObject.fetchStoreObjectFromJson(store));
            }
        })

        storeModule.commit("store/FETCH_LIST_OF_STORES", listOfStoresToReturn);
    }

    /**
     * Get store details using store id
     * @param storeId 
     */
    public static async getStoreDetails(storeId: number, forcePickup: boolean = false) {
        // get user object
        var currentUser = ((storeModule as any).state["auth"] as AuthenticationState).currentUser;        
        var currentUserObject: User = ((storeModule as any).state["auth"] as AuthenticationState).listOfUsers[((storeModule as any).state["auth"] as AuthenticationState).currentUser];
        
        var listOfDeliveryStores = ((storeModule as any).state["store"] as StoreState).listOfDeliveryStores;        
        var listOfPickupStores = ((storeModule as any).state["store"] as StoreState).listOfPickupStores;        
        var agnosticDeliveryStoreId = ((storeModule as any).state["store"] as StoreState).agnosticDeliveryStoreId;        
        var partnerSettings = ((storeModule as any).state["store"] as PartnerSettingsState).partnerSettings;        

        // prepare store details request payload
        var body = {
            branchId: storeId, 
            shopperCoordinates: {
                latitude: currentUserObject.usedAddress ? currentUserObject.usedAddress.position.latitude : 0,
                longitude: currentUserObject.usedAddress ?currentUserObject.usedAddress.position.longitude : 0,
            } as AddressCoordinates,
            getEligibilityInfo: true

        }

        var storeObject = new StoreSettings();

        // get store details request        
        await _requestHelper({
            method: "post",
            url: Apis.singleStoreURL,
            data: body,
            loaderKey: "",
            successCallback: async (storeDetails: any) => {

                // fetch store details
                storeObject = storeObject.fetchStoreDetailsObjectFromJson(storeDetails);
                storeModule.commit("store/FETCH_SELECTED_STORE_DETAILS", storeObject);
                storeModule.dispatch("cart/initializeCart", storeDetails.id)

                // when is store is selected and details of this store is fetched, we need to set the order type
                let orderTypeToBeSet: number = OrderType.unknowOrderTypeInt;

                // check if store is eligible for delivery
                if(listOfDeliveryStores.length > 0 && forcePickup == false) {                 
                    var deliveryStoreFound = listOfDeliveryStores.find(store => store.id == storeDetails.id);

                    if(deliveryStoreFound != null) {
                        orderTypeToBeSet = OrderType.deliveryOrderTypeInt;
                        
                        // set the order type
                        storeModule.commit("auth/SET_ORDER_TYPE", orderTypeToBeSet);
                    }                    
                }

                if(orderTypeToBeSet == OrderType.unknowOrderTypeInt) {
                    // check if store is eligible for pickup
                    if(listOfPickupStores.length > 0) {
                        var pickupStoreFound = listOfPickupStores.find(store => store.id == storeDetails.id);

                        if(pickupStoreFound != null) {
                            orderTypeToBeSet = OrderType.pickupOrderTypeInt;

                            // set the order type
                            storeModule.commit("auth/SET_ORDER_TYPE", orderTypeToBeSet);
                        }

                    }
                }
            }
        });

        
    }



    static getStoreByStoreId(storeId: number) : Store {
        var storesList = ((storeModule as any).state["store"] as StoreState).listOfStores as Array<Store>;                
        return storesList.find((storeElement: Store) => storeElement.id == storeId) ?? new Store();
    }


    static getStoreDisplayNameFromStoreId(storeId: number): string {
        var storeObject = this.getStoreByStoreId(storeId);

        if(storeObject == null)
            return "";
        return storeObject.displayName;
    }


    //#region Store Elligibility checks

      /** check if there any store eligible store, delivery or pickup */
      public static isAnyElligbleStoreAvailable() {
        // get list of stores
        var listOfStores = ((storeModule as any).state["store"] as StoreState).listOfStores;
        var agnosticDeliveryStore = ((storeModule as any).state["store"] as StoreState).agnosticDeliveryStoreId;

        var partnerSettings = ((storeModule as any).state["partner"] as PartnerSettingsState).partnerSettings;
        
        // check if there is any delivery store
        var deliveryStores = listOfStores.filter((store) => this.isStoreAvailableForDelivery(store.id, false));

        // check if there is any pickup store
        var pickupStores = listOfStores.filter((store) => this.isStoreAvailableForPickup(store.id));

        if(partnerSettings.partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel) {
            return pickupStores.length > 0 || agnosticDeliveryStore > 0
        }

        else {
            return deliveryStores.length > 0 || pickupStores.length > 0
        }
        
    }

    /** Check if store & customer are eligible for delivery */
    static isStoreAvailableForDelivery(storeId: number, checkAgnosticStore: boolean, checkFromListOfStores: boolean = false): boolean {        
        var partnerSettings = ((storeModule as any).state["partner"] as PartnerSettingsState).partnerSettings as PartnerSettings;         
        var agnosticDeliveryStore = ((storeModule as any).state["store"] as StoreState).agnosticDeliveryStoreId;
        var storeObject = {} as Store | StoreSettings;

        if(checkFromListOfStores == true) {
            storeObject = this.getStoreByStoreId(storeId);
        }

        else {
            storeObject = ((storeModule as any).state["store"] as StoreState).selectedStoreDetails;
        }

        if(storeObject == null) {
            return false;
        }

        if(checkAgnosticStore && partnerSettings.partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel) {
            return agnosticDeliveryStore > 0;
        }

        else {
            return partnerSettings.isDeliveryFeatureEnabled 
                && storeObject.hasDeliveryService
                && storeObject.deliveryZone && storeObject.deliveryZone.isCustomerEligibleForDelivery;
        }
    
    }

    /** Check if store & customer are eligible for pickup */
    static isStoreAvailableForPickup(storeId: number, checkFromListOfStores: boolean = false) {
        var partnerSettings = ((storeModule as any).state["partner"] as PartnerSettingsState).partnerSettings as PartnerSettings;
        var storeObject = {} as Store | StoreSettings;

        if(checkFromListOfStores == true) {
            storeObject = this.getStoreByStoreId(storeId);
        }

        else  {
            storeObject = ((storeModule as any).state["store"] as StoreState).selectedStoreDetails;
        }

        if(storeObject == null)
            return false;

        return  (partnerSettings as PartnerSettings).isPickupFeatureEnabled 
            && storeObject.hasPickupService
            && storeObject.pickupZone
            && storeObject.pickupZone.id > 0
            && storeObject.isCustomerEligibleForPickup == true;
    }    
    //#endregion
  
    static async getStoreHomePageData() {
        var selectedStoreDetails = ((storeModule as any).state["store"] as StoreState).selectedStoreDetails;
        var isMobileViewDisplayed = ((storeModule as any).state["ui"] as UIState).isMobileViewDisplayed;
        
        storeModule.dispatch("schedule/getStoreHomePageFirstAvailableSlots");


        
        if(selectedStoreDetails.categorizationAndStructureLayer.isDepartmentsHigherStructure) {
            storeModule.dispatch("product/getDepartments", {
                branchId: selectedStoreDetails.id,
                isLight: false,
                includeDynamicCampaigns: true
            })
        }

        else { 
            storeModule.dispatch("product/getCategories", {
                branchId: selectedStoreDetails.id,
                isLight: false,
                includeDynamicCampaigns: true
            })    
        }
        

        storeModule.dispatch("product/getStoreHomePage", {
            branchId: selectedStoreDetails.id,
            search: "",
            light: false,
            includeProbablyOOS: true,
            sortBy: 0,
            typeOfRequest: selectedStoreDetails.storeType != EStoreType.Convenient ? ETypeOfRequest.BySubCat : ETypeOfRequest.ByCategory,
            subCategoryId: 0,
            categoryId: 0,
            requestedFrom: 1,
            isWsaFromMobile: isMobileViewDisplayed,
            includeDynamicCampaigns: true
        })

        // store.dispatch("cusService/getInAppMsg", {
        //     branchId: selectedStoreDetails.id
        // })
      

    }


    //-----------------------------------------------//
    //---------------- New Function -----------------//
    //-----------------------------------------------//

    static checkIfStoreIsEligibileForDelivery(store: Store): boolean {
        var partnerSettings = ((storeModule as any).state["partner"] as PartnerSettingsState).partnerSettings as PartnerSettings;

        return partnerSettings.isDeliveryFeatureEnabled 
                && store.hasDeliveryService
                && store.deliveryZone && store.deliveryZone.isCustomerEligibleForDelivery;
    }

    static checkIfStoreIsEligibileForPickup(store: Store): boolean {
        var partnerSettings = ((storeModule as any).state["partner"] as PartnerSettingsState).partnerSettings as PartnerSettings;

        return  (partnerSettings as PartnerSettings).isPickupFeatureEnabled 
            && store.hasPickupService
            && store.pickupZone
            && store.pickupZone.id > 0
            && store.isCustomerEligibleForPickup == true;
    }

    /**
     * Get list of eligible stores + fetch the list in the store Module
     */
    static async getListOfEligibleStoresFromServer() {
         // user info
         var currentUser = ((storeModule as any).state["auth"] as AuthenticationState).currentUser;        
         var currentUserObject: User = ((storeModule as any).state["auth"] as AuthenticationState).listOfUsers[((storeModule as any).state["auth"] as AuthenticationState).currentUser];

        var body = {
            shopperCoordinates: {
                latitude: currentUserObject.usedAddress.position.latitude,
                longitude: currentUserObject.usedAddress.position.longitude,
              },
              typeOfBranchServices: ETypeOfBranchServices.All
        }

        let listOfStoresToReturn: Array<Store> = [];
        var storeObject = new Store();

        await _requestHelper({
            method: "post",
            url: Apis.listOfDiffrentStoresURL,
            data: body,
            loaderKey: "",
            successCallback: (stores: any) => {
                listOfStoresToReturn = stores.map((store:any) => storeObject.fetchStoreObjectFromJson(store));
            }
        })

        storeModule.commit("store/FETCH_LIST_OF_STORES", listOfStoresToReturn);
    }
}