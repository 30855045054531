
import Currency from "@/models/Currency";
import StoreSettings from "@/models/StoreSettings";
import Vue from "vue";
import MoneyFormat from "vue-money-format/src/money-format.vue";
import { mapState } from "vuex";
export default Vue.extend({
  components: {
    MoneyFormat,
  },

  props: {
    price: {
      required: true,
      type: Number,
      default: 0
    },

    currencyCode: {
      required: false,
      type: String
    },

    roundingType: {
      required: false,
      type: Number,
      default: 0
    },

    typeOfAmount: {
      required: false,
      type: Number,
      default: 1
    },
  },

  computed: {
    ...mapState("store", ["selectedStoreDetails"]),
    ...mapState("partner", ["partnerSettings"]),

    selectedStoreCurrency() : Currency | null {
      return ((this as any).selectedStoreDetails as StoreSettings) && ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency
        ?  ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency
        : null
    },

    currencyCodeToUse(): string | undefined {
      return this.currencyCode != null || typeof this.currencyCode !== 'undefined' ? this.currencyCode : this.selectedStoreCurrency?.currencyCode
    },

    roundingTypeToUse(): number | string | undefined {
      return this.roundingType != null || typeof this.roundingType !== 'undefined' ? this.roundingType : this.selectedStoreCurrency?.roundingType
    },


    style(): string {

      // promoted
      if(this.typeOfAmount == 2) {
        return `color: ${this.partnerSettings.itemPromotedTagTextColor} !important`
      }

      // old price
      if(this.typeOfAmount == 3) {
        return `font-size: 10px; text-decoration: line-through grey;`
      }

      // old price
      if(this.typeOfAmount == 4) {
        return `font-size: 20px !important; color: #fff;`
      }

      return '';
    }
  }
});
