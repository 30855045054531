import { Module } from "vuex";
import request, { _axios } from "../../apiManager/_requestHelper";
import store, { RootState } from "..";
import { Apis } from "@/apiManager/Apis";
import AddressPosition from "@/models/AddressPosition";

export interface MapState {
  geoLocation: {};
  loading: boolean;
  autocomplete: any[];
  address: AddressPosition;

  // LOADING STATE OF EACH REQUEST
  geoLocationLoading: boolean;
  autocompleteLoading: boolean;
}

export default <Module<MapState, RootState>>{
  namespaced: true,
  state: {
    geoLocation: {},
    loading: false,
    autocomplete: [],
    address: {} as AddressPosition,

    // LOADING STATE OF EACH REQUEST
    geoLocationLoading: false,
    autocompleteLoading: false,
  },

  mutations: {
    SET_GEOLOCATION(state, location) {
      state.geoLocation = location.candidates[0].geometry.location;
    },

    FETCH_AUTOCOMPLETE(state, autocomplete) {
      state.autocomplete = autocomplete.predictions.map((p: any) => {
        return p.description;
      });
    },

    FETCH_ADDRESS(state, address: any) {
      state.address = {
        area: address.area,
        city: address.city,
        country: address.country,
        county: address.area,
        label: address.label,
        district: address.district,
        state: address.state,
        streetName: address.street,
        countryCode: address.countryCode,  
        postalCode: address.PostalCode,
        houseNumber: address.HouseNumber,
        position: { 
          latitude: address.coordinatesLatitude, 
          longitude: address.coordinatesLongitude, 
        }        
      } as AddressPosition;      
    },
  },

  actions: {
    getGeoLocation({ commit }, params) {
      return request({
        commit: commit,
        method: "POST",
        url: Apis.mapSearchPlacesSubUrl,
        data: params,
        loaderKey: "geoLocationLoading",
        successCallback: (location: any) => {
          commit("SET_GEOLOCATION", location);          
        },
      })
    },

    mapSearchAutoComplete({ commit }, params) {
      return request({
        commit: commit,
        method: "POST",
        url: Apis.mapAutocompletePlacesSubUrl,
        loaderKey: "mapAutoCompleteLoading",
        data: params,
        successCallback: (autocomplete: any) => {
          commit("FETCH_AUTOCOMPLETE", autocomplete);
        },
      })
    },

    getAddress({ commit }, params) {
      return request({
        commit: commit,
        method: "POST",
        url: Apis.mapGetAddressSubUrl,
        data: params,
        loaderKey: "locationInfoLoading",
        hideErrorToast: true,
        successCallback: (address: any) => {
          commit("FETCH_ADDRESS", address);
        },
      }).catch(() => {
          commit("FETCH_ADDRESS", {});
      })
    },
  },
};
