
import FirebaseAnalyticsScreensNames from '@/firebase/firebaseAnalyticsScreensNames'
import { Settings } from '@/Settings/Settings'
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import WsaButton from '../UI/Button/wsa-button.vue'
import WsaTextField from '../UI/TextFields/WsaTextField.vue'
import UITitle from '../UI/Text/UITitle.vue'
import OtpValidation from './OtpValidation.vue'
import phoneNumberMixin from "@/mixins/phoneNumberMixin"
import { EPartnerStoresStructureType } from '@/enums/EPartnerStoresStructureType'
import { ERedirectTo } from '@/enums/ERedirectTo'
import AddressPosition from '@/models/AddressPosition'
import PartnerSettings from '@/models/PartnerSettings'
import User from '@/models/User'
import routerManager from '@/routerManager'
import FlowFunctions from '@/flows/FlowFunctions'
import { Apis } from '@/apiManager/Apis'
import _requestHelper from '@/apiManager/_requestHelper'
import WsaPhoneNumber from "../UI/TextFields/WsaPhoneNumber.vue";
import BackIcon from '../UI/Icons/BackIcon.vue'

export default Vue.extend({
    components: {
        "ui-title": UITitle,
        "wsa-button": WsaButton,
        "otp-validation": OtpValidation,
        "wsa-text-field": WsaTextField,
        "wsa-phone-number": WsaPhoneNumber,
        "back-icon" :BackIcon
    },

   
    mixins: [phoneNumberMixin],

    data: () => ({
        isPasswordVisisble: false as boolean,
        
        passwordRule: [
            (v: any) =>
            (typeof v != "undefined" && v != "" && v.length > 7) ||
            "This field is required",
        ],

        rule: [
            (v: any) =>
                (typeof v != "undefined" && v != "") || "This field is required",
        ],

        params: {
            phoneNumber: "",
            smsRetryCount: 1,
            otpToken: "",
            password: "",
            tempPhoneNumber: ""
        },

        step: 1 as number,
        forgotPasswordLoading: false as boolean
    }),

    computed: {
        ...mapState("partner", ["appDataContent", "partnerSettings"]),
        ...mapState("store", ["selectedStoreDetails", "listOfStores"]),
        ...mapState("auth", ["isFirstTimeOpened"]),
        ...mapState("address", ["listOfUserAddresses"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),

        ...mapGetters("auth", ["currentSelectedUser"]),

        userObject() : User{
            return this.currentSelectedUser;
        }
    },

    methods: {
        ...mapActions("auth", ["forgotPassword", "resetPassword", "authenticate"]),
        ...mapActions("store", ["getEligibleStores", "getStoreDetails"]),
        ...mapActions("maps", ["getAddress"]),
        ...mapActions("address", ["createAddress","updateAddress", "getUserAddresses"]),

        ...mapMutations("auth", ["ADD_LOCAL_ADDRESS", "SET_USER_USED_ADDRESS", "SET_FIRST_TIME_OPENNED_VALUE"]),
        ...mapMutations("firebase", ["LOG_FIREBASE_SCREEN"]),

        // get list of stores
        async getEligibleStoresList() { 
            await this.getEligibleStores({
                shopperCoordinates: {
                latitude: this.userObject.usedAddress?.position.latitude,
                longitude:this.userObject.usedAddress?.position.longitude,
                },
            });
        },

        async goToStoresOrStoreHomePageDependingOnThePartnerStoreStruct() {
        // check partner struct type
        if( (this.partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.MultiStoreSelectionModel || (this.partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel ) {                          
            // go to stores page
            routerManager.goToStoresPage();
        }

        else if( (this.partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.SingleStoreModel ) {
            // get eligible stores using used address
            if(typeof this.userObject.usedAddress != 'undefined') {
            await this.getEligibleStoresList().then(
                async () => {
                if(this.listOfStores.length > 0) {
                    await this.getStoreDetails({branchId:this.listOfStores[0].id}).then(() => {
                    
                    //go to store home page            
                    routerManager.goToStoreHomePage();

                    })    
                }
                else {
                    routerManager.goToStoresPage(true)
                }      
                }
            );
            }
        }        
        },  

        createOrUpdateAddress(adrId: number = 0) {
        var adrObj = {   
            id: adrId,
            tag: this.userObject.usedAddress?.tag.id,
            tagDescription: this.userObject.usedAddress?.tagDescription,
            streetNumber: this.userObject.usedAddress?.streetNbre,
            streetName: this.userObject.usedAddress?.streetName,
            postalCode: this.userObject.usedAddress?.postalcode,
            city: this.userObject.usedAddress?.city,
            area: this.userObject.usedAddress?.area,
            state: this.userObject.usedAddress?.state,
            country: this.userObject.usedAddress?.country,
            countryCode: this.userObject.usedAddress?.countryCode,
            additionalAddressInformation: this.userObject.usedAddress?.additionalAddressInfo,
            coordinatesLongitude: this.userObject.usedAddress?.position.longitude,
            coordinatesLatitude: this.userObject.usedAddress?.position.latitude,
            IsMultiAddressSA: true
        } as any;

        if(adrId > 0) {        
            this.updateAddress({ address: adrObj })
            .then(() => {
            this.getUserAddresses()
            .then(() => {
                var primaryAddress = this.userObject.deliveryAddressesFromServer.find(adr => adr.isPrimary == true);
                this.SET_USER_USED_ADDRESS(primaryAddress)
            });
            });
        }

        else {
            this.createAddress({ address: adrObj })
            .then(() => {
                this.getUserAddresses()
                .then(() => {
                var primaryAddress = (this.userObject.deliveryAddressesFromServer as AddressPosition[]).find(adr => adr.isPrimary == true);
                this.SET_USER_USED_ADDRESS(primaryAddress)
                });
            });      
        }
        },



        forgotPasswordRequest() {
            this.sendSms()
        },

        async resetPasswordRequest() {
            var phoneNumberToUse = this.params.phoneNumber.replace('+', '');

            await this.resetPassword({
                phoneNumber: phoneNumberToUse,
                password: this.params.password,
                token: this.params.otpToken,
            })

            

           _requestHelper({          
                method: "post",
                url: Apis.loginSubUrl,
                data: {
                    phoneNumber: phoneNumberToUse,
                    password: this.params.password,
                    oldFcmToken: Settings.old_fcm_token,
                    deviceType: Settings.device_type,
                    isNewUser: false,
                },
                loaderKey: "authenticationLoading",
                successCallback: async (user: any) => {
                    if(typeof user != "undefined") {    
                        this.$store.commit("auth/SET_USER", user);
                        
                        // get list of addresses from server
                        await this.getUserAddresses()
                        .then(async () => {
                            var adrFromServer = this.listOfUserAddresses as AddressPosition[];     
                            var primaryAddress = adrFromServer != null ? adrFromServer.find(adr => adr.isPrimary == true) : {} as AddressPosition;


                            // if there is any primary address -> update address by the new local address
                            this.createOrUpdateAddress(primaryAddress!=null? primaryAddress.id : 0);                                                        
                        });

                        await FlowFunctions.afterLoginFlow(this.$route.name);              
                                    
                        switch(this.redirectTo as ERedirectTo) {
                            case ERedirectTo.NoWhere: this.$emit("closeDialog"); break;
                            case ERedirectTo.Checkout: routerManager.goToCheckoutPage(); this.$emit("closeDialog"); break;
                            default: this.$emit("closeDialog"); break;
                        }    

                        this.$emit("closeDialog");
                    }           
                }
           })

            
        },

        goBack() {
            if(this.step == 1) { 
                this.$emit("setLoginPage");
            }
            else if( this.step == 2) {
                this.step = 1;
            }
        },

        async sendSms() {


            if(this.params.tempPhoneNumber.trim().length == 0) {
                this.$store.commit("ui/snackbar", {
                    error: true,
                    message: this.appDataContent.authLoginEmptyMobileNbreTxtBnr,
                    show: true
                })
            }

            else if(this.params.password.trim().length == 0) {
                this.$store.commit("ui/snackbar", {
                    error: true,
                    message: this.appDataContent.authLoginEmptyPwdTxtBnr,
                    show: true
                })
            }

            else if(this.params.password.trim().length < 8) {
                this.$store.commit("ui/snackbar", {
                    error: true,
                    message: this.appDataContent.authForgotPwdLenghPwdIncorrecrTxtBnr,
                    show: true
                })
            }

            else {
                this.forgotPasswordLoading = true;
                this.params.smsRetryCount++;

                await this.forgotPassword( {
                    phoneNumber: this.params.phoneNumber.replaceAll('+', ''),
                    smsRetryCount: this.params.smsRetryCount,
                })
                .then(() => {
                    this.step = 2;
                })
                .finally(() => {
                    this.forgotPasswordLoading = false;
                })
            }

        }
    },

    watch: {
        step(value: number) {
            if(value == 2) {
                // firebase analytics
                this.LOG_FIREBASE_SCREEN(FirebaseAnalyticsScreensNames.resetPasswordPage);
            }
        }
    },

    mounted() {
        // firebase analytics
        this.LOG_FIREBASE_SCREEN(FirebaseAnalyticsScreensNames.forgotPasswordPage);
    }


})
