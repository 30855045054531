
import MainDialogCard from '@/components/MainDialogComponents/MainDialogCard.vue';
import PartnerLogo from '@/components/PartnerLogo/PartnerLogo.vue';
import WsaTextField from '@/components/UI/TextFields/WsaTextField.vue';
import PartnerSettings from '@/models/PartnerSettings';
import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
  components: { WsaTextField, MainDialogCard, PartnerLogo },
    
    data:() => ({
        params: {
            subject: ""
        }
    }),
    
    computed: {
        ...mapState("partner", ["partnerSettings", "appDataContent"])
    },

    methods: {
        backBtnClicked() {
            this.$emit("closeDialog");
        },

        openWpChat(){ 
            var anchor = document.createElement('a');
            anchor.href = `https://wa.me/${(this.partnerSettings as PartnerSettings).customerServiceWhatsAppNumber}`;
            anchor.target="_blank"
            anchor.click();
        }
    }
})
