
import Vue from 'vue'
export default Vue.extend({
    props: {
        lightMode: {
            default: true,
            type: Boolean,
            required: false
        },

        addPadding: {
            default: true,
            type: Boolean,
            required: false
        }
    }
})
