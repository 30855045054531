
import Vue from "vue";
import {mapState, mapActions, mapMutations } from "vuex";
import StoreSettings from "@/models/StoreSettings";
import AlcoholWarningDialog from "@/components/AlcoholWarningDialog.vue";
import TobaccoWarningDialog from "@/components/TobaccoWarningDialog.vue";

export default Vue.extend({
  components: { 
    "alcohol-warning-dialog": AlcoholWarningDialog,
    "tobacco-warning-dialog": TobaccoWarningDialog
  },


  name: "category-card",

  props: {
    loading: {
      required: false,
      default: false,
      type: Boolean
    },

    category: {
      required: true,
      type: Object
    },
    
    departmentId: {
      required: false,    
    },

    departmentName: {
      required: false,
    },



    index: {
      required: true,
      type: Number
    },

    elementWidth: {
      required: false,
      default: '80px'
    },

    elementHeight: {
      required: false,
      default: '80px'
    },
  },

  data: () => ({
    alcoholWarningDialog: false as boolean,
    tobaccoWarningDialog: false as boolean,
  }),

  computed:{
    ...mapState("partner",["partnerSettings"]),
    ...mapState("store",["selectedStoreDetails"]),
  },


  methods: {
    ...mapActions("product", ["getItems"]),
    ...mapMutations("product", ["SET_LAST_CAT_SELECTED"]),

    getToUrl(): any {
      if( ((this as any).selectedStoreDetails as StoreSettings).categorizationAndStructureLayer.isDepartmentsHigherStructure 
        && ((this as any).selectedStoreDetails as StoreSettings).categorizationAndStructureLayer.isSubcategoriesLowestStructure)
      {        
        return {
              name: "productsPageByCategoryByDepartment",
              params: {
                departmentId: this.$route.params["departmentId"],
                departmentName: this.$route.params["departmentName"],
                categoryId: this.category.id,
                categoryName: this.category.name,
                campaignCollectionId:  this.category.isCustomCategory == true ? this.category.id : 0,
                itemsCollectionType: this.category.isCustomCategory == true ? this.category.itemsCollectionType : 0
              },
              query: { 
                  type: this.category.isCustomCategory == true ? this.category.itemsCollectionType : 0,
                  typeId: this.category.isCustomCategory == true ? this.category.id : 0,
              }
          }
      }

      else {
        if(!((this as any).selectedStoreDetails as StoreSettings).categorizationAndStructureLayer.isSubcategoriesLowestStructure) {
          return {
            name: "subcategoriesPage",
            params: {
              storeId: ((this as any).selectedStoreDetails as StoreSettings).id,
              storeName: ((this as any).selectedStoreDetails as StoreSettings).displayName,
              categoryId: this.category.id,
              categoryName: this.category.name
            }
          }
        }

        else { 
          return {
              name: "productsPageByCategory",
              params: {
                categoryId: this.category.id,
                categoryName: this.category.name,
                campaignCollectionId:  this.category.isCustomCategory == true ? this.category.id : 0,
                itemsCollectionType: this.category.isCustomCategory == true ? this.category.itemsCollectionType : 0
              },
              query: { 
                  type: this.category.isCustomCategory == true ? this.category.itemsCollectionType : 0,
                  typeId: this.category.isCustomCategory == true ? this.category.id : 0,
              }
          }
        }
      }
      
    },

    proceed(skipCheck: boolean = false) {

      if(skipCheck == false) {
        if(this.category.isAlcohol == true) {
          this.alcoholWarningDialog = true;
        }

        else if(this.category.isTobacco == true) {
          this.tobaccoWarningDialog = true;
        }
      }

      else {
        this.$router.push(this.getToUrl())
      }
    },
      
    // categories image url
    getImgUrl(item: any): string {
        if(item.isCustomCategory == true)
          return item.image;

        return (this as any).partnerSettings.blobUrl + `categories/${(this as any).partnerSettings.partnerId}/${item.image}`;
    }
    
  }  
});
