<template>
    <vue-barcode margin-left="-8px" margin-bottom="-4px"  :value="value" tag="svg" width="2" height="75" :display-value="false"></vue-barcode>
</template>

<script lang="js">
import VueBarcode from 'vue-barcode';
import Vue from 'vue'
export default Vue.extend({

    props: ["value"],

    components:{ 
        "vue-barcode": VueBarcode
    }
})
</script>