import { EStoreType } from "@/enums/EStoreType";
import store from "@/store";
import { ProductState } from "@/store/modules/productModule";
import { StoreState } from "@/store/modules/storeModule";
import router from "./router";
import Routes from "./routes";

function gotToRoute(routeName: string, params?: any){
    var routeObject = {} as any;
    routeObject.name = routeName;

    if(params != null || typeof params != 'undefined')
        routeObject.params = params

    router.push(routeObject).catch(err => {});
}

function goBack() {
    router.go(-1);
}

function goToStoreHomePage(getCategoriesRequest: boolean = true, item: any = null) {
    var selectedStoreDetails = ((store as any).state["store"] as StoreState).selectedStoreDetails;
       

    var params = {
        branchName: selectedStoreDetails.displayName.toLocaleLowerCase().replaceAll(" ", "-"),
        branchId: selectedStoreDetails.id.toString(),
        getCategoriesRequest: getCategoriesRequest,
    } as any;

    if(item != null ){
        params.itemId = item.id;
        params.itemName = item.name.toLocaleLowerCase().replaceAll(" ", "-");
    }

    gotToRoute(Routes.storeHomePage.name, params)
    

   
}

function goToStoresPage(isEmptyPageDisplayed: boolean = false) {
    gotToRoute(Routes.stores.name, {isEmptyPageDisplayed: isEmptyPageDisplayed});
}

function goToContactUsPage() {
    gotToRoute(Routes.contactUs.name, {});
}

function goToLoginPage() {
    gotToRoute(Routes.login.name, {});
}

function goToStoreDetailsPage() {
    var selectedStoreDetails = ((store as any).state["store"] as StoreState).selectedStoreDetails;

    gotToRoute(Routes.storeDetails.name, {
        branchName: selectedStoreDetails.displayName.toLowerCase(),
    });
}

function goToProfilePage() { 
    gotToRoute(Routes.profile.name, {});
}

function goToProductsByCatSubCat(category: any, subcat: any, item: any = null) {
    var selectedStoreDetails = ((store as any).state["store"] as StoreState).selectedStoreDetails;
    var isStoreTypeConvinientOrDiscounter = selectedStoreDetails.storeType == EStoreType.Convenient || selectedStoreDetails .storeType == EStoreType.Discounter;

    var params = {
        branchName: selectedStoreDetails.displayName.toLowerCase().replace(" ", "-"),
        categoryId: category.id,
        category: category.name.toLowerCase().replaceAll(" & ", "-").replaceAll(" ", "-"),
        categoryImage: category.image,
        subcatId: subcat ? subcat.id : "",
        subcat: subcat ? subcat.name.toLowerCase().replace(" ", "-") : "",
        title: isStoreTypeConvinientOrDiscounter ? category.name : subcat.name,
    } as any;
    

    if(item != null ){
        params.itemId = item.id;
        params.itemName = item.name;
    }

    if(isStoreTypeConvinientOrDiscounter)
        gotToRoute(Routes.productsByCategory.name, params);
    
    else
        gotToRoute(Routes.productsBySubCategory.name, params);
}


function goToCheckoutPage() {
    gotToRoute(Routes.checkout.name, {});
}

function goToOrderSummaryPage(orderId: string) {
    gotToRoute(Routes.orderSummary.name, {
        orderId: orderId
    })
}

function goToRegisterPage() {
    gotToRoute(Routes.register.name, {})
}

function goToItemDetailsFromStoreHomePage(item: any) {
    goToStoreHomePage(false, item);
}

function goToItemDetailsFromProductsByCatSubCat(category: any, subcat: any, item: any) {
    goToProductsByCatSubCat(category, subcat, item);
}


function goToLoginOrGuestPage() {
    gotToRoute(Routes.loginOrGuest.name, {});
}

function goToLocationPage() {
    gotToRoute(Routes.location.name ,{});
}

function goToOrdersPage() {
    gotToRoute(Routes.orders.name)
}

function goToSearchPage(search: string) {
    var selectedStoreDetails = ((store as any).state["store"] as StoreState).selectedStoreDetails;

    var params = {
        branchName: selectedStoreDetails.displayName.toLowerCase().replace(" ", "-"),        
        search: search,
    } as any;

        gotToRoute(Routes.productsBySearch.name, params);   
}

function goToItemsBySectionPage(section: string) {
    var selectedStoreDetails = ((store as any).state["store"] as StoreState).selectedStoreDetails;

    var params = {
        branchName: selectedStoreDetails.displayName.toLowerCase().replace(" ", "-"),        
        section: section,
    } as any;

        gotToRoute(Routes.productsBySection.name, params);   
}


function goToProductsBySeachBySubcat(subcat: any) {
    var selectedStoreDetails = ((store as any).state["store"] as StoreState).selectedStoreDetails;

    var params = {
        branchName: selectedStoreDetails.displayName.toLowerCase().replace(" ", "-"),     
        subcat: subcat ? subcat.name.toLowerCase().replace(" ", "-") : "",
        title: subcat.name,
    } as any;

    gotToRoute(Routes.productsBySearchBySubcat.name, params);
    
}


function goToWalletPage() {
    gotToRoute(Routes.wallet.name)
}

function goToLoyaltyPage() {
    gotToRoute(Routes.loyaltyProgram.name)
}

var routerManager = {
    gotToRoute,
    goToStoreHomePage,
    goToStoresPage,
    goToContactUsPage,
    goToLoginPage,
    goToStoreDetailsPage,
    goToProfilePage,
    goToProductsByCatSubCat,
    goToItemDetailsFromStoreHomePage,
    goToItemDetailsFromProductsByCatSubCat,
    goToCheckoutPage,
    goToOrdersPage,
    goToOrderSummaryPage,
    goToRegisterPage,
    goBack,
    goToLoginOrGuestPage,
    goToLocationPage,
    goToSearchPage,
    goToItemsBySectionPage,
    goToProductsBySeachBySubcat,
    goToWalletPage,
    goToLoyaltyPage
}

export default routerManager;