
import BranchSection from "@/components/Branch/BranchSectionInfo.vue";
import UISubtitle from "@/components/UI/Text/UISubtitle.vue";
import UIPopup from "@/components/UI/Popup/UIPopup.vue";
import LogoComponent from "@/components/UI/Logo/logoComponent.vue";

import { mapState } from "vuex";
import Vue from "vue";
import BulletPoint from "./BulletPoint.vue";
export default Vue.extend({
  components: {
    BranchSection,
    "ui-subtitle": UISubtitle,
    BulletPoint,
    "ui-popup": UIPopup,
    "logo-component": LogoComponent
  },

  data: () => ({
    weekDays: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
  }),
  computed: {
    ...mapState("store", ["selectedStoreDetails"]),
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
});
