
import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  props: ["onClick", "title", "message"],

  computed: {
    ...mapState("partner", ["appDataContent"])
  }
});
