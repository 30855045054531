import { Module } from "vuex";
import { RootState } from "..";

interface LoadingState{
    // Auth Module
    authenticationLoading: boolean;
    accountLoading: boolean;
    forgotPasswordLoading: boolean;
    resetPasswordLoading: boolean;
    editLoading: boolean;

    // product Module
    listOfItemsLoading: boolean;
    categoriesLoading: boolean;
    searchItemsLoading: boolean;
    similarItemsLoading: boolean;

    // user address
    createAddressLoading: boolean;
    setAddressPrimaryLoading: boolean;
    updateAddressLoading: boolean;
    deleteAddressLoading: boolean;
    userAddressesLoading: boolean;

    // map
    geoLocationLoading: boolean;
    autocompleteLoading: boolean;
    mapAutoCompleteLoading: boolean;
    locationInfoLoading: boolean;

    // store home page
    storeHomePageLoading: boolean;

    // customer service
    contactUsLoading: boolean;
    inAppMsgLoading: boolean;

    // categories
    listOfCategoriesLoading: boolean;

    // order
    placeOrderLoading: boolean;
    listOfOrdersLoading: boolean;
    orderSummaryLoading: boolean;
    promoCodeValidationLoading: boolean;
    firstAvailableSlotsLoading: boolean;
    serviceFeeLoader: boolean;

    // partner
    partnerSettingsLoading: boolean;
    appDataContentLoading: boolean;

    // store
    listOfStoresLoading: boolean;
    storeDetailsLoading: boolean;

    // schedule
    availableSlotsLoading: boolean;
}

export default <Module<LoadingState,RootState>>{
    namespaced: true,
    state: {
        // authentication
        authenticationLoading: false,
        accountLoading: false,
        forgotPasswordLoading: false,
        resetPasswordLoading: false,
        editLoading: false,

        //product Module
        listOfItemsLoading: false,
        categoriesLoading: false,
        searchItemsLoading: false,
        similarItemsLoading: false,

        // user address
        createAddressLoading: false,
        setAddressPrimaryLoading: false,
        updateAddressLoading: false,
        deleteAddressLoading: false,
        userAddressesLoading: false,

        // map
        geoLocationLoading: false,
        autocompleteLoading: false,
        mapAutoCompleteLoading: false,
        locationInfoLoading: false,
    
        // store home page
        storeHomePageLoading: false,

        // customer service
        contactUsLoading: false,
        inAppMsgLoading: false,

        // categories
        listOfCategoriesLoading: false,

        // order
        placeOrderLoading: false,
        listOfOrdersLoading: false,
        orderSummaryLoading: false,
        promoCodeValidationLoading: false,
        firstAvailableSlotsLoading: false,
        serviceFeeLoader: false,

        // partner
        partnerSettingsLoading: false,
        appDataContentLoading: false,

        // store
        listOfStoresLoading: false,
        storeDetailsLoading: false,

        // schedule
        availableSlotsLoading: false,
    },

    mutations: {
        // LOADING STATE
        DISABLE_LOADING_STATE(state, loaderKey){           
            state[loaderKey as keyof typeof state] = false;    
        },

        ENABLE_LOADING_STATE(state, loaderKey){           
            state[loaderKey as keyof typeof state] = true;        
        },
    },
}