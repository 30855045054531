
import Vue from 'vue'
import HeaderBar from "@/components/UI/HeaderBar/UIHeaderBar.vue";
import UIMoneyFormatter from "@/components/UI/Money/UIMoneyFormat.vue";
import { mapGetters, mapState } from 'vuex';
import _requestHelper from '@/apiManager/_requestHelper';
import { Apis } from '@/apiManager/Apis';
import User from '@/models/User';
import CloseIcon from '@/components/UI/Icons/CloseIcon.vue';
import WsaTextField from '@/components/UI/TextFields/WsaFormTextField.vue';
import WsaButton from '@/components/UI/Button/wsa-button.vue';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import BarcodeGenerator from '@/components/BarcodeGenerator.vue'

enum ELoyaltyStatus {
    Inexisting = 0,
    Validated = 10,
    CreationInProgress = 20,
    ValidationInProgress = 30,
    Rejected = 40,
}

export default Vue.extend({
    components: {
        "header-bar": HeaderBar,
        "ui-money-format": UIMoneyFormatter,
        "close-icon": CloseIcon,
        "wsa-text-field": WsaTextField,
        "wsa-button": WsaButton,
        "pulse-loader": PulseLoader,
        "barcode-generator": BarcodeGenerator
    },

        
    data: () => ({
        loyaltyInfo: {
            status: 0
        } as any,
        eLoyaltyStatus: ELoyaltyStatus,

        loyaltyStatus: {
            0: "Inexisting",
            10: "Validated",
            20: "Creation In Progress",
            30: "Validation In Progress",
            40: "Rejected"
        },

        loading: false as boolean,
        loyaltyFormDialog: false as boolean,

        loyaltyCardNumberModel: "" as string,
        loyaltyCardPhoneNumberModel: "" as string,
    }),

    computed: {
        ...mapState("partner", ["partnerSettings", "appDataContent"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),
        ...mapGetters("auth", ["currentSelectedUser"]),

        user(): User {
            return (this as any).currentSelectedUser;
        }
    },

    methods: {
        onAddBtnClick() {
            this.loyaltyFormDialog = true
        },

        getLoyaltyInfo() {
            this.loading = true;

            _requestHelper({
                url: Apis.checkCreditsLoyaltyProgram,
                method: "POST",
                data: {
                    id: null
                },
                loaderKey: "",
                successCallback: (loyaltyInfo: any) => {
                    this.loyaltyInfo = loyaltyInfo;
                    var fac = Math.pow(10, loyaltyInfo.numberOfVisibleDecimals)
                    this.loyaltyInfo.creditsInCash = Math.floor((loyaltyInfo.creditsInCash * fac)) / fac;
                }
            })
            .catch(() => {
                this.loading = false;
            })
            .finally(() => {
                this.loading = false;
            })
        },

        detachLoyalty() {
            this.loading = true;

            _requestHelper({
                url: Apis.detachLoyaltyProgram,
                method: "POST",                
                loaderKey: "",
                successCallback: () => {
                    this.getLoyaltyInfo();
                    this.loading = false;
                }
            })
            .catch(() => {
                this.loading = false;
            })
            .finally(() => {
                this.loading = false;
            })
        },

        requestNewLoyalty() {
            this.attachLoyalty(true)
        },

        attachLoyalty(isRequestingNewOne: boolean = false) {
          
                if(isRequestingNewOne == false && (this.loyaltyCardNumberModel.trim().length == 0 && this.loyaltyCardPhoneNumberModel.trim().length== 0)
                    || ((this.loyaltyCardNumberModel.trim().length > 0 && this.loyaltyCardNumberModel.trim().length < 2 ) 
                        || this.loyaltyCardPhoneNumberModel.trim().length > 0 && this.loyaltyCardPhoneNumberModel.trim().length < 2)
                ) {
                    this.$store.commit("ui/snackbar", {
                        show: true,
                        message: "Your loyalty card number or phone number must be at least 2 characters",
                        error: true
                    })
                }                
            

            else {
                this.loading = true;

                _requestHelper({
                    url: Apis.syncLoyaltyProgram,
                    data: {
                        customerPartnerLoyaltyId: isRequestingNewOne == true ? null : this.loyaltyCardNumberModel,
                        customerLoyaltyPhoneNumber: isRequestingNewOne == true ? null : this.loyaltyCardPhoneNumberModel,
                    },
                    method: "POST",                
                    loaderKey: "",
                    successCallback: () => {
                        this.getLoyaltyInfo();
                        this.loading = false;
                        this.loyaltyFormDialog = false;

                    }
                })
                .catch(() => {
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                })
        
            }

        },
    },

    mounted() {
        this.getLoyaltyInfo();
    }

})
