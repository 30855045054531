<template>
     <v-card  :heiht="height" style="width:100%; z-index:1; border-radius:20px!important" flat :disabled="disabled" >    
        <GmapMap
            ref="googleMap"
            :center="centerToDisplay"
            @center_changed="(v) => onCenterChange(v)"
            :zoom="zoom"
            map-type-id="roadmap"
            style="width: 100%;"
            :style="`height: ${height ? height : '48vh'}`"
            :options="{
                zoomControl: isMobileViewDisplayed == false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: true,
                isFractionalZoomEnabled: true,
                gestureHandling: 'greedy',
                styles: mapStyle
            }"
        >
        
            <template v-slot:visible v-if="isMapInitialized == true" >                    
                <GmapMarker                
                    :icon="userMarker"
                    :key="index"
                    v-for="(m, index) in userMarkers"
                    :position="m"
                    :clickable="false"
                    :draggable="false"                 
                />


                <GmapMarker                
                    :icon="getStoreMarker(store.marker)"
                    :key="index + 1000"
                    v-for="(store, index) in storeMarkers"
                    :position="{lat: store.lat, lng: store.lng}"
                    :clickable="isStoreMarkerClickable == true"
                    :draggable="false"     
                    @click="onStoreMarkerClicked(store.id)"            
                /> 

                <!-- map polyline -->
               <gmap-polyline v-if="polyline" :path="polyline" :strokeWeight="3" />

              <!-- map marker -->
              <div v-if="onlyForMarkerDisplay == false">
                  <div class="pulsating-circle"> </div>
              </div> 
            </template>
        </GmapMap>


     </v-card>
</template>

<script lang="js">
import Vue from 'vue'
import GmapVue, { getGoogleMapsAPI } from 'gmap-vue';
import  CustomGoogleAutocomplete  from 'vue-custom-google-autocomplete'
import {  mapGetters, mapState } from "vuex";
import GooglePlacesAutocomplete from "@/components/Location/GooglePlacesAutocomplete.vue"
import GoogleMapStyle from "./GoogleMapStyle"

Vue.use(CustomGoogleAutocomplete)
Vue.use(GmapVue, {
  load: {
    // [REQUIRED] This is the unique required value by Google Maps API
    key: 'AIzaSyC38-kEWYa8fFbgAlTS6wL2zF2hz-kumK0',
    // [OPTIONAL] This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    libraries: 'places',

    // [OPTIONAL] If you want to set the version, you can do so:
    // v: '3.26',

    // This option was added on v3.0.0 but will be removed in the next major release.
    // If you already have an script tag that loads Google Maps API and you want to use it set you callback
    // here and our callback `GoogleMapsCallback` will execute your custom callback at the end; it must attached
    // to the `window` object, is the only requirement.
    customCallback: 'MyCustomCallback',
  },

  // [OPTIONAL] If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  autoBindAllEvents: false,

  // [OPTIONAL] If you want to manually install components, e.g.
  // import {GmapMarker} from 'gmap-vue/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then set installComponents to 'false'.
  // If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,

  // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
  dynamicLoad: false,
})


export default Vue.extend({
    components:{ "auto-complete-location-search": GooglePlacesAutocomplete},
    
    props: ["height", "center", "disabled", "onlyForMarkerDisplay", "userMarkers", "storeMarkers", "polyline", "isAutocompleteSearchActivated", "hideCancelButton", "zoomToUse", "isStoreMarkerClickable", "updateMapDisplayView"],

    data: () => ({
        centerToDisplay: {lat: 0, lng: 0},
        place: {},
        zoom: 16,
        isMapInitialized: false,

        options : {
          apiKey: "AIzaSyBqhIbIxRVxOISlUaiwt4bUa9pLkt9eCTU",
          deepSearch: true,
          cors: false,
          params: {},
          focus: false
        },

        selected: null,

        mapStyle: GoogleMapStyle,

        autocomplete: null,
        search: "",
        listOfPredictions: [],
        debounce: null
    }),

    computed: {
        ...mapGetters("auth", ["currentSelectedUser"]),
        ...mapState("auth", ["isFirstTimeOpened"]),
        ...mapState("partner", ["appDataContent", "partnerSettings"]),
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),
        
        google: getGoogleMapsAPI,

        userMarker() {
          var image = {
            url: "https://suppystorage.blob.core.windows.net/valeur/sa/user_maps_marker.png",
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(35, 35)
          };

          return image
        },

        storeMarker() {
          var image = {
            url: this.selectedStoreDetails.storeMapMarkerImgUrl,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(35, 35)
          };

          return image
        },

        centerToUse: {
            get(){
                return this.center;
            },

            set(value) {
                this.$emit("changed", value);
            }
        },
    },
    
    model: {
        prop: "center",
        event: "changed",
    },

    methods: {

        getStoreMarker(markerUrl) {
          var image = {
            url: markerUrl,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(35, 35)
          };

          return image
        },

        onCenterChange(v) {
          clearTimeout(this.debounce);
          if(this.onlyForMarkerDisplay == false) {
              this.centerToUse = {
                  lat: v.lat(),
                  lng: v.lng()
              };
              
              this.debounce = setTimeout(() => {
                  this.centerToDisplay = {
                    lat: v.lat(),
                    lng: v.lng()
                  };
              }, 300)
          }
        },

        onStoreMarkerClicked(storeId) {
          this.$emit("onStoreMarkerClicked", storeId)
        }
    },

    watch: {
      centerToUse(value) {
        if(this.updateMapDisplayView == true) {
          this.centerToDisplay.lat =  parseFloat(value.lat);
          this.centerToDisplay.lng = parseFloat(value.lng);

          this.$emit('disableUpdateMapDisplayView')
        }
      }
    },

    async mounted() {  
      if(this.isFirstTimeOpened == true) {
        this.centerToDisplay.lat = parseFloat(this.partnerSettings.defaultUserLocationCoordinates.latitude);
        this.centerToDisplay.lng = parseFloat(this.partnerSettings.defaultUserLocationCoordinates.longitude);

        this.centerToUse.lat = parseFloat(this.partnerSettings.defaultUserLocationCoordinates.latitude);
        this.centerToUse.lng = parseFloat(this.partnerSettings.defaultUserLocationCoordinates.longitude);
      }
      
      await this.$gmapApiPromiseLazy().then(() => {
        this.isMapInitialized = !!window.google;

        this.$emit("mapInit", this.$refs.googleMap)

        if(this.currentSelectedUser.usedAddress && this.currentSelectedUser.usedAddress.position && this.currentSelectedUser.usedAddress.position.latitude && this.currentSelectedUser.usedAddress.position.longitude  && this.onlyForMarkerDisplay == false) {
          this.centerToDisplay.lat = this.currentSelectedUser.usedAddress.position.latitude ? parseFloat(this.currentSelectedUser.usedAddress.position.latitude) : 0;
          this.centerToDisplay.lng = this.currentSelectedUser.usedAddress.position.longitude ? parseFloat(this.currentSelectedUser.usedAddress.position.longitude) : 0;
          this.centerToUse.lat = this.currentSelectedUser.usedAddress.position.latitude ? parseFloat(this.currentSelectedUser.usedAddress.position.latitude) : 0;
          this.centerToUse.lng = this.currentSelectedUser.usedAddress.position.longitude ? parseFloat(this.currentSelectedUser.usedAddress.position.longitude) : 0;
        }

        this.centerToDisplay = this.centerToUse;

        if(this.zoomToUse) {
          this.zoom = this.zoomToUse;
        }
  
        this.completeAddress = this.currentSelectedUser.usedAddress?.additionalAddressInfo            
      })
   
    },
})
</script>


<style scoped>
.location-close-btn {
  filter: drop-shadow(0px 5px 10px black);
}

.pulsating-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 25px;
  height: 25px;
}


.pulsating-circle:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-inline-start: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #000000;
  -webkit-animation: pulse-ring 2.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
          animation: pulse-ring 2.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}


.pulsating-circle:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: black;
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  -webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
          animation: pulse-dot 1.0s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}


@-webkit-keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}
 
@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}
@-webkit-keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.autocomplete-field {
    font-size: 30px !important;
    border-radius: 5px;
    border: 1px solid black;
    margin: 20px;
    width: 100%
}

.pac-target-input{ 
    width: 100%;
}



</style>

<style lang="scss">
.pac-container {
  width: 15% !important;
}


.autocomplete-overlay {
  border-radius: 0% !important;

  .v-overlay__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px;
  }  
}
  
  
</style>