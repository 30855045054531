import store from "@/store";
import StoreService from "./storeService";
import { PartnerSettingsState } from "@/store/modules/partnerModule";
import { EPartnerStoresStructureType } from "@/enums/EPartnerStoresStructureType";
import { StoreState } from "@/store/modules/storeModule";
import OrderType from "@/models/OrderType";
import routerManagerNew from "@/routerManagerNew/routerManager";
import Store from "@/models/Store";

export default class OrderTypeService {        
    
    /** Set order type
     * /!\ if order type is not passed => set default one
     */
    public static SetOrderType(orderType?: number, navigateRoutes: boolean = true) {   
        var partnerSettings = ((store as any).state["partner"] as PartnerSettingsState).partnerSettings;
        var agnosticDeliveryStore = ((store as any).state["store"] as StoreState).agnosticDeliveryStoreId;
        var selectedStore = ((store as any).state["store"] as StoreState).selectedStoreDetails;
        var orderTypeToSet: number = OrderType.unknowOrderTypeInt;

        if(orderType == null || orderType == 0) {
            if(StoreService.isAnyElligbleStoreAvailable() == false) { 
                orderTypeToSet = OrderType.unknowOrderTypeInt; // unknown
            }

            else {
                var storeIdToCheck: number = partnerSettings.partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel                        
                    ? agnosticDeliveryStore
                    : selectedStore.id;

                if(StoreService.isStoreAvailableForDelivery(storeIdToCheck, true)) {                    
                    orderTypeToSet = OrderType.deliveryOrderTypeInt; // delivery
                }

                else if (StoreService.isStoreAvailableForPickup(storeIdToCheck)) {
                    orderTypeToSet = OrderType.pickupOrderTypeInt; // pickup
                }
            }
        }

        else {
            orderTypeToSet = orderType;
        }

        store.dispatch("schedule/getStoreHomePageFirstAvailableSlots")


        store.commit("auth/SET_ORDER_TYPE", orderTypeToSet);
        
        if(navigateRoutes == true) {

            if(orderTypeToSet == OrderType.pickupOrderTypeInt) {
                if(partnerSettings.partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel) {
                    routerManagerNew.goToStoresPage();
                }                
            }

            else if(orderTypeToSet == OrderType.deliveryOrderTypeInt) {
                if(partnerSettings.partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel) {     
                    routerManagerNew.goToStoreHomePage({
                        id: selectedStore.id,
                        displayName: selectedStore.displayName
                    } as Store);
                }
            }            
        }
    }

}
