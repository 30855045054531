
import Vue from 'vue'
import ItemDetails from "@/components/Items/ItemDetails.vue"
import CategoriesPanel from "@/components/Drawers/MainNavigationDrawer.vue"
import ProductsPage from "@/views/v1/ProductsPages/ProductsPage.vue"
import { mapGetters, mapState } from 'vuex'
import CartBottomCard from '@/components/Cart/CartBottomCard.vue'
import Cart from '@/components/Cart/Cart.vue';
import InitLoader from '@/components/UI/Loader/InitLoader.vue'
import WsaButton from '@/components/UI/Button/wsa-button.vue'

export default Vue.extend({
    components: {
        "item-details": ItemDetails,
        "categories-panel": CategoriesPanel,
        "products-page": ProductsPage,
        "cart-bottom-card": CartBottomCard,
        "cart": Cart,
        "init-loader": InitLoader,
        "wsa-button":WsaButton
    },

    data: () => ({
        cartDrawer: false as boolean,
        fab: false as boolean,
        isLoading: false as boolean,
        showToast: false,
        downloadAppDialog: true as boolean
    }),

    computed: {
        ...mapState("partner", ["partnerSettings"]),
        ...mapState("product", ["itemDetailsDialogValue"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapGetters("cart", ["currentSelectedCartTotalQuantity"]),

        itemDetailsDialog: {
            get(): boolean {
                return this.itemDetailsDialogValue;
            },
            set(value: boolean) {
                this.$store.commit("product/UPDATE_ITEM_DETAILS_DIALOG", value)
            }
        },

        isRouteForItemDetails(): boolean {
            return this.$route.name == "productDetailsPage"
        },

        productDetailsPageId(): number {
            if(this.isRouteForItemDetails == true) {
                return this.$route.params["productId"] as number | any;
            }

            return 0;
        },


    },

    methods: {
        updateLoadingState(val: boolean){
            this.isLoading = val;
        },

        closeDialog() {
            this.itemDetailsDialog = false;
            this.$router.go(-1);
        },

        toTop() {
            this.$vuetify.goTo("#app-bar");
        },

        onScroll(e: any) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 60;
        },

        goToBitlyUrl() {
            var a = document.createElement("a");
            a.href = this.partnerSettings.appShortLink;
            a.target = "_blank";
            a.click();
            a.remove()
        },

        isWelcomeToastDisplayed() {
            this.showToast = typeof this.$route.params["showWelcomeToast"] != "undefined" &&  this.$route.params["showWelcomeToast"] == "true" 
        }
    },

    created() {
        this.downloadAppDialog = this.isMobileViewDisplayed && typeof this.partnerSettings.appShortLink != "undefined" && this.partnerSettings.appShortLink.length > 0;
        this.isWelcomeToastDisplayed();
    }

})

