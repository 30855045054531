import { RouteConfig } from "vue-router";
import Routes from "./routes";

export interface MenuItem {
  title: string;
  route?: RouteConfig | string | undefined;
}

export interface MetaParams {
  isAPartnerPage: Boolean; // used to detect if colors of a page should be taken from the partner or store settings
}
interface MenuSection {
  name: string;
  path: string;
  meta: MetaParams,
  redirect?: string
  component?: string;
}

interface Menu {
  menuList: MenuItem[];
}

var menu : MenuSection[] = [
  // {name: "Location-home", redirect: "Location", path:"/",  meta: {isAPartnerPage: true}, component: "Authentication/Login.vue" },

  {name: Routes.index.name, redirect: Routes.storeHomePage.name, path: Routes.index.path, meta: {isAPartnerPage: true} },
  {name: Routes.loginOrGuest.name, redirect: Routes.loginOrGuest.name, path: Routes.loginOrGuest.path, meta: {isAPartnerPage: true}, component: "LoginOrGuestPage.vue" },

  // Authentication routes
  {name: Routes.login.name, path: Routes.login.path, meta: {isAPartnerPage: true} },
  {name: Routes.register.name, path: Routes.register.path, meta: {isAPartnerPage: true}, component: "Authentication/Register.vue" },
  {name: Routes.forgotPassword.name, path: Routes.forgotPassword.path, meta: {isAPartnerPage: true}, component: "Authentication/ForgotPassword.vue" },
  {name: Routes.profile.name, path: Routes.profile.path, meta: {isAPartnerPage: true}, component: "Profile/Profile.vue" },
  {name: Routes.location.name, path: Routes.location.path, meta: {isAPartnerPage: true}, component: "Location/LocationPage.vue" },

  // Contact us routes
  {name: Routes.contactUs.name, path: Routes.contactUs.path, meta: {isAPartnerPage: true}, component: "Authentication/ContactUs.vue" },

  // Location map routes
  // {name: "Location", path:"location", meta: {isAPartnerPage: true}, component: "Location/Loc.vue" },

  // List of store routes
  {name: Routes.stores.name, path: Routes.stores.path, meta: {isAPartnerPage: true}, component: "Stores/Stores.vue" },

  // orders
  {name: Routes.orders.name, path: Routes.orders.path, meta: {isAPartnerPage: true}, component: "Orders/OrdersPage.vue" },

  // wallet
  {name: Routes.wallet.name, path: Routes.wallet.path, meta: {isAPartnerPage: true}, component: "Profile/Wallet.vue" },

  // Loyalty Program
  {name: Routes.loyaltyProgram.name, path: Routes.loyaltyProgram.path, meta: {isAPartnerPage: true}, component: "Profile/LoyaltyProgram.vue" },

] 

function generateMenu(): MenuItem[] {
 return menu.map((m) => {
    var route: MenuItem = {
      title: m.name,      
      route: {
        path: `/${m.path}`,        
        name: m.name,
        meta: m.meta,
        props: true
      }
    }

    if(m.component) {
      (route.route as any).component = () => import( /* webpackChunkName: "[request]" */ `@/views/${m.component}` );
    }

    if(m.redirect)
      (route.route as RouteConfig).redirect = m.redirect;

    return route;
  })
}

export default <Menu>{
  menuList: generateMenu(),
};
