import { EPartnerStoresStructureType } from "@/enums/EPartnerStoresStructureType";
import AddressPosition from "@/models/AddressPosition";
import { ETypeOfBranchServices } from "@/models/ETypeOfBranchServices";
import OrderType from "@/models/OrderType";
import User from "@/models/User";
import routerManager from "@/routerManager";
import routerManagerNew from "@/routerManagerNew/routerManager";
import Routes from "@/routerManager/routes";
import OrderTypeService from "@/services/orderTypeService";
import StoreService from "@/services/storeService";
import store from "@/store";
import { AuthenticationState } from "@/store/modules/authenticationModule";
import { MapState } from "@/store/modules/mapModule";
import { PartnerSettingsState } from "@/store/modules/partnerModule";
import { StoreState } from "@/store/modules/storeModule";
import Store from "@/models/Store";

export default class FlowFunctions {    
    /**
     * get default address from partner settings `defaultUserLocationCoordinates`
     * 
     * @defaultValue `AddressPostion` | `null` 
     */
    public static async getAddressFromPartnerDefaultLocation(): Promise<AddressPosition | null>
    {
        var partnerSettings = ((store as any).state["partner"] as PartnerSettingsState).partnerSettings;
        var currentUserObject: User = ((store as any).state["auth"] as AuthenticationState).listOfUsers[((store as any).state["auth"] as AuthenticationState).currentUser];

        if(typeof partnerSettings.defaultUserLocationCoordinates != "undefined" 
            && typeof partnerSettings.defaultUserLocationCoordinates.latitude != undefined
        ) 
        {
            await store.dispatch("maps/getAddress", {
                latitude: partnerSettings.defaultUserLocationCoordinates.latitude,
                longitude: partnerSettings.defaultUserLocationCoordinates.longitude
            })
            .then(() => {
                var addressFromCoords = ((store as any).state["maps"] as MapState).address;
                
                store.commit("auth/ADD_LOCAL_ADDRESS", {
                    isDefault: true,
                    addressInfo: addressFromCoords,
                    addressPosition: {
                        lat: partnerSettings.defaultUserLocationCoordinates.latitude,
                        lng: partnerSettings.defaultUserLocationCoordinates.longitude
                    }
                });
            })
        }

        return currentUserObject.usedAddress;
    }

    /**
     * if user is logged in -> get address list from the server and take the primary address
     * 
     * if user is a guest -> check if there is any address on the local storage
     * 
     * @returns address
     * @defaultValue `AddressPostion` | `null`
    */
    public static async retrievePrimaryAddress(): Promise<AddressPosition | null>
    {
        var currentUser = ((store as any).state["auth"] as AuthenticationState).currentUser;
        var currentUserObject: User = ((store as any).state["auth"] as AuthenticationState).listOfUsers[((store as any).state["auth"] as AuthenticationState).currentUser];
        
        var primaryAddress = null;
        
        // if user is a guest
        if(currentUser === 'guest') 
        {
            if (typeof currentUserObject.usedAddress != 'undefined' && currentUserObject.usedAddress.city != null) 
            {
                primaryAddress = currentUserObject.usedAddress;
            }
        }

        // if user is logged in
        else 
        {
            // get user addresses from server
            await store.dispatch("address/getUserAddresses")
            .then(() => {
                if(currentUserObject.deliveryAddressesFromServer.length > 0 
                    && typeof currentUserObject.usedAddress != "undefined" 
                    && currentUserObject.usedAddress.isPrimary == true
                )
                {
                    primaryAddress = currentUserObject.usedAddress;
                }
            })
        }

        if( primaryAddress == null ) 
        {
            primaryAddress = await this.getAddressFromPartnerDefaultLocation()
        }

        return primaryAddress;    
    }


    
    public static async afterLoginFlow(routeName: string | null | undefined) {
        var partnerSettings = ((store as any).state["partner"] as PartnerSettingsState).partnerSettings;

        // get list of eligible stores
        await StoreService.getElligibleStores(ETypeOfBranchServices.All);
        var listOfStores = ((store as any).state["store"] as StoreState).listOfStores;
        var selectedStore = ((store as any).state["store"] as StoreState).selectedStoreDetails;

        // if there is not any elligible store, go to stores page Empty view
        if(listOfStores.length == 0) {
            OrderTypeService.SetOrderType(OrderType.unknowOrderTypeInt , false);
            routerManager.goToStoresPage(true);

            return;
        }

        else {
            var storeFound = listOfStores.find(store => store.id == selectedStore.id);
            
            // if store not found
            // go to store home page of the first delivery store or to stores page depending on partner store structure type
            if(storeFound == null) {

                // Partner store structure type: Single Store
                // go to stores page, empty view
                // order type = unknown
                if(partnerSettings.partnerStoresStructureType == EPartnerStoresStructureType.SingleStoreModel) {
                    routerManager.goToStoresPage(true);
                    OrderTypeService.SetOrderType(OrderType.unknowOrderTypeInt);
                }

                // Partner store structure type: Multi Store
                // go to stores page
                // order type = unknown
                else if(partnerSettings.partnerStoresStructureType == EPartnerStoresStructureType.MultiStoreSelectionModel) {
                    routerManager.goToStoresPage();
                    OrderTypeService.SetOrderType(OrderType.unknowOrderTypeInt);
                }

                // Partner store structure type: Agnostic Store
                // Check if there is any available delivery store, if yes go to store home page
                // if not, go to agnostic stores page
                else if(partnerSettings.partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel) {

                    // check if there any delivery store
                    var deliveryStores = listOfStores.filter(store => StoreService.isStoreAvailableForDelivery(store.id, false));
                    if(deliveryStores.length > 0) {

                        // set the first delivery store as selected store
                        await StoreService.getStoreDetails(deliveryStores[0].id);
                        
                        // update selected store
                        selectedStore = ((store as any).state["store"] as StoreState).selectedStoreDetails;

                        // save the first delivery store as the agnostic delivery store to use it after
                        store.commit("store/SAVE_AGNOSTIC_DELIVERY_STORE", selectedStore);

                        routerManager.goToStoreHomePage(true);

                        if(routeName == Routes.storeHomePage.name) {
                            await StoreService.getStoreHomePageData();
                        }
                    }

                    // if there is only pickup stores
                    // go to agnotic stores page
                    else {
                        routerManager.goToStoresPage();
                    }

                }
                
            }

            // if the store was found, stay in the same store, same order type and update the store home page data
            else {
                StoreService.getStoreHomePageData();
            }
        }

    }
}