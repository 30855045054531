
import Vue from 'vue'
import WsaButton from '@/components/UI/Button/wsa-button.vue'
import CloseIcon from "@/components/UI/Icons/CloseIcon.vue";
import LocationMapCard from "@/components/Map/LocationCard.vue";
import { mapState } from 'vuex';

export default Vue.extend({
    props: ["eligibilityCheck"],

    components: {
        "wsa-button": WsaButton,
        "close-icon": CloseIcon,
        "location-map-card": LocationMapCard,
    },

    data: () => ({
        addressDialogState: false as boolean,
    }),

    computed: {
        ...mapState("ui", ["isMobileViewDisplayed"]),

        eligibilityCheckModel: {
            get(): boolean {
                return this.eligibilityCheck;
            },

            set(value: boolean) {
                this.$emit("changed", value)
            }
        }
    },

    model: {
        event: "changed",
        prop: "eligibilityCheck"
    },

    methods: {        
        changeAddress() {
            this.eligibilityCheckModel = false;
            this.addressDialogState = true;            
        },
    }
})
