
import Vue from 'vue'
import UITitle from "@/components/UI/Text/UITitle.vue"
import {  mapState } from 'vuex';
import { EStoreType } from '@/enums/EStoreType';
import _requestHelper from '@/apiManager/_requestHelper';
import ItemsSliderWithTitle from '@/components/Items/ItemsSliderWithTitle.vue';
import Breadcrumbds from '@/components/UI/Breadcrumbs.vue';

export default Vue.extend({
    props: {
        departmentId: {
            required: true
        },
        departmentName: {
            required: true
        },
    },

    components: {
        "ui-title": UITitle,
        ItemsSliderWithTitle,    
        "breadcrumbds": Breadcrumbds,    
    },

    data : () => ({
        loading: false as boolean,
        listOfSubcategories: [] as any[]
    }),

    computed: {
        ...mapState("store", ["selectedStoreDetails"])
    },

    methods: {

        async getCategoriesList() {
            this.$emit("loadingState", true);
            this.loading = true;
            
            await _requestHelper({
                method: "post",
                url: "items/categories-page",
                loaderKey: "",
                data: {
                    branchId: (this as any).selectedStoreDetails.id,
                    isLight: (this as any).selectedStoreDetails.storeType == EStoreType.Convenient,
                    departmentId: this.departmentId
                },

                successCallback: (listOfSubcategories: any) => {
                    this.listOfSubcategories = listOfSubcategories.categories;
                }
            });

            this.loading = false;
            this.$emit("loadingState", false);
        },
    },

    watch: {
        async departmentId() {
            await this.getCategoriesList();
        }
    },

    async mounted() {
        await this.getCategoriesList();
    }


})
