
import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({

  props: ["isPartnerLogoDisplayed"],

  computed: {
      ...mapState("partner", ["partnerSettings", "AppDataContent"])
  }
})
