
import { Apis } from '@/apiManager/Apis';
import _requestHelper from '@/apiManager/_requestHelper';
import Product from '@/models/Product';
import ItemType from './ItemType.vue';
import MoneyFormat from "@/components/UI/Money/UIMoneyFormat.vue";
import ItemUnitMeasure from './ItemComponents/ItemUnitMeasure.vue';
import ItemsSliderWithTitle from './ItemsSliderWithTitle.vue';
import CartActions from "@/components/Cart/CartActions/CartActions.vue";
import Vue from 'vue'
import { mapState } from 'vuex'
import Breadcrumbs from '../UI/Breadcrumbs.vue';
import InitLoader from '../UI/Loader/InitLoader.vue';
import EmptyView from '../Cart/EmptyCart.vue';
import UITitle from '../UI/Text/UITitle.vue';
import AppDataContent from '@/models/AppDataContent';
export default Vue.extend({

    components: {
        "item-type": ItemType,
        "money-format": MoneyFormat,
        "item-unit-measure": ItemUnitMeasure, 
        "items-slider-with-title": ItemsSliderWithTitle,
        "cart-actions": CartActions,
        "breadcrumbs": Breadcrumbs,
        "init-loader": InitLoader,
        "empty-view": EmptyView,
        "ui-title": UITitle
    },

    data: () => ({
        loading: false as boolean,
        itemInformation: [] as any,
        similarItems: [] as any[],
        alsoBoughtWithItems: [] as any[],
        itemsByBrand: [] as any[],
        selectedItem: {} as Product,
        selectedImage: "" as string,
        hiResImageFailedToLoad: false as boolean,
        lowResImageFailedToLoad: false as boolean,
        multiImageFailedToLoad: [] as boolean[],
        isEmptyViewDisplayed: false as boolean,
    }),
    
    computed: {
        ...mapState("ui", ["isMobileViewDisplayed"]),
        ...mapState("partner", ["partnerSettings", "appDataContent"]),
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("product", ["currentSelectedItem"]),

        itemIdFromRoute(): any {
            return this.$route.params["productId"];
        }
    },


    methods: {

        async getGenericMoreItems() {
            this.$emit("loadingState", true);

            // this.selectedItem = {} as Product;
            this.itemInformation = [] as any;
            this.similarItems = [] as any[];
            this.alsoBoughtWithItems = [] as any[];

            this.loading = true;

            await _requestHelper({
                method: "POST",
                url: `${Apis.itemsSearchURL}/${typeof this.$route.params["productId"] == "undefined" ? this.currentSelectedItem.id : this.$route.params["productId"]}`,
                data: { 
                    id: Number.parseInt(typeof this.$route.params["productId"] == "undefined" ? this.currentSelectedItem.id : this.$route.params["productId"]),
                    includeItemDetails: true,
                    includeItemAttributes: true,
                    includeSimilarItemsByBrand: true,
                    includeSimilarItemsByType: true,
                    includeSimilarItemsAlsoBoughtWith: true,
                    branchId: this.selectedStoreDetails.id
                },
                loaderKey: "",
                successCallback: (result: any) => {

                    this.selectedItem = Product.fetchItemObjectFromJson(result.itemDetails);

                    if(typeof (this as any).selectedItem != "undefined") {                        
                        this.selectedImage = (this as any).selectedItem.images[0];
                        this.multiImageFailedToLoad = (this as any).selectedItem.images.map( (_: any) => false)
                    }

                    else if(typeof this.currentSelectedItem != "undefined") {
                        this.selectedImage = (this as any).currentSelectedItem.images[0];
                        this.multiImageFailedToLoad = (this as any).selectedItem.images.map( (_: any) => false)
                    }

                    // similar items
                    if(result.similarItemsByType)
                        this.similarItems = result.similarItemsByType.map((item: any) => Product.fetchItemObjectFromJson(item));
                    
                    // bought With
                    if(result.alsoBoughtWithItems)
                        this.alsoBoughtWithItems = result.alsoBoughtWithItems.map((item: any) => Product.fetchItemObjectFromJson(item));
            
                    // brand
                    if(result.similarItemsByBrand)
                        this.itemsByBrand = result.similarItemsByBrand.map((item: any) => Product.fetchItemObjectFromJson(item));

                    // item information
                    if(result.itemInformation)
                        this.itemInformation = result.itemInformation;

                        this.$store.commit("product/UPDATE_PRODUCT_DETAILS_LOADING_STATE", false);
                }
            }).catch(() => {
                this.isEmptyViewDisplayed = true
            });
            
            this.loading = false;
            this.$emit("loadingState", false);
        }
    },

    async mounted() {
        await this.getGenericMoreItems()
     
    },
    
    watch: {
        async "currentSelectedItem.id" () {
            // this.scrollOffset = 0;
            this.hiResImageFailedToLoad = false;
            this.lowResImageFailedToLoad = false;
            await this.getGenericMoreItems();
        },

        async itemIdFromRoute (val) {
            this.hiResImageFailedToLoad = false;
            this.lowResImageFailedToLoad = false;
            await this.getGenericMoreItems();
        },
        
        selectedImage() {
            this.hiResImageFailedToLoad = false;
            this.lowResImageFailedToLoad = false;
        }
    },
    
})
