
import Vue from "vue";
import UIPopup from "@/components/UI/Popup/UIPopup.vue";
import UIMoneyFormat from "@/components/UI/Money/UIMoneyFormat.vue";
import Receipt from "@/components/Orders/Receipt.vue";
import HeaderBar from "@/components/UI/HeaderBar/UIHeaderBar.vue";
import UITitle from "@/components/UI/Text/UITitle.vue";
import UISubtutitle from "@/components/UI/Text/UISubtitle.vue";
import { ImageSettings } from "@/Settings/Settings";
import { mapActions, mapMutations, mapState } from "vuex";
import OrderStatus from "@/models/OrderStatus";
import FirebaseAnalyticsScreensNames from "@/firebase/firebaseAnalyticsScreensNames";
import GoogleMap from "@/components/Map/GoogleMap.vue"
import { EPartnerStoresStructureType } from "@/enums/EPartnerStoresStructureType";
import PartnerSettings from "@/models/PartnerSettings";
import OrderType from "@/models/OrderType";
import Order from "@/models/Order";
import PaymentMethod from "@/models/PaymentMethod";
import InitLoader from "@/components/UI/Loader/InitLoader.vue";
import TrackingMap from "@/components/Map/TrackingMap.vue";
import { initializeApp } from "firebase/app";
import { collection, getFirestore, onSnapshot } from "firebase/firestore";
import moment from "moment";

var config = {
    apiKey: "AIzaSyCJ88la-kfuseDjrvRmE6ekB5ZoEIhnXy8",
    authDomain: "ma-osp-ordermng.firebaseapp.com",
    databaseURL: "https://ma-osp-ordermng.firebaseio.com",
    projectId: "ma-osp-ordermng",
    storageBucket: "ma-osp-ordermng.appspot.com",
    messagingSenderId: "234275447504",
    appId: "1:234275447504:web:46b0170545f72f4d8734d5",
    measurementId: "G-GF5QLTDPN8",
};

const firebaseApp = initializeApp(config);
let db = getFirestore(firebaseApp);


export default Vue.extend({
  components: {
    "ui-popup": UIPopup,
    "ui-money-format": UIMoneyFormat,
    receipt: Receipt,
    "header-bar": HeaderBar,
    "ui-title": UITitle,
    "ui-subtitle": UISubtutitle,
    "google-map": GoogleMap,
    "init-loader": InitLoader,
    "tracking-map": TrackingMap
  },

  props: ["orderId"],

  metaInfo: function() {
    return {
      title: "#" + this.orderId,
    }
  },

  data: () => ({
    dialog: false,
    order: {},
    step: 1,
    expansion: 0,
    itemsImageUrl: ImageSettings.baseItemsUrl,
    orderStatusClass: OrderStatus,
    loading: false as boolean,
    autoReload: 0 as any,
    driverTrackingObject: { lat: 0, lng: 0},
    lastDriverTrackingTimestamp: 0,
    showDefaultMap: false as boolean,
    reloadMap: false as boolean,
  }),

  computed: {
    ...mapState("order", ["singleOrderLoading", "singleOrder"]),
    ...mapState("partner", ["partnerSettings", "appDataContent"]),
    ...mapState("ui", ["isMobileViewDisplayed"]),

    numberOfItemsPreviewToBeDisplayed (): number {
        return (this as any).isMobileViewDisplayed ? 4 : 5
    }
  },

  methods: {
    ...mapActions("order", ["singleOrderById"]),
    ...mapMutations("firebase", ["LOG_FIREBASE_SCREEN"]),
    
    getRoutePolylineAsArray(polylineAsString: string) {
      if(polylineAsString != null) {
        var res = JSON.parse(polylineAsString);
        return res.map((x: any) => {return {lat: x.Latitude, lng: x.Longitude}});
      }
    },

    getIsAgnosticStoreType(): boolean {
      return (this.partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel
    },

    
    getIsDeliveryOrder(orderTypeInt: number): boolean {
      return orderTypeInt == OrderType.deliveryOrderTypeInt
    },

    isTokenDisplayed(): boolean {
      return (this.partnerSettings as PartnerSettings).isDropOffPinEnabled == true
        && (this.singleOrder as Order).status.statusInt != OrderStatus.orderCompletedStatus 
        && (this.singleOrder as Order).status.statusInt != OrderStatus.cancelledOrderStatus
        && (this.singleOrder as Order).status.statusInt != OrderStatus.orderDeliveredStatus
        && (this.singleOrder as Order).status.statusInt != OrderStatus.pickupDoneStatus
        && (this.singleOrder as Order).customerReceptionToken != null
        && (this.singleOrder as Order).paymentMethod.pMethodInt == PaymentMethod.OnlieCardPayment
    },

    async getOrderSummary() {
      await this.singleOrderById({ id: this.orderId, includeAlternatives: true });
      clearInterval(this.autoReload);
      if(this.singleOrder.status.statusInt > -1 && this.singleOrder.status.statusInt <= 70) { 
        this.autoReload = setInterval(async() => {
          this.getOrderSummary()
        }, 30000);
      }
    },


    async updateDriverTracking() {
      this.$forceUpdate();
      // set by default the center of the tracking map in user location
      this.driverTrackingObject = {lat: this.singleOrder.deliveryAdr.position.latitude, lng: this.singleOrder.deliveryAdr.position.longitude}
      
      if(this.partnerSettings.driverTrackingFirestoreCollection != null && this.partnerSettings.driverTrackingFirestoreCollection != ""){          
        const driverLocationcollection = this.partnerSettings.driverTrackingFirestoreCollection;
          var col = collection(db, driverLocationcollection);

          onSnapshot(col, (snapshot: any) => {
            this.$forceUpdate();
            snapshot.docChanges().forEach((change: any) => {
              if (change.type === "modified") {
                this.$forceUpdate();
                if (this.singleOrder.driverTrackingId == change.doc.id) {
                  this.$forceUpdate();
                  if(this.singleOrder.status.statusInt == OrderStatus.inDeliveryOrderStatus) {
                    var data = change.doc.data();
                    this.driverTrackingObject =  {
                      lng: data.longitude,
                      lat: data.latitude                
                    };

                    this.lastDriverTrackingTimestamp = data.timestamp;
                    this.showDefaultMap = false;
                    this.$forceUpdate();
                  }
                }
                else {
                  this.showDefaultMap = true;
                }
              }
              else {
                  this.showDefaultMap = true;
                }
            })
          })
      }

      else {
        this.showDefaultMap = true;
      }

    }
  },

  watch: {
    "singleOrder.status.statusInt"(value: number) {
      if(value == OrderStatus.inDeliveryOrderStatus) {
        this.reloadMap = true;
        this.updateDriverTracking();
        this.showDefaultMap = false;
        this.reloadMap = false;
      }

      else if (value > OrderStatus.inDeliveryOrderStatus) {
        this.showDefaultMap = true;
      }

      this.$forceUpdate();
    }
  },

  async mounted() {
    clearInterval(this.autoReload)
    this.loading = true;
    await this.getOrderSummary();
    this.loading = false;

    // (this.$refs.map as any).mapObject._onResize();
    this.LOG_FIREBASE_SCREEN(FirebaseAnalyticsScreensNames.orderSummaryPage);
    this.updateDriverTracking();
  },

  

  beforeDestroy() {
    clearInterval(this.autoReload)
  }


});
