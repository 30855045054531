import { Module } from "vuex";
import { RootState } from "..";
import _requestHelper from "@/apiManager/_requestHelper";
import { Apis } from "@/apiManager/Apis";

interface LoyaltyState extends RootState {
    loyalty: object
}

export default <Module<LoyaltyState, RootState>>{
    namespaced: true,
    state: {
        loyalty: {
            creditsInCash: 0
        } as any
    },

    mutations: {
        FETCH_LOYALTY(state, loyalty: any) {
            if(typeof loyalty == "undefined")
                state.loyalty = {
                    creditsInCash:0
                };
            
            state.loyalty = loyalty;
            
            if(typeof (state.loyalty as any).creditsInCash != "undefined" &&  (state.loyalty as any).creditsInCash != null) {
                var numberOfVisibleDecimals  = 2;
                var fac = Math.pow(10, numberOfVisibleDecimals);
                (state.loyalty as any).creditsInCash = Math.floor((loyalty.creditsInCash * fac)) / fac;
            }
               
        }
    },

    actions: {
        
        getLoyalty({ commit }, params) {
            _requestHelper({
                method: "POST",
                url: Apis.checkCreditsLoyaltyProgram,
                data: params,
                loaderKey: "",
                successCallback: (loyalty: any) => {
                    commit("FETCH_LOYALTY", loyalty)
                }
            })
        }

    }
}