
import ItemsSliderWithTitle from "@/components/Items/ItemsSliderWithTitle.vue"
import Vue from 'vue'
import { mapState } from "vuex";
import ProductMappingBySubcategoryAndType from "@/models/ProductMappingBySubcategoryAndType";
import Product from "@/models/Product";

export default Vue.extend({
    components: {     
        "item-slider-with-title": ItemsSliderWithTitle,
    },

    props:{
        itemGroupSelected: {
            required: false,
            default: 0,
            type: Number
        },

        isItemsByGroup: {
            required: false,
            default: true,
            type: Boolean
        },

        items: {
            required: false,
            type: Array
        },
        search: {
            required:false
        },
        
        loading: {
            required: false,
            default: true,
            type: Boolean
        } 
    },

    data: () => ({
        listOfItemsToBeDisplayed: [] as any[],
    }),


    computed: {
        ...mapState("product", ["listOfItems"]),

        itemsToManipulate(): any[] {
            if(this.items && this.items.length > 0)
                return this.items as Product[];
            else 
                return (this as any).listOfItems;
        }
    },

    methods: {
        filterItems() {            
            if( this.itemGroupSelected == 0 ) {
                this.listOfItemsToBeDisplayed = (this.itemsToManipulate as ProductMappingBySubcategoryAndType[]);
            }

            else {
                if(this.isItemsByGroup) {
                    this.listOfItemsToBeDisplayed = (this.itemsToManipulate as ProductMappingBySubcategoryAndType[])
                        .filter((item: ProductMappingBySubcategoryAndType) => item.groupId == this.itemGroupSelected);                
                }
                
                else {                    
                    this.listOfItemsToBeDisplayed = (this.itemsToManipulate as Product[])
                        .filter((item: any) => item.itemTypeId == this.itemGroupSelected);
                }

            }

            if(this.listOfItemsToBeDisplayed.length == 0)
                this.listOfItemsToBeDisplayed = (this.itemsToManipulate as ProductMappingBySubcategoryAndType[]);
        }

    },

    watch: {
        itemGroupSelected() {
            this.filterItems();
        },

        loading() {
            this.filterItems();
        },

        $router() {
            this.filterItems();
        },

        search() {
            this.filterItems();
        },
        
    },

    mounted() {
        this.filterItems();
    }
   

})
