import AddressCoordinates from "./AddressCoordinates";
import AddressPosition from "./AddressPosition";
import AddressTag from "./AddressTag";
import OrderType from "./OrderType";
import PaymentMethod from "./PaymentMethod";
import StoreSettings from "./StoreSettings";
import UserGender from "./UserGender";
import UserPersonalInfo from "./UserPersonalInfo";

export default class User {
    personalInfo: UserPersonalInfo = new UserPersonalInfo(); // Includes user personal info
    deliveryAddressesFromServer: Array<AddressPosition> = []; // It provides a 1st, 2nd, 3rd delivery address (each index will have a tag)
    deliveryAddressesFromLocal: Array<AddressPosition> = [];  // It provides a 1st, 2nd, 3rd delivery address (each index will have a tag)
    usedAddress: AddressPosition = {} as AddressPosition;  // The Address to be used
    selectedStore?: StoreSettings;  // the branch selected by the user
    userPreferredPayMethod: PaymentMethod = {} as PaymentMethod;   // the payment method preferred by user to be applied unless not proposed by the store TODO: store it and get it from server
    isUserLoggedIn: boolean = false;    // this logs if the user is logged in to the app or not
    hasSyncedPartnerLoyaltyProgram: boolean = false;    // this logs if the user has a synced a loyalty program
    listOfeligibleOrderTypes: Array<OrderType> = [];    // list of eligible order types for store agnostic only         
    selectedOrderType: OrderType = OrderType.getOrderTypeFromInt(OrderType.unknowOrderTypeInt);    // selected order type for store agnostic only
    bearerToken: string = "";
    preferredOrderType: OrderType = OrderType.getOrderTypeFromInt(OrderType.unknowOrderTypeInt);
    hasPartnerLoyalty: boolean = false; // Loyalty program synced

    // this method is used to fetch json server result into user object
    static fetchUserObjectFromJson(user: any) : User{
        var userObject = {} as User; 
        userObject.personalInfo = {} as UserPersonalInfo;
        userObject.personalInfo.gender = UserGender.noneGender;
        userObject.deliveryAddressesFromServer = [] as AddressPosition[];

        userObject.isUserLoggedIn = true; 
        userObject.bearerToken = user.access_token;
        userObject.hasSyncedPartnerLoyaltyProgram = user.hasPartnerLoyalty;

        userObject.personalInfo.firstName = user.firstName;
        userObject.personalInfo.lastName = user.lastname;
        userObject.personalInfo.email = user.email === null || user.email === "email" ? "" : user.email;
        userObject.personalInfo.phoneNumber = user.phoneNumber;
        userObject.personalInfo.dateOfBirth = user.dateOfBirth;
        userObject.personalInfo.eWalletId = user.eWalletId;

        userObject.personalInfo.gender.genderInt = UserGender.getGenderFromInt(user.gender).genderInt;
        userObject.personalInfo.gender.genderStr = UserGender.getGenderFromInt(user.gender).genderStr;

        userObject.hasPartnerLoyalty = user.hasPartnerLoyalty;

        return userObject;
    }

    static updateUserListOfAddress(list:any) {
        var listOfAddresses: AddressPosition[] = [];
        
        list.forEach((address: any) => {
            var addrFromServer: AddressPosition = {} as AddressPosition;
            addrFromServer.id = address.id;
            addrFromServer.city = address.city;
            addrFromServer.tagDescription = address.tagDescription;
            addrFromServer.tag = AddressTag.getTagFromAddressTagId(address.tag);
            addrFromServer.additionalAddressInfo = address.additionalAddressInformation;
            addrFromServer.area = address.area;
            addrFromServer.country =  address.country;
            addrFromServer.state =  address.state;
            addrFromServer.streetName = address.streetName;
            addrFromServer.countryCode = address.countryCode;
            addrFromServer.state = address.state;
            addrFromServer.postalCode = address.postalCode;
            addrFromServer.isPrimary = address.isPrimary
            
            addrFromServer.position = {} as AddressCoordinates;
            addrFromServer.position.latitude = address.coordinatesLatitude;
            addrFromServer.position.longitude = address.coordinatesLongitude;            

            listOfAddresses.push(addrFromServer);
          });
        
          return listOfAddresses;
    }
}  