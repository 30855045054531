
import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  props: ["title", "loading","fontsize"],

  computed: {
    ...mapState('ui', ['isMobileViewDisplayed'])
  }
});
