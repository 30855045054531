
import Vue from "vue";
import MainDialogCard from "@/components/MainDialogComponents/MainDialogCard.vue";

export default Vue.extend({
  components: {
    MainDialogCard

  },
  
  props: {
    title: {
      required: false,
      type: String,
      default: "",
    },
  },
});
