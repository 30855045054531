import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({ 
  rtl: false, 
  theme: {    
    options: {
      customProperties: true,      
    },
    themes: {
      light: {
        primary: "",
        secondary: "#003399",
        accent: "#ffffff",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        black: "#000",
        lightenBlack: "#424242"
      },
    },
  },
});
