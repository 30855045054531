import { PartnerSettingsState } from "@/store/modules/partnerModule";
import { StoreState } from "@/store/modules/storeModule";
import PartnerSettings from "./PartnerSettings";
import StoreDeliveryZone from "./StoreDeliveryZone";
import StorePickupZone from "./StorePickupZone";
import store from "@/store";
import AddressCoordinates from "./AddressCoordinates";
import AddressPosition from "./AddressPosition";
import { EStoreType } from "@/enums/EStoreType";

export default class Store {
    id: number = 0; // branch Id
    displayName: string = "";   // branch name
    logoUrl: string = "";
    storeStandardLogoUrl: string = "";
    hasDeliveryService: boolean = false;    // delivery service enabled?
    hasPickupService: boolean = false;  // takeaway service enabled?
    isCustomerEligibleForPickup: boolean = false;   // is Customer elligible for pickup
    distanceToShopper: number = 0;  // distance to shopper
    deliveryZone: StoreDeliveryZone = new StoreDeliveryZone(); // delivery zone
    pickupZone: StorePickupZone = new StorePickupZone(); // pickup zone
    address: AddressPosition = {} as AddressPosition;
    isOpened: boolean = false;
    mapMarkerImageUrl: string = "";
    storeType: EStoreType = EStoreType.None;    // store type
    closingOpeningTime!: Date;    // closing and opening hour
    is24h7dService: boolean = false;    // closing and opening hour


    /** fetch server result to store object */
    fetchStoreObjectFromJson(store: any) : Store {        
        var storeObject = {} as Store;
        storeObject.deliveryZone = {} as StoreDeliveryZone;
        storeObject.pickupZone = {} as StorePickupZone;
        storeObject.address = {} as AddressPosition;
        storeObject.address.position = {} as AddressCoordinates;

        storeObject.id = store.id;
        storeObject.displayName = store.displayName;
        storeObject.logoUrl = store.logoUrl;
        storeObject.storeStandardLogoUrl = store.alternatedLogoUrl;
        storeObject.hasDeliveryService = store.hasDeliveryService;
        storeObject.hasPickupService = store.hasTakeawayService;
        storeObject.isCustomerEligibleForPickup = store.isCustomerElligibleForPickup;
        storeObject.pickupZone.id = typeof store.pickupZone != 'undefined' && typeof store.pickupZone.id != 'undefined' ? store.pickupZone.id : 0;
        storeObject.isOpened = store.isOpened;
        storeObject.mapMarkerImageUrl = store.mapMarkerImageUrl;
        storeObject.storeType = store.storeType;
        storeObject.is24h7dService = store.is24h7dService;
        storeObject.closingOpeningTime = new Date(store.closingOpeningTime);
        storeObject.deliveryZone.isCustomerEligibleForDelivery = store.deliveryZone.isCustomerElligibleForDelivery;

        storeObject.address.position.latitude = store.address.coordinatesLatitude;
        storeObject.address.position.longitude = store.address.coordinatesLongitude;
        storeObject.address.city = store.address.city;
        storeObject.address.country = store.address.country;

        if(storeObject .deliveryZone.isCustomerEligibleForDelivery) {
            storeObject.deliveryZone.id = store.deliveryZone.id;
            storeObject.deliveryZone.deliveryFees = store.deliveryZone.deliveryFees;
            storeObject.deliveryZone.deliveryThreshold = store.deliveryZone.deliveryThreshold;
            storeObject.deliveryZone.freeDeliveryThreshold = store.deliveryZone.freeDeliveryThreshold;
            storeObject.deliveryZone.hasDeliveryRestrictions = store.deliveryZone.hasDeliveryRestrictions;
            storeObject.deliveryZone.hasFreeDelivery = store.deliveryZone.hasFreeDelivery;
            storeObject.deliveryZone.isProgrammedDeliveryEnabled = store.deliveryZone.isProgrammedDeliveryEnabled;
        }

        storeObject.distanceToShopper = store.distanceToCustomer;

        return storeObject;
    }

}