
import User from '@/models/User';
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex';
import LocationMapCard from "@/components/Map/LocationCard.vue";
import PartnerSettings from '@/models/PartnerSettings';
import { EPartnerStoresStructureType } from '@/enums/EPartnerStoresStructureType';
import OrderType from '@/models/OrderType';
import routerManager from '@/routerManagerNew/routerManager';

export default Vue.extend({
    
    components: {
        "location-map-card": LocationMapCard,
    },

    data: () => ({
        addressDialogState: false as boolean
    }),

    computed: {
        ...mapGetters("auth", ["currentSelectedUser"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("partner", ["partnerSettings"]),
        ...mapState("auth", ["preferredOrderType"]),

        user(): User {
            return (this as any).currentSelectedUser as User;
        },


        // check if the partner store type struct is agnostic && delivery order type is selected
        // if true, return false              
        isStoreNameShouldBeDisplayed(): boolean {            
            return !(((this as any).partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel 
                && (this as any).preferredOrderType.typeInt == OrderType.deliveryOrderTypeInt);
        }
    },

    methods: {
        onAddressCreation() {
            routerManager.goToStoresPage();            
            this.addressDialogState = false;
        }
    }
})
