
import Vue from 'vue'
import BannersCarousel from './BannersCarousel.vue'
import BannersGrid from './BannersGrid.vue'
import GridElement from './GridElement.vue'
import SliderElement from './SliderElement.vue'

export default Vue.extend({
    props: {
        // defines which element needs to be displayed
        elementId: {
            required: true,
            type: Number,
            default: 1
        },

        // items to be displayed
        items: {
            required: true
        },

        itemType: {
            required: false,
            type: Number,
            default: 1        
        },
        
        // single element width
        elementWidth: {
            required: false,
            type: String,
            default: "0"
        },

        title: {
            required: false,
            type: String
        },

        isShowAllButtonEnabled: {
            required: false,
            default: false,
            type: Boolean
        },

        
        loading: {
            required: false,
            default: false,
            type: Boolean
        }
    },

    components: {
        "grid-element": GridElement,
        "slider-element": SliderElement,
        "banners-carousel": BannersCarousel,
        "banners-grid": BannersGrid

    }
})
