<template>
   <v-card dark flat tile class="ma -0">
       
        <flipbook :key="isRendered" v-if="isRendered" class="flipbook" :pages="images" :zooms=[1,2,4] :startPage="1" v-slot="flipbook" :centering="true">
            

            <div class="d-flex justify-space-between pa-4 mb-15" style="height: 56px">
                <!-- <close-icon :isLight="true" @onClick="$emit('closeDialog')" /> -->
                <v-icon @click="$emit('closeDialog')">mdi-close</v-icon>

                <div class="d-flex align-center justify-center">
                    <v-btn  icon @click="flipbook.flipLeft">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon @click="flipbook.zoomOut">
                        <v-icon>mdi-magnify-minus-outline</v-icon>
                    </v-btn>

                    <p class="d-flex align-center mb-0 mx-2" style="font-size: 12px">
                        Page {{flipbook.page}} of {{ flipbook.numPages}}
                    </p>


                    
                    <v-btn icon @click="flipbook.zoomIn">
                        <v-icon>mdi-magnify-plus-outline</v-icon>
                    </v-btn>

                    <v-btn icon  @click="flipbook.flipRight">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </div>

                <div style="width: 24px height: 28px"  />
                
            </div>
        </flipbook>
    </v-card>
</template>

<script>
import CloseIcon from '@/components/UI/Icons/CloseIcon.vue'
import Flipbook from 'flipbook-vue/dist/vue2/flipbook.mjs'


export default {

    props: ["isOpened", "images"],

    components: {
        Flipbook,
        CloseIcon
    },

    
    data: () => ({
        isRendered: false,


    }),

    computed: {
        imagesToDisplay() {
            return [null, ...this.images]
        }
    },

    methods: {

    },

    mounted() {
        this.$nextTick(() => {
            this.isRendered = true;
            this.$forceUpdate()
        });
    },

    watch: {
        isOpened(value) {
            this.isRendered = false;
            if(value == true) {
                this.isRendered = true;
                // Add the component back in
            }
        }
    }
}
</script>

<style>
.flipbook {
   width: 100vw;
   height: calc(100vh - 200px);
   margin: auto !important;
}
</style>