
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default Vue.extend({

    props: {
        isLight: {
            required: false,
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapState("ui", ["isMobileViewDisplayed"]),

        closeIconUrl(): string {
            return this.isLight 
                ? "https://suppystorage.blob.core.windows.net/valeur/wsa/icons/close-white.svg"
                : "https://suppystorage.blob.core.windows.net/valeur/wsa/icons/close.svg"
        }
    }
    
})
