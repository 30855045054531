
import Vue from 'vue';
import ItemType from "@/components/Items/ItemType.vue";

export default Vue.extend({
    props: ["brand", "elementWidth", "elementHeight"],

    components: {
      "item-type": ItemType
    }
})
