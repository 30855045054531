import store from "@/store";
import { PartnerSettingsState } from "@/store/modules/partnerModule";

export default class OrderStatus {
    statusInt: number = 0;
    displayStatus: string = "";
    messageToShowInOrderStatusPage: string = ""; 
    icon: string = "";
    color: string = "";

    static cancelledOrderStatus: number = -1;
    static scheduledOrderStatus: number = 5;
    static newOrderStatus: number = 10;
    static ongoingOrderStatus: number = 20;
    static awaitingForAlternativeStatus: number = 30;
    static alternativesSelectedStatus: number = 40;
    static alternativesTimeoutStatus: number = 50;
    static collectionCompletedStatus: number = 55;
    static readyForDeliveryStatus: number = 60;
    static readyForPickupStatus: number = 61;
    static inDeliveryOrderStatus: number = 70;
    static orderDeliveredStatus: number = 80;
    static pickupDoneStatus: number = 81;
    static orderCompletedStatus: number = 90;
    
    static listOfAllOrderStatusesInt = [
        OrderStatus.cancelledOrderStatus, 
        OrderStatus.scheduledOrderStatus, 
        OrderStatus.newOrderStatus, 
        OrderStatus.ongoingOrderStatus,
        OrderStatus.awaitingForAlternativeStatus,
        OrderStatus.alternativesSelectedStatus,
        OrderStatus.alternativesTimeoutStatus,
        OrderStatus.collectionCompletedStatus,
        OrderStatus.readyForDeliveryStatus,
        OrderStatus.readyForPickupStatus,
        OrderStatus.inDeliveryOrderStatus,
        OrderStatus.orderDeliveredStatus,
        OrderStatus.pickupDoneStatus,
        OrderStatus.orderCompletedStatus
    ]




    static getStatusFromStatusInt(statusInt: number): OrderStatus {
        let appDataContent = ((store.state as any)["partner"] as PartnerSettingsState).appDataContent;

        switch(statusInt) {
            case -1: 
                return { statusInt: OrderStatus.cancelledOrderStatus, icon: "" , color: "black", displayStatus: appDataContent.cancelledOrder, messageToShowInOrderStatusPage: appDataContent.cancelledOrderStatusInfo } as OrderStatus;
            case 5: 
                return { statusInt: OrderStatus.scheduledOrderStatus,  icon: "", color:"blue" , displayStatus:appDataContent.scheduledOrder, messageToShowInOrderStatusPage: appDataContent.newOrderStatusInfo } as OrderStatus;
            case 10: 
                return { statusInt: OrderStatus.newOrderStatus, icon: "", color:"blue", displayStatus: appDataContent.newOrder, messageToShowInOrderStatusPage: appDataContent.newOrderStatusInfo } as OrderStatus;
            case 20: 
                return { statusInt: OrderStatus.ongoingOrderStatus, icon: "", color:"orange", displayStatus: appDataContent.ongoingOrder, messageToShowInOrderStatusPage: appDataContent.ongoingOrderStatusInfo } as OrderStatus;
            case 30: 
                return { statusInt: OrderStatus.awaitingForAlternativeStatus, icon: "", color:"red", displayStatus: appDataContent.awaitingForReplacements, messageToShowInOrderStatusPage: appDataContent.awaitingForReplStatusInfo } as OrderStatus;
            case 40: 
                return { statusInt: OrderStatus.alternativesSelectedStatus, icon: "" , color:"red", displayStatus: appDataContent.replacementsReceived, messageToShowInOrderStatusPage: appDataContent.replacementsReceivedStatusInfo } as OrderStatus;
            case 50: 
                return { statusInt: OrderStatus.alternativesTimeoutStatus, icon: "" , color:"red", displayStatus: appDataContent.replacementsTimeOut, messageToShowInOrderStatusPage: appDataContent.replacementsTimeOutStatusInfo } as OrderStatus;
            case 55: 
                return { statusInt: OrderStatus.collectionCompletedStatus, icon: "" , color:"red", displayStatus: appDataContent.collectionCompleted, messageToShowInOrderStatusPage: appDataContent.collectionCompletedStatusInfo } as OrderStatus;
            case 60: 
                return { statusInt: OrderStatus.readyForDeliveryStatus, icon: "" , color:"purple", displayStatus: appDataContent.readyForDelivery, messageToShowInOrderStatusPage: appDataContent.readyForDeliveryOrderStatusInfo } as OrderStatus;
            case 61: 
                return { statusInt: OrderStatus.readyForPickupStatus, icon: "" , color:"purple", displayStatus: appDataContent.readyForPickup, messageToShowInOrderStatusPage: appDataContent.readyForPickupOrderStatusInfo } as OrderStatus;
            case 70: 
                return { statusInt: OrderStatus.inDeliveryOrderStatus, icon: "" , color:"purple", displayStatus: appDataContent.inDeliveryOrder, messageToShowInOrderStatusPage: appDataContent.inDeliveryOrderStatusInfo } as OrderStatus;
            case 80: 
                return { statusInt: OrderStatus.orderDeliveredStatus, icon: "" , color:"green", displayStatus: appDataContent.delivered, messageToShowInOrderStatusPage: appDataContent.deliveredStatusInfo } as OrderStatus;
            case 81: 
                return { statusInt: OrderStatus.pickupDoneStatus, icon: "" , color:"green", displayStatus: appDataContent.pickupDone, messageToShowInOrderStatusPage: appDataContent.pickupDoneStatusInfo } as OrderStatus;
            case 90: 
                return { statusInt: OrderStatus.orderCompletedStatus, icon: "" , color:"green", displayStatus: appDataContent.completedOrder, messageToShowInOrderStatusPage: appDataContent.completedOrderStatusInfo } as OrderStatus;
            default:
                return { statusInt: OrderStatus.cancelledOrderStatus, icon: "" , color: "black", displayStatus: appDataContent.cancelledOrder, messageToShowInOrderStatusPage: appDataContent.cancelledOrderStatusInfo } as OrderStatus;

        }
    }

    static getListOfOrderStatuses(): OrderStatus[] {        
        return OrderStatus.listOfAllOrderStatusesInt.map(status => OrderStatus.getStatusFromStatusInt(status));        
    }
    
}


export class OrderParentStatus {
    id: number = 0;
    displayStr: string = "";
    listOfStatuses: OrderStatus[] = [];

    /////////////////////////////
    /// Parent Order Statuses //
    ///////////////////////////

    static ongoingOrdersParentStatus: number = 2;
    static completedOrdersParentStatus: number = 3;
    static cancelledOrdersParentStatus: number = 4;

    // onging orders parent status
    static listOfOngoingOrdersStatusesInt: number[] = [
        OrderStatus.scheduledOrderStatus,
        OrderStatus.newOrderStatus, 
        OrderStatus.ongoingOrderStatus,
        OrderStatus.awaitingForAlternativeStatus,
        OrderStatus.alternativesTimeoutStatus,
        OrderStatus.alternativesSelectedStatus,
        OrderStatus.collectionCompletedStatus,
        OrderStatus.readyForDeliveryStatus,
        OrderStatus.readyForPickupStatus,
        OrderStatus.inDeliveryOrderStatus,
    ]
   
    // completed orders parent status
    static listOfCompletedOrdersStatusesInt = [
        OrderStatus.orderDeliveredStatus,
        OrderStatus.pickupDoneStatus,
        OrderStatus.orderCompletedStatus
    ];

    static listOfCancelledOrdersStatusesInt = [
        OrderStatus.cancelledOrderStatus
    ];


    static getParenOrderStatusByStatusInt(statusGrp: number): OrderParentStatus {
        let appDataContent = ((store.state as any)["partner"] as PartnerSettingsState).appDataContent;
    
        switch(statusGrp) {
            case 2:
                var ongoingStatuses = OrderParentStatus.listOfOngoingOrdersStatusesInt.map(status => OrderStatus.getStatusFromStatusInt(status));
                return {id: 2, displayStr: appDataContent.ongoingGrpStatus, listOfStatuses: ongoingStatuses } as OrderParentStatus;
            
            case 3:
                var completedStatuses = OrderParentStatus.listOfCompletedOrdersStatusesInt.map(status => OrderStatus.getStatusFromStatusInt(status));
                return {id: 3, displayStr: appDataContent.completedGrpStatus, listOfStatuses: completedStatuses } as OrderParentStatus;

            case 4:
                var cancelledStatuses = OrderParentStatus.listOfCancelledOrdersStatusesInt.map(status => OrderStatus.getStatusFromStatusInt(status));
                return {id: 4, displayStr: appDataContent.cancelledGrpStatus, listOfStatuses: cancelledStatuses } as OrderParentStatus;

            default:
                var ongoingStatuses = OrderParentStatus.listOfOngoingOrdersStatusesInt.map(status => OrderStatus.getStatusFromStatusInt(status));
                return {id: 2, displayStr: appDataContent.ongoingGrpStatus, listOfStatuses: ongoingStatuses } as OrderParentStatus;
        }
    }
   
}