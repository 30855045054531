import UserGender from "./UserGender";

export default class UserPersonalInfo {
    firstName: string = ""; // Firstname
    lastName: string = "";  // Lastname
    gender: UserGender = new UserGender(0, "None");  // Gender: Female/Male
    dateOfBirth!: Date; // user DoB
    age: string = "";   // User age computed on device
    password: string = "";  // pwd
    email: string = ""; 
    phoneNumber: string = "";   // phone number -> also used as username
    eWalletId: number = 0;   // E-Wallet Id
}

