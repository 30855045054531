
import Vue from "vue";
import wsaButton from "../UI/Button/wsa-button.vue";
export default Vue.extend({
  components: { wsaButton },
  props: ["icon", "title", "subtitle", "action", "onClick"],
  methods: {
    closeCart() {
      this.$emit("closeCart");
    },
  },
});
