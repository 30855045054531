import Vue from "vue";


export default Vue.extend({
  data: () => ({

    phoneNumberLengthRules: [
      (v: any) =>
        (typeof v != "undefined" &&
          v != "" &&
          v.length > 6 &&
          !/[^0-9]/g.test(v)) ||
        "This field must be max 8 numbers",
    ],
  }),

  methods: {
    checkCharsDigit(e: any) {
      if (!Number.isInteger(parseInt(e.key))) e.preventDefault();
      else return true;
    },
  },
});
