
import Vue from "vue";
import UISubtitle from "@/components/UI/Text/UISubtitle.vue";

export default Vue.extend({
  components: {
    "ui-subtitle": UISubtitle,
  },
  props: ["subtitle", "image"],
});
