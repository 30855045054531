
import StoreSettings from '@/models/StoreSettings';
import Vue from 'vue'
import { mapActions, mapState } from 'vuex';
import AlcoholWarningDialog from "../AlcoholWarningDialog.vue";
import TobaccoWarningDialog from "../TobaccoWarningDialog.vue";

export default Vue.extend({
    name: "categories-list",

    components: { 
        "alcohol-warning-dialog": AlcoholWarningDialog,
        "tobacco-warning-dialog": TobaccoWarningDialog
    },

    data: () => ({
        alcoholWarningDialog: false as boolean,
        tobaccoWarningDialog: false as boolean,
        selectedElement: {} as any,
        selectedElementRoute: {} as any,
    }),
    
    computed: {
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("product", ["categoriesAndSubCatList", "departmentsList"]),
        ...mapState("loading", ["listOfCategoriesLoading"]),

        isLowestLayerSubcategory(): boolean {
            return (this.selectedStoreDetails as StoreSettings).categorizationAndStructureLayer.isSubcategoriesLowestStructure
        },

        isDepartmentsHighestLayer(): boolean {
            return (this.selectedStoreDetails as StoreSettings).categorizationAndStructureLayer.isDepartmentsHigherStructure
        },

        dataToDisplay(): any {
            return [] as any;
        }
    },


    methods: {
        ...mapActions("product",  ["getCategories", "getDepartments"]),

        getCategoriesList() {

            if(this.isDepartmentsHighestLayer)
            {
                this.getDepartments({
                    branchId: (this as any).selectedStoreDetails.id,
                    isLight: false,
                    includeDynamicCampaigns: true
                })
            }

            else {
                if((this as any).categoriesAndSubCatList.length == 0) {
                    this.getCategories({
                        branchId: (this as any).selectedStoreDetails.id,
                        isLight: false,
                        includeDynamicCampaigns: true
                    })
                }
            }            
        },


        proceed(element: any, skipCheck: boolean = false, route: any) {
            if(skipCheck == false) {
                this.selectedElement = element;
                this.selectedElementRoute = route;

                if(element.isAlcohol == true) {
                    this.alcoholWarningDialog = true;
                }

                else if(element.isTobacco == true) {
                    this.tobaccoWarningDialog = true;
                }
            }

            else {
                this.$router.push(route)
            }
        },

    },

    mounted() {
        this.getCategoriesList();
    }
})
