
import LoginCard from '@/components/Authentication/LoginCard.vue'
import RegisterCard from '@/components/Authentication/RegisterCard.vue'
import Vue from 'vue';
import { mapState } from 'vuex';
import ForgotPasswordCard from '@/components/Authentication/ForgotPasswordCard.vue';
import MainCard from "@/components/MainDialogComponents/MainDialogCard.vue"
import ContacUsCard from '@/views/Authentication/ContactUsCard.vue'

enum EComponentToDisplay {
    loginComponent,
    registerComponent,
    forgotPasswordComponent,
    contactUsComponent
}

export default Vue.extend({
    props: ["redirectTo", "isDialogOpen", "componentToDisplayFirst"],

    components: { LoginCard, RegisterCard, ForgotPasswordCard, "main-card": MainCard, "contact-us-card": ContacUsCard},


    data: () => ({
        eComponentToDisplay: EComponentToDisplay,
        componentToDisplay: EComponentToDisplay.loginComponent as EComponentToDisplay,

        registrationPrefilledData: null as any
    }),

    computed: {
        ...mapState("partner", ["partnerSettings", "appDataContent"]),

        componentToDisplayModel(): any {
            if(this.componentToDisplayFirst && this.componentToDisplayFirst > -1)
                return this.componentToDisplayFirst;
            
            return this.componentToDisplay;
        }
    },

    methods: {

        goToregisterPage(data?: any) {
            if(typeof data.phoneNumber != "undefined") {
                this.registrationPrefilledData = data
            }
            else {
                this.registrationPrefilledData = null;
            }

            this.componentToDisplay = this.eComponentToDisplay.registerComponent
        },

        backBtnClicked() {
            if(this.componentToDisplay == this.eComponentToDisplay.loginComponent) {
                this.closeDialog();
            }

            else if(this.componentToDisplay == this.eComponentToDisplay.registerComponent || this.componentToDisplay == this.eComponentToDisplay.forgotPasswordComponent){
                this.componentToDisplay = this.eComponentToDisplay.loginComponent;
            }            
        },

        closeDialog() {
            this.$emit("closeDialog");  
            this.componentToDisplay = EComponentToDisplay.loginComponent;
        }
    }
})
