import { Module } from "vuex";
import request, { _axios } from "../../apiManager/_requestHelper";
import store, { RootState } from "..";
import { Apis } from "@/apiManager/Apis";
import StoreSettings from "@/models/StoreSettings";
import Store from "@/models/Store";
import StoreService from "@/services/storeService";

export interface StoreState {
    listOfStores: Array<Store>;
    listOfDeliveryStores: Array<Store>;
    listOfPickupStores: Array<Store>;
    selectedStoreDetails: StoreSettings;
    agnosticDeliveryStoreId: number;

}

export default <Module<StoreState, RootState>> {
    namespaced: true,

    state: {
        listOfStores: [] as Store[],
        listOfDeliveryStores: [] as Store[],
        listOfPickupStores: [] as Store[],
        selectedStoreDetails: {} as StoreSettings,
        agnosticDeliveryStoreId: 0 as number,
    },

    mutations: {
        async FETCH_LIST_OF_STORES(state, listOfStores: Array<Store>){
            state.listOfStores = listOfStores;
            state.listOfDeliveryStores = [];
            state.listOfPickupStores = [];

            state.listOfStores.forEach(storeObject => {

                // get list of delivery stores
                if(StoreService.checkIfStoreIsEligibileForDelivery(storeObject)) {
                    state.listOfDeliveryStores.push(storeObject);

                    if(state.agnosticDeliveryStoreId == 0) {
                        state.agnosticDeliveryStoreId = storeObject.id;
                    }
                }

                // get list of pickup stores
                if(StoreService.checkIfStoreIsEligibileForPickup(storeObject)) {
                    state.listOfPickupStores.push(storeObject);
                }

                // initialize stores carts
                store.dispatch("cart/initializeCart", storeObject.id);

            });        
        },

        FETCH_SELECTED_STORE_DETAILS(state, selectedStoreDetails: StoreSettings){
            state.selectedStoreDetails = selectedStoreDetails;
            
        },

        SAVE_AGNOSTIC_DELIVERY_STORE(state, storeId: number) {
            state.agnosticDeliveryStoreId = storeId
        }
    },

    actions: {
        getEligibleStores({commit}, params) {
            return request({
                commit: commit,
                method: "post",
                url: Apis.listOfDiffrentStoresURL,
                data: params,
                loaderKey: "listOfStoresLoading",
                successCallback: (listOfStores : any) => {
                    commit("FETCH_LIST_OF_STORES", listOfStores);
                }
            })
        },

        getStoreDetails({commit}, params) {
            return request({
                commit: commit,
                method: "post",
                url: Apis.singleStoreURL,
                data: params,
                loaderKey: "storeDetailsLoading",
                successCallback: (storeDetails : any) => {
                    commit("FETCH_SELECTED_STORE_DETAILS", storeDetails);
                }
            })
        },
    }
}