
import Vue, { computed } from 'vue'
import UITitle from '../Text/UITitle.vue';
import WsaTextField from './WsaTextField.vue'
export default Vue.extend({
    components: { 
        "wsa-text-field": WsaTextField, 
        "ui-title": UITitle 
    },

    props: ["textFieldModel", "fieldTitle", "placeholder"],

    computed: {

        fieldModel: {
            get(): string {
                return this.textFieldModel;
            },

            set(value: string) {
                this.$emit("changed", value);
            }
        }
    },

    model: {
        event: "changed",
        prop: "textFieldModel"
    }
    
})
