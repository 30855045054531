
import Vue from "vue";
export default Vue.extend({
  props: {
    subtitle: {
      default: "",
      required: true,
      type: String
    },
    
    toUpperCase: {
      default: true,
      required: false,
      type: Boolean
    },
  }
});
