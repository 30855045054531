import { Module } from "vuex";
import axios from "axios";
import request, { _axios } from "../../apiManager/_requestHelper";
import { RootState } from "..";
import { Apis } from "@/apiManager/Apis";

interface CusServiceState extends RootState {
  inAppMsg: {};
  complaintLoading: boolean;
  inAppMsgLoading: boolean;
}

export default <Module<CusServiceState, RootState>>{
  namespaced: true,
  state: {
    loading: false,
    inAppMsg: {},
    complaintLoading: false,
    inAppMsgLoading: false,
  },

  mutations: {
    FETCH_INAPP_MESSAGE(state, inAppMsg: any) {
      state.inAppMsg = inAppMsg;
    },
    CHANGE_COMPLAINT_LOADING_STATE(state, loading: boolean) {
      state.complaintLoading = loading;
    },
    CHANGE_INAPP_LOADING_STATE(state, loading: boolean) {
      state.inAppMsgLoading = loading;
    },
  },

  actions: {
    complaint({ commit }, params) {
      return request({
        method: "POST",
        url: Apis.contactUsMessage,
        data: params,
        commit: commit,
        loaderKey: "contactUsLoading"
      })
    },
    
    getInAppMsg({ commit }, params) {
      return request({
        commit: commit,
        method: "POST",
        url: Apis.messageAtStoreLevelURL,
        data: params,
        loaderKey: "inAppMsgLoading",
        successCallback: (inAppMsg: any) => {
          commit("FETCH_INAPP_MESSAGE", inAppMsg);
        },
      })
    },
  },
};
