
import Vue from 'vue'
import { mapState } from 'vuex';
import WsaButton from '@/components/UI/Button/wsa-button.vue';
import CloseIcon from '@/components/UI/Icons/CloseIcon.vue';

export default Vue.extend({
    props: ["dialog"],

    components: {
        "wsa-button": WsaButton,
        "close-icon": CloseIcon
    },


    computed: {
        ...mapState("partner", ["appDataContent"]),

        dialogModel: {
            get(): boolean {
                return this.dialog;
            },

            set(value: boolean) {
                this.$emit("changed", value);
            }
        },
    },

    
    model: {
        prop: "dialog",
        event: "changed"
    },

    methods: {
        onProceed() {
            this.$emit("onProceed");
            this.dialogModel = false;
        }
    }
})
