
import Vue from 'vue'
import { mapState } from 'vuex';
export default Vue.extend({

    props: {
        
        fromCart: {
            required: false,
            default: false,
            type: Boolean
        }
    },

    computed: {
        ...mapState('partner', ["partnerSettings"]),
        ...mapState("ui", ["isMobileViewDisplayed"])
    },

    methods: {
        minusBtnClicked() {
            this.$emit("decreaseQuantity");
        }
    }
})
