export enum ECurrencyRoundingType {
    Rounding001 = 0,
    Floor = 1
}

export default class Currency{
    id: number = 0;
    currencyCode: string = "";
    currencyDescription: string = "";
    isDefault: boolean = false;
    roundingType: ECurrencyRoundingType = ECurrencyRoundingType.Floor
}

