
import Vue from 'vue'
import { mapState } from 'vuex';
export default Vue.extend({

    props: {
        disabled: {
            required: false,
            default: false,
            type: Boolean
        },

        fromCart: {
            required: false,
            default: false,
            type: Boolean
        }
    },

    computed: {
        ...mapState("partner", ["partnerSettings"]),
        ...mapState("ui", ["isMobileViewDisplayed"])
    },

    methods: {
        plusBtnClicked() {
            this.$emit("increaseQuantity");
        }
    }
})
