
import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
    props:{
        width: {
            required: false,
            default: 100
        }

    },

    computed: {
        ...mapState("partner", ["partnerSettings", "appDataContent"])
    }
})
