
import User from '@/models/User';
import routerManager from "@/routerManagerNew/routerManager";
import LocationMapCard from "@/components/Map/LocationCard.vue";
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
export default Vue.extend({

    components: {
        "location-map-card": LocationMapCard
    },

    data: () => ({
        addressDialog: false as boolean
    }),
    
    computed: {
        ...mapState('partner', ["partnerSettings", "appDataContent"]),
        ...mapState('ui', ["isMobileViewDisplayed"]),

        ...mapGetters("auth", ["currentSelectedUser"]),

        /** Define user object */
        user(): User {
            return (this as any).currentSelectedUser;
        },

        /** Check if shop now button is displayed */
        isShopNowBtnDisplayed(): boolean {
            return (this as any).partnerSettings.isWsaEnabled
        },

        /** Check if google play store is displayed */
        isGooglePlayStoreBtnDisplayed(): boolean {
            return (this as any).partnerSettings.msaGooglePlayUrl != null && (this as any).partnerSettings.msaGooglePlayUrl != ''
        },

        /** Check if app store is displayed */
        isAppStoreBtnDisplayed(): boolean {
            return (this as any).partnerSettings.msaAppStoreUrl != null && (this as any).partnerSettings.msaAppStoreUrl != ''
        },
        
        /** Get google play store url */
        googlePlayStoreUrl(): string {
            return (this as any).partnerSettings.msaGooglePlayUrl
        },

        /** Get app store url */
        appStoreUrl(): string {
            return (this as any).partnerSettings.msaAppStoreUrl
        }
    },

    methods: {

        /** On shop now button click: 
         * if user's address is not defined
         * or if user's address is default
        */
        onShopNowClick() {
            if(typeof this.user.usedAddress == "undefined" || this.user.usedAddress == null || typeof this.user.usedAddress.city == "undefined" || this.user.usedAddress.isDefault == true) {
                this.addressDialog = true
            }

            else {
                routerManager.goToStoresPage(true);
            }
        },

        onAddressSubmitted() {
            routerManager.goToStoresPage();
        }
    }

})
