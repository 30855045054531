import Vue from "vue";
import App from "./App.vue";
import router from "./routerManagerNew";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "leaflet/dist/leaflet.css";
import AOS from "aos";
import "aos/dist/aos.css";
import moment from "moment-timezone";
import ItemCard from "@/components/Items/ItemCard.vue";
import CategoryCard from "@/components/Categories/CategoryCard.vue";
import VueMeta from 'vue-meta'


// for dynamic meta
Vue.use(VueMeta)

Vue.config.productionTip = false;

// #region Date and Time formatters

let tz = moment.tz.guess();

moment.locale("EN")
Vue.filter("formatDate", function(value: string) {
  if (value) {
    return moment.tz(value, tz).format("dddd, MMM DD");
  }
});

Vue.filter("formatDateWithTime", function(value: string) {
  if (value) {
    return moment.tz(value, tz).format("dddd, MMMM DD, hh a");
  }
});

Vue.filter("formatDateMinify", function(value: string) {
  if (value) {
    return moment.tz(value, tz).format("ddd, MMM DD");
  }
});

Vue.filter("formatMonthDay", function(value: string) {
  if (value) {
    return moment.tz(value, tz).format("MMM DD");
  }
});

Vue.filter("formatDay", function(value: string) {
  if (value) {
    return moment.tz(value, tz).format("ddd");
  }
});

Vue.filter("formatDateTime", function(value: string) {
  if (value) {
    return moment.tz(value, tz).format("DD MMM. hh A");
  }
});

Vue.filter("formatDayMonthDay", function(value: string) {
  if (value) {
    return moment.tz(value, tz).format("dddd, MMM DD");
  }
});

Vue.filter("formatTime", function(value: string) {
  if (value) {
    return moment.tz(value, tz).format("h:mm A");
  }
});
Vue.filter("formatHour", function(value: string) {
  if (value) {
    return moment.tz(value, tz).format("h A");
  }
});

Vue.filter("eWalletFormatDate", function(value: string) {
  if (value) {
    return moment.tz(value, tz).format("dddd, MMMM DD, hh:mm a");
  }
});

// #endregion Date and Time formatters


Vue.component("item-card", ItemCard);
Vue.component("category-card", CategoryCard);

new Vue({
  created() {
    AOS.init();
  },
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
