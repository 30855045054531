import Category from "./Category";

export default class Department {
    id: number = 0;                           // cat id -- !!!!! For Custom Cat the id is set to 0 !!!!!
    name: string = "";                      // Subcat name
    image: string = "";                     // Image url
    sortingPrio: number = 0;                  // order listing 
    categoriesList: Array<Category> = [];     // Nbre of existing items in the subcat
    isCustomCategory: Boolean = false;
    itemsCollectionType: number = 0;
    isAlcohol: boolean = false;
    isTobacco: boolean = false;
    
    static fetchDepartmentObjectFromJson(department: any): Department {
        var departmentObject = {} as Department;
        departmentObject.categoriesList = [] as Category[];

        departmentObject.id = department.id;
        departmentObject.name = department.name;
        departmentObject.image = department.image;
        departmentObject.sortingPrio = department.order;
        departmentObject.isCustomCategory = false;
        departmentObject.itemsCollectionType = 0;
        departmentObject.isAlcohol = department.isAlcohol;
        departmentObject.isTobacco = department.isTobacco;

        if(department.categories && department.categories.length > 0) { 
            department.categories.forEach((cat: any) => {
                var newCategory = new Category();
                newCategory = Category.fetchCategoryObjectFromJson(cat);
                departmentObject.categoriesList.push(newCategory);
            })
        }

        return departmentObject;
    }
}