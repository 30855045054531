import store from "@/store";
import { PartnerSettingsState } from "@/store/modules/partnerModule";
import AddressCoordinates from "./AddressCoordinates";
import AddressTag from "./AddressTag";

export default class AddressPosition {
    id: number = 0;                         
    tag: AddressTag = AddressTag.homeTag;
    streetNbre: string = "";              
    streetName: string = "";
    postalcode: string = "";
    district: string = "";
    city: string = "";
    area: string = "";
    state: string = "";
    country: string = "";
    county: string = "";
    countryCode: string = "";
    additionalAddressInfo: string = "";
    position: AddressCoordinates = new AddressCoordinates();    //includes Long and Lat & more
    tagDescription: string = "";
    isPrimary: boolean = false;
    postalCode: string = "";
    houseNumber: string = "";
    label: string = "";
    isDefault: boolean = false; // is used when it's the first time the user opens the wsa


    static generateDistanceToBeDisplayed(distance: number) : string {
        let appDataContent = ((store.state as any)["partner"] as PartnerSettingsState).appDataContent;
        
        var distanceInMeter: number = parseInt((distance*1000).toString());
    
        if((distanceInMeter).toString().length <= 3) {
          return `${distanceInMeter} ${appDataContent.storeSelectionMeterAwayTxt}`;
        } 
    
        var distanceInKM: String = (distanceInMeter*0.001).toFixed(1); // 1.2Km
    
        if((distanceInKM).length <= 5) { // taking into consideration the ".2"
          return `${distanceInKM} ${appDataContent.storeSelectionKmAwayTxt}`;
        } 
    
        return appDataContent.storeSelectionTooFarTxt;
    
      }
}