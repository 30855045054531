import Vue from "vue";
import Router from "vue-router";
import { RouteConfig } from "vue-router";
import Menu, { MetaParams } from "./menu";
import store from "@/store";
import { PartnerSettingsState } from "@/store/modules/partnerModule";
import { EPartnerStoresStructureType } from "@/enums/EPartnerStoresStructureType";
import routerManager from ".";
import { AuthenticationState } from "@/store/modules/authenticationModule";
import RoutesName from "./routes";
import Routes from "./routes";
import { StoreState } from "@/store/modules/storeModule";

Vue.use(Router);

// Get routes from menu and import them here by turn
// in this page we implement routes that takes parameters
export const routes: RouteConfig[] = [
  // {
  //   path: "*",
  //   component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
  // },

  {
    path: Routes.storeHomePage.path,
    props: true,
    name: Routes.storeHomePage.name,
    meta : { isAPartnerPage: false } as MetaParams,
    component: () =>
      import(
        /* webpackChunkName: "store-home-page" */ "@/views/StorePages/ProductsPages/StoreHomePage.vue"
     ) ,
  },
  {
    path: Routes.storeDetails.path,
    props: true,
    name: Routes.storeDetails.name,
    meta : { isAPartnerPage: false } as MetaParams,
    component: () =>
      import(
        /* webpackChunkName: "store-details" */ "@/views/StorePages/ProductsPages/StoreDetails.vue"
     ) ,
  },

  // {
  //   path: Routes.productsByCategory.path,
  //   props: true,
  //   name: Routes.productsByCategory.name,
  //   meta : { isAPartnerPage: false } as MetaParams,
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "products-by-cat" */ "@/views/ToRemove/ProductsByCatSubcat.vue"
  //    ) ,
  // },

  // {
  //   path: Routes.productsBySubCategory.path,
  //   props: true,
  //   name: Routes.productsBySubCategory.name,
  //   meta : { isAPartnerPage: false } as MetaParams,
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "products-by-cat-subcat" */ "@/views/ToRemove/ProductsByCatSubcat.vue"
  //    ) ,
  // },

  // {
  //   path: Routes.productsByCollection.path,
  //   props: true,
  //   name: Routes.productsByCollection.name,
  //   meta : { isAPartnerPage: false } as MetaParams,
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "products-by-collection" */ "@/views/ToRemove/ProductsByCatSubcat.vue"
  //    ) ,
  // },

  // {
  //   path: Routes.productsBySearchBySubcat.path,
  //   props: true,
  //   name: Routes.productsBySearchBySubcat.name,
  //   meta : { isAPartnerPage: false } as MetaParams,
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "products-by-search-by-subcat" */ "@/views/ToRemove/ProductsByCatSubcat.vue"
  //    ) ,
  // },

  // {
  //   path: Routes.productsBySearch.path,
  //   props: true,
  //   name: Routes.productsBySearch.name,
  //   meta : { isAPartnerPage: false } as MetaParams,
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "products-by-search" */ "@/views/ToRemove/SearchPage.vue"
  //    ) ,
  // },

  // {
  //   path: Routes.productsBySection.path,
  //   props: true,
  //   name: Routes.productsBySection.name,
  //   meta : { isAPartnerPage: false } as MetaParams,
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "products-by-section" */ "@/views/StorePages/ProductsPages/ProductsBySection.vue"
  //    ) ,
  // },

  {
      path: "/store/:branchName/categories",
      props: true,
      name: "Categories",
      meta : { isAPartnerPage: false } as MetaParams,
      component: () =>
        import(
          /* webpackChunkName: "Store" */ "@/views/StorePages/Categories.vue"
        ),
  },
  {
    path: Routes.checkout.path,
    props: true,
    name: Routes.checkout.name,
    meta : { isAPartnerPage: false } as MetaParams,
    component: () =>
      import(
        /* webpackChunkName: "store-checkout" */ "@/views/StorePages/Orders/Checkout.vue"
      ),
  },
  {
    path: Routes.orderSummary.path,
    props: true,
    name: Routes.orderSummary.name,
    meta : { isAPartnerPage: false } as MetaParams,
    component: () =>
      import(
        /* webpackChunkName: "order-summary" */ "@/views/StorePages/Orders/OrderSummary.vue"
      ),
  },
  // {
  //   path: Routes.notFound.path,
  //   name: Routes.notFound.name,
  //   meta : { isAPartnerPage: true } as MetaParams,
  //   component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
  // },
];

Menu.menuList.forEach((menu) => {
    if (typeof menu.route !== "undefined" && typeof menu.route !== "string")
      routes.push(menu.route as RouteConfig);
});

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {      
      resolve({ top: 0 } as any)      
    })
  },

});

router.beforeEach((to, from, next) => {
  window.scrollTo(0,0)

  if(to.name == "storeHomePage") {
    var listOfStores = ((store as any).state["store"] as StoreState).listOfStores;

    if(listOfStores.length == 0)
      routerManager.goToStoresPage();

  }


  if(to.name == 'Stores' && to.params.isEmptyPageDisplayed == 'false') {
    var partnerSettings = ((store as any).state["partner"] as PartnerSettingsState).partnerSettings;    
    if(partnerSettings != null && partnerSettings.partnerStoresStructureType == EPartnerStoresStructureType.SingleStoreModel) {
      routerManager.goToStoreHomePage();
    }

    else {
      next();
    }

  }

  else if(to.name == RoutesName.checkout.name) {
    var listOfUsers = ((store as any).state["auth"] as AuthenticationState).listOfUsers;
    var currentUser = ((store as any).state["auth"] as AuthenticationState).currentUser;

    if(listOfUsers[currentUser].isUserLoggedIn == true) {
      next();
    } 
  }

  else next();
})

export default router;
