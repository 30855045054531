import { Module } from "vuex";
import { RootState } from "..";
import { Snackbar, CartSnackbar } from "@/models/UI";

export interface UIState {
  snackbar: Snackbar;
  cartSnackbar: CartSnackbar;
  isSmScreen: boolean;
  isMobileViewDisplayed: boolean;
}

export default <Module<UIState, RootState>>{
  namespaced: true,
  state: {
    snackbar: {
      show: false,
      error: false,
      message: "",
    },
    cartSnackbar: {
      show: false,
      error: false,
      message: "",
    },
    isSmScreen: false,
    isMobileViewDisplayed: true
  } as UIState,

  mutations: {
    snackbar(state: UIState, snackbar: Snackbar) {
      state.snackbar = snackbar;
    },
    cartSnackbar(state: UIState, snackbar: CartSnackbar) {
      state.cartSnackbar = snackbar;
    },

    SET_SCREEN_SIZE(state, isSmScreen) {
      state.isSmScreen = isSmScreen;
    },
    
    SET_MOBILE_VIEW(state, isMobileViewDisplayed: boolean) {
      state.isMobileViewDisplayed = isMobileViewDisplayed;
    },
  },
};
