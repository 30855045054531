
import Vue from 'vue'
import HeaderBar from "@/components/UI/HeaderBar/UIHeaderBar.vue";
import _requestHelper from '@/apiManager/_requestHelper';
import { Apis } from '@/apiManager/Apis';
import { mapGetters, mapState } from 'vuex';
import User from '@/models/User';
import UIMoneyFormat from '@/components/UI/Money/UIMoneyFormat.vue';
import EmptyCart from '@/components/Cart/EmptyCart.vue';

export default Vue.extend({
    components: {
        "header-bar": HeaderBar,
        "ui-money-format": UIMoneyFormat,
        EmptyCart
    },

    data: () =>  ({
        eWalletDetails: [] as any,

        transactionType: {
            1: 'Purchase',
            2: 'Reload',
            3: 'Transfer',
            4: 'Refund',
            5: 'Refund',
            6: 'Fee',
            7: 'Cashback',
            8: 'Subscription',
            9: 'TopUp',
            10: 'PromotionOrVoucher',
            11: 'CurrencyConversion',
            12: 'Inactivity',
            13: 'Withdrawal'
        }        
    }),    

    computed: { 
        ...mapGetters("auth", ["currentSelectedUser"]),
        ...mapState("partner", ["partnerSettings", "appDataContent"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),

        user(): User {
            return (this as any).currentSelectedUser;
        }
    },

    methods: {
        getWalletTransactions() {
            _requestHelper({
                method: "POST",
                url: Apis.ewalletTransactions,
                data:  {
                    walletId: this.user.personalInfo.eWalletId
                },
                loaderKey: "",
                successCallback: (details: any) => {
                    this.eWalletDetails = details;
                }
             })
        },

        getTransactionStatus(transaction: any): any {
            var statusToReturn = {} as any;
            
            switch (transaction.transactionType) {
                case 1: 
                    statusToReturn = {
                        isPositive: false
                    };
                    break;
                
                case 2: 
                    statusToReturn = {
                        isPositive: true
                    };
                    break;
                
                case 3: 
                    statusToReturn = {
                        isPositive: true
                    };
                    break;
                
                case 4: 
                    statusToReturn = {
                        isPositive: true
                    };
                    break;
                
                case 5: 
                    statusToReturn = {
                        isPositive: true
                    };
                    break;
                
                case 6: 
                    statusToReturn = {
                        isPositive: false
                    };
                    break;
                
                case 7: 
                    statusToReturn = {
                        isPositive: true
                    };
                    break;
                
                case 8: 
                    statusToReturn = {
                        isPositive: false
                    };
                    break;
                
                case 9: 
                    statusToReturn = {
                        isPositive: true
                    };
                    break;
                
                case 10: 
                    statusToReturn = {
                        isPositive: true
                    };
                    break;
                
                case 11: 
                    statusToReturn = {
                        isPositive: false
                    };
                    break;
                
                case 12: 
                    statusToReturn = {
                        isPositive: false
                    };
                    break;

                case 13: 
                    statusToReturn = {
                        isPositive: false
                    };
                    break;

                default:
                    statusToReturn = {
                        isPositive: false
                    };
                    break;
            };

            return statusToReturn;
        }
    },

    mounted() {
        this.getWalletTransactions();
    }
})
