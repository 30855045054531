import { Module } from "vuex";
import request, { _axios } from "../../apiManager/_requestHelper";
import store, { RootState } from "..";
import { Apis } from "@/apiManager/Apis";
import Order from "@/models/Order";
import OrderType from "@/models/OrderType";
import ScheduleSlot from "@/models/ScheduleSlot";

interface OrderState extends RootState {
  listOfOrders: [];
  orderTypes: {};
  paymentMethodes: {};
  ordersListStatuses: object[];
  singleOrder: Order;
  promoCodeValidation: {};
  isPromoCodeValidated: boolean;
  orderTypeInfo: Object[];
  paymentTypeInfo: Object[];
  newOrderPlacedObject: Object;
  orderType: OrderType;
  firstAvailableDeliverySlot: ScheduleSlot;
  firstAvailablePickupSlot: ScheduleSlot;

  // LOADING STATE FOR EACH REQUEST
  listOfOrdersLoading: boolean;
  placeOrderLoading: boolean;
  singleOrderLoading: boolean;
  promoCodeValidationLoading: boolean;

  serviceFeesInclTax: number;
  serviceFeesTaxValue: number;

}

export default <Module<OrderState, RootState>>{
  namespaced: true,
  state: {
    loading: false,
    listOfOrders: [],
    singleOrder: {} as Order,
    promoCodeValidation: {},
    isPromoCodeValidated: false,
    newOrderPlacedObject: {} as any,
    orderType: {} as OrderType,
    firstAvailableDeliverySlot: {} as ScheduleSlot,
    firstAvailablePickupSlot: {} as ScheduleSlot,
    serviceFeesInclTax: 0,
    serviceFeesTaxValue: 0,

    // LOADING STATE FOR EACH REQUEST
    listOfOrdersLoading: false,
    singleOrderLoading: false,
    placeOrderLoading: false,
    promoCodeValidationLoading: false,

    /** Order statuses */


    status: [
      { text: "Scheduled", value: [5] },
      { text: "Now", value: [10] },
      { text: "Ongoing", value: [20, 40] },
      { text: "Pending", value: [30, 50] },
      { text: "Collected", value: [55] },
      { text: "Ready For Delivery", value: [60] },
      { text: "Ready For Pickup", value: [61] },
      { text: "In Delivery", value: [70] },
      { text: "Done", value: [80, 81] },
      // { text: "Pick-up Done", value: [81] },
      { text: "Completed", value: [90] },
      { text: "Cancelled", value: [-1] },
    ],

    ordersListStatuses: [
      { text: "In Progress", value: [5, 10, 20, 30, 40, 50, 55, 60, 61, 70] },
      { text: "Completed", value: [80, 81, 90] },
      { text: "Canelled", value: [-1] },
    ],

    /** Order Types */
    orderTypes: {
      1: "Delivery",
      2: "Pickup",
    },
    orderTypeInfo: [
      { text: "Delivery", value: 1 },
      { text: "Pickup", value: 2 },
    ],
    paymentTypeInfo: [
      { text: "Cash", value: 1 },
      { text: "Credit Card", value: 2 },
    ],

    paymentMethodes: {
      0: "Unknown",
      1: "Cash",
      2: "Credit Card",
    },
  },

  mutations: {
    /** Fetching new order details */
    FETCH_ORDERS_LIST(state, listOfOrders) {
      state.listOfOrders = listOfOrders;
    },

    FETCH_NEW_ORDER_PLACED(state, order: any) {
      state.newOrderPlacedObject = order;
    },

    /** order by id */
    FETCH_SINGLE_ORDER(state, singleOrder: any) {
      state.singleOrder = Order.fetchOrderObjectFromJson(singleOrder);
    },

    /** Promo code validation info */
    FETCH_PROMO_VALIDATION(state, promoCodeValidation) {
      state.promoCodeValidation = promoCodeValidation;
      state.isPromoCodeValidated = true;      
    },

    CLEAR_PROMOTION(state) {
      state.promoCodeValidation = {};
      state.isPromoCodeValidated = false;      
    },

    FETCH_FIRST_AVAILABLE_SLOTS(state, firstAvailableSlots) {
      if(typeof firstAvailableSlots.deliverySlot != 'undefined')
        state.firstAvailableDeliverySlot = ScheduleSlot.fetchScheduleSlotObjectFromJson(firstAvailableSlots.deliverySlot);

      if(typeof firstAvailableSlots.pickupSlot != 'undefined')
        state.firstAvailablePickupSlot = ScheduleSlot.fetchScheduleSlotObjectFromJson(firstAvailableSlots.pickupSlot);
    },

    FETCH_SERVICE_FEE_AMOUNT(state, serviceFeeResult) {
      state.serviceFeesInclTax = serviceFeeResult.serviceFeesInclTax;
      state.serviceFeesTaxValue = serviceFeeResult.serviceFeesTaxValue;
    },

  },
  actions: {
    /** Place new order request */
    place({ commit }, params) {
      return request({
        commit: commit,
        method: "post",
        url: Apis.placeOrderSubURL,
        loaderKey: "placeOrderLoading",
        data: params,
        successCallback: (order: any) => {
          commit("FETCH_NEW_ORDER_PLACED", order)
        }
      })
    },

    /** Get list of orders of user */
    list({ commit }, params) {
      return request({
        commit: commit,
        method: "post",
        url: Apis.getUserOrderSubURL,
        data: params,
        loaderKey: "listOfOrdersLoading",
        successCallback: (newOrder: any) => {
          commit("FETCH_ORDERS_LIST", newOrder);
        },
      })
    },

    /** Get single order by id*/
    singleOrderById({ commit }, params) {
      return request({
        method: "POST",
        url: Apis.getSingleOrderById,
        data: params,
        loaderKey: "orderSummaryLoading",
        successCallback: (singleOrder: any) => {
          commit("FETCH_SINGLE_ORDER", singleOrder);
        },
      })
    },

    /** Validate Promocode */
    validatePromoCode({ commit }, params) {
      return request({
        commit: commit,
        method: "POST",
        url: Apis.promoCodeValidation,
        data: params,
        loaderKey: "promoCodeValidationLoading",
        successCallback: (promoCodeValidation: any) => {
          commit("FETCH_PROMO_VALIDATION", promoCodeValidation);
        },
      })       
    },

    getFirstAvailableSlots({ commit}, params) {
      return request({ 
        url: Apis.getFirstAvlScheduleSlotStoreHomePage,
        method: "post",
        data: params,
        commit: commit,
        loaderKey: "firstAvailableSlotsLoading",
        successCallback: (firstAvailableSlots: any) => {
          commit("FETCH_FIRST_AVAILABLE_SLOTS", firstAvailableSlots);
        }
       })
    },

    getServiceFeeAmount({ commit}, params) {
      return request({ 
        url: Apis.serviceFeeAmount,
        method: "post",
        data: params,
        commit: commit,
        loaderKey: "serviceFeeLoader",
        successCallback: (serviceFeeResult: any) => {
          commit("FETCH_SERVICE_FEE_AMOUNT", serviceFeeResult);
        }
       })
    },


  },
};
