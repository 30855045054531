import Store from "@/models/Store";
import Router from "./index"
import Category from "@/models/Category";

/**
 * Base function to navigate through routers
 * @param routeName 
 * @param params 
 */
function gotToRoute(routeName: string, params?: any){
    var routeObject = {} as any;
    routeObject.name = routeName;

    if(params != null || typeof params != 'undefined') {
        routeObject.params = params

        if(params.query)
            routeObject.query = params.query

        if(!routeObject.query) {
            routeObject.query = {};
        }
        
        routeObject.query["_r"] = Date.now();
    }    

    Router.replace(routeObject).catch(err => {}) // TODO: to be handled;    
}



function goToStoresPage(navigateToDeliveryStoreDirectly: boolean = false) {
    gotToRoute("storesPage", {
        navigateToDeliveryStoreDirectly: navigateToDeliveryStoreDirectly
    });
}

function goToStoreHomePage(store: Store) {
    gotToRoute("storeHomePage", {
        storeId: store.id,
        storeDisplayName: store.displayName
    });
}

function goToProductsByCategory(category: Category) {    
    gotToRoute("productsPageByCategory", {
        categoryId: category.id,
        categoryName: category.name
    });
}

function goToProductsBySearch(search: string) {    
    gotToRoute("productsPageBySearch", {
        query: { q: search}
    });
}

function goToCheckoutPage() {    
    gotToRoute("Checkout");
}

function goToOrderSummary(orderId: number) {    
    gotToRoute("OrderSummaryPage", {
        orderId: orderId
    });
}

function goToOrdersPage() {    
    gotToRoute("OrdersPage");
}

function goToProfilePage() {    
    gotToRoute("ProfilePage");
}

function goToWalletPage() {    
    gotToRoute("WalletPage");
}

function goToLoyaltyPage() {    
    gotToRoute("LoyaltyPage");
}

function goToItemsBySectionPage(section: string, index: number) {
    var params = {
        section: section,
        sectionId: index
    } as any;

    gotToRoute("productsBySection", params);   
}


function goToProductsByBanner(campaignId: number, itemsCollectionType: number, campaignName: string) {
    gotToRoute("productsPageByCampaign", {       
        campaignId: typeof campaignId == "undefined" ? itemsCollectionType : campaignId,
        campaignName: campaignName,
        campaignCollectionType: itemsCollectionType,
        title: campaignName,
    })
}


var routerManager = {
    gotToRoute,
    goToStoresPage,
    goToStoreHomePage,
    goToProductsByCategory,
    goToCheckoutPage,
    goToProductsBySearch,
    goToOrdersPage,
    goToOrderSummary,
    goToProfilePage,
    goToWalletPage,
    goToLoyaltyPage,
    goToItemsBySectionPage,
    goToProductsByBanner
}

export default routerManager;