
import Vue from 'vue'
export default Vue.extend({
    props: {
        // items to be displayed
        items: {
            required: true
        },
    },

    data: () => ({
        listOfImagesChunks: [] as any
    }),

    methods: {
        splitImagesIntoChunks(chunkSize: number = 3) {            
            // Loop through the original array with a step of 3
            for (let i = 0; i < (this.items as string[]).length; i += chunkSize) {
                // Use slice to get a subarray of chunkSize elements
                let subarray = (this.items as string[]).slice(i, i + chunkSize);
                
                // Push the subarray to the result array
                this.listOfImagesChunks.push(subarray);
            }
        }
    },

    mounted() {
        this.splitImagesIntoChunks();
    }
})
