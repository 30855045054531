import { Apis } from "@/apiManager/Apis";
import _requestHelper from "@/apiManager/_requestHelper";
import Cart from "@/models/Cart";
import OrderType from "@/models/OrderType";
import ScheduleSlot from "@/models/ScheduleSlot";
import User from "@/models/User";
import store from "@/store";
import { AuthenticationState } from "@/store/modules/authenticationModule";
import { StoreState } from "@/store/modules/storeModule";

export default class ScheduleService {
    public static async getFirstAvailableSlots(): Promise<any | null>  {
        var selectedStoreDetails = ((store as any).state["store"] as StoreState).selectedStoreDetails;
        
        if(selectedStoreDetails != null && typeof selectedStoreDetails != "undefined") {

            var currentUserObject: User = ((store as any).state["auth"] as AuthenticationState).listOfUsers[((store as any).state["auth"] as AuthenticationState).currentUser];
            var currentUserCart: Cart = (store as any).getters["cart/currentSelectedCart"];        

            var payload = {
                branchId: selectedStoreDetails.id,
                items: currentUserCart == null || typeof currentUserCart == "undefined" ? [] : currentUserCart.items,
                orderType: OrderType.unknowOrderTypeInt,
                address : {
                    tag: 0,
                    streetNumber: currentUserObject.usedAddress ? currentUserObject.usedAddress.streetNbre : "",
                    streetName: currentUserObject.usedAddress ? currentUserObject.usedAddress.streetName : "",
                    postalCode: currentUserObject.usedAddress ? currentUserObject.usedAddress.postalCode : "",
                    city: currentUserObject.usedAddress ? currentUserObject.usedAddress.city : "",
                    area: currentUserObject.usedAddress ? currentUserObject.usedAddress.area : "",
                    state: currentUserObject.usedAddress ? currentUserObject.usedAddress.state : "",
                    country: currentUserObject.usedAddress ? currentUserObject.usedAddress.country : "",
                    countryCode: currentUserObject.usedAddress ? currentUserObject.usedAddress.countryCode : "",
                    additionalAddressInformation: currentUserObject.usedAddress ? currentUserObject.usedAddress.additionalAddressInfo : "",
                    coordinatesLongitude: currentUserObject.usedAddress ? currentUserObject.usedAddress.position.longitude : 0,
                    coordinatesLatitude: currentUserObject.usedAddress ? currentUserObject.usedAddress.position.latitude : 0,
                },
                userDeliveryZoneId: typeof selectedStoreDetails.deliveryZone != "undefined" ? selectedStoreDetails.deliveryZone.id : 0,
                userPickupZoneId: typeof selectedStoreDetails.pickupZone  != "undefined" ? selectedStoreDetails.pickupZone.id : 0,
                includePickUp: true, 
                includeDelivery: true,
                requestedFrom: 1
            }

            var result = {
                deliverySlot: {} as ScheduleSlot,
                pickupSlot: {} as ScheduleSlot
            }

            await _requestHelper({
                url: Apis.getFirstAvlScheduleSlotStoreHomePage,
                method: "POST",
                data: payload,
                loaderKey: "",
                successCallback: (res: any) => {
                    result = {
                        deliverySlot: ScheduleSlot.fetchScheduleSlotObjectFromJson(res.deliverySlot),
                        pickupSlot: ScheduleSlot.fetchScheduleSlotObjectFromJson(res.pickupSlot)
                    }
                }
            })

            return result;
        }
    }
}