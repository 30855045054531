import store from "@/store";
import { ProductState } from "@/store/modules/productModule";
import Category from "./Category";

export default class Subcategory {
    id: number = 0;                     // Id of Subcat
    name: string = "";                // Subcat name
    image: string = "";               // Subcat Image 
    nbreOfItemsInSubCat: number = 0;    // Nbre of existing items in the subcat
    subCatPrio: number = 0;             // Listing Prio
    isAlcohol: boolean = false;             // bool to say if the subcat is Alcohol
    isTobacco: boolean = false;             // bool to say if the subcat is Tobacco
    isWeightVariable: boolean = false; 

    fetchSubcategoryObjectFromJson(subcategory: any) : Subcategory {
        var subcategoryObject = {} as Subcategory;
        
        subcategoryObject.id = subcategory.id;
        subcategoryObject.name = subcategory.name;
        subcategoryObject.image = subcategory.image;
        subcategoryObject.isAlcohol = subcategory.isAlcohol;
        subcategoryObject.isTobacco = subcategory.isTobacco;
        subcategoryObject.isWeightVariable = subcategory.isVariableWeight;
        subcategoryObject.subCatPrio = subcategory.order;
        subcategoryObject.nbreOfItemsInSubCat = subcategory.itemsCount;

        return subcategoryObject;
    }

    static getSubcategoryFromCatAndSubCatId(categoryId: number, subcatId: number): Subcategory{
        let listOfCatsAndSubcats = ((store as any).state['product'] as ProductState).categoriesAndSubCatList;
        var category = listOfCatsAndSubcats.find((cat: Category) => cat.id == categoryId);
        var subcategory = {} as Subcategory;
        if(category != null && typeof category.subcatList != 'undefined') {
            subcategory = category.subcatList.find((subcat: Subcategory) => subcat.id == subcatId) as Subcategory;
        }

        return subcategory;
    }
}