
import BrandCard from '@/components/Brand/BrandCard.vue';
import SubcategoryCard from '@/components/Categories/SubcategoryCard.vue';
import department from '@/components/Departments/DepartmentCard.vue';
import Vue from 'vue'
import { mapState } from 'vuex';
import UITitle from '../Text/UITitle.vue';

export default Vue.extend({
    components: { 
        "ui-title": UITitle,
        "subcategory-card": SubcategoryCard,
        "department-card": department,
        "brand-card": BrandCard,
    },

    props: {
        // items to be displayed
        items: {
            required: true
        },

        itemType: {
            required: true,
            type: Number,
            default: 1        
        },

        elementWidth: {
            required: false,
            type: String,
            default: '0'
        },  

        title: {
            required: false,
            type: String,           
        },
        
        loading: {
            required: false,
            default: false,
            type: Boolean
        }
    },

    computed: {        
        ...mapState('ui', ["isMobileViewDisplayed"]),

        style(): string { 
            var elementWidthToUse = "1fr";

            if(this.elementWidth != '0') { 
                elementWidthToUse = this.elementWidth + 'px';
            }
            
            if(this.itemType == 1 || this.itemType == 4) {
                elementWidthToUse = this.categoryElementWidth;
            }

            if(this.itemType == 5) {
                elementWidthToUse = this.brandsElementWidth;
            }
        
            return `
                grid-template-columns: repeat(auto-fill, minmax(${elementWidthToUse}, 1fr);
            
            `
        },

        categoryElementWidth(): string {
            return (this as any).isMobileViewDisplayed == true ? "80px" : "100px"
        },

        categoryElementHeight(): string {
            return (this as any).isMobileViewDisplayed == true ? "80px" : "100px"
        },

        brandsElementWidth(): string {
            return (this as any).isMobileViewDisplayed == true ? "80px" : "100px"
        },

        brandsElementHeight(): string {
            return (this as any).isMobileViewDisplayed == true ? "80px" : "100px"
        }
    }
})
