export class Settings {
  public static partner_id = 1;
  public static partner_name = "La Valeur";
  public static partner_name_normalized = "valeur";
  public static client_id = "ospclient";
  public static client_secret = "secret";
  public static scope = "ospapi";
  public static grant_type = "phonenumbergrant";
  public static is_new_user = false;
  public static device_type = "web";
  public static old_fcm_token = "";
  public static new_fcm_token =
    "fIILKkAuzOg:APA91bGJxn9R18NPmcl5XkC8WEDWYq2eYhQdgode89AekqQhG32SVaDjvrsUcGS6Y1Y2Ln6TxtELuOhcbKlmaI5rEU3P7AHg9AoGYOEWnYkp1vKQxwMc4nGkeSV3ClwBJvaujPfgUqI2";
}

export class MapSettings {
  public static url: string = "http://{s}.tile.osm.org/{z}/{x}/{y}.png";
  public static lat: number = 33.8547;
  public static lng: number = 35.8623;
  public static zoom: number = 15;

  // default location (map center)
  public static initCameraLat: Number = 35.892077; // Init Camera Position LAT
  public static initCameraLong: Number = 14.447579; // Init Camera Position LONG

  // Google Maps Settings
  public static mapsZoom: Number = 18; // Google Maps Zoom
  public static kGoogleApiKey: String =
    "AIzaSyBP3jiSt800fsP8Sk-qSrVrTl9oz3ZQu9g"; // Google Maps Key
  public static googlePlacesApiKey: String =
    "AIzaSyBiUG2E2OdNpxf7ORXZ7khgIWi7b0xr28w"; // used for places only

  // Here Maps API Settings
  public static hereApiKey: String =
    "gWpUR4UYdjUNMXFV7CL-zjoYMEDbUpmk0IYbLXCmu-o"; // used for reverse Geocoding
  public static hereRequestLanguage: String = "en"; // used for the language in response
}

export class ImageSettings {
  public static baseItemsUrl: string = `https://suppystorage.blob.core.windows.net/${Settings.partner_name_normalized}/items`;
  public static baseSubcatUrl: string = `https://suppystorage.blob.core.windows.net/${Settings.partner_name_normalized}/subcategories/${Settings.partner_id}`;
}
