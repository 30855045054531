
import UITitle from "@/components/UI/Text/UITitle.vue";
import Vue from 'vue'
import { mapState } from 'vuex'
import Currency from '@/models/Currency';
import CloseIcon from "../UI/Icons/CloseIcon.vue";

export default Vue.extend({
    components: {
        "ui-title": UITitle,
        "close-icon": CloseIcon,
    },

    props: ["selectedPaymentCurrency", "selectedPaymentMethod", "paymentCurrencyToCompare"],

    computed: {
        ...mapState("partner", ["appDataContent"]),
        
        chosenPaymentCurrency: {
            get(): Currency {
                return this.selectedPaymentCurrency;
            },

            set(value: Currency) {
                this.$emit("changed", value);
            }
        }
    },

    model: {
        event: "changed",
        prop: "selectedPaymentCurrency"
    },


    methods: {
        setSelectedPaymentCurrencyType(paymentMethod: Currency) {
            this.chosenPaymentCurrency = paymentMethod;
            this.$emit("closeDialog");
            this.$emit("clicked");
        },  

        closeDialog() {
            this.$emit('closeDialog');
            this.$emit('closeDialogWithoutEvent');
        }

    },




})
