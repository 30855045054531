import store from "@/store";
import { PartnerSettingsState } from "@/store/modules/partnerModule";

export default class OrderType {
    typeInt: number = 0;    // Int value share with server
    icon: string = "";  // Order type icon
    displayStr: string = "";    // Order type str name

    static unknowOrderTypeInt: number = 0;
    static deliveryOrderTypeInt: number = 1;
    static pickupOrderTypeInt: number = 2;
    // static unknowOrderTypeInt: number = 3;
    static otherOrderTypeInt: number = 4;
    
    // get order type for order type int
    static getOrderTypeFromInt(orderTypeInt: number) : OrderType{
        let appDataContent = ((store.state as any)["partner"] as PartnerSettingsState).appDataContent;

        //   orderTypeInt 0 --> unknown
        //   orderTypeInt 1 --> delivery
        //   orderTypeInt 2 --> pickup
        //   orderTypeInt 3 --> other        

        switch(orderTypeInt) {
            case 0:
                return {typeInt: 0, displayStr: appDataContent.orderTypeUnknownTitle} as OrderType;
            
            case 1:
                return {typeInt: 1, displayStr: appDataContent.orderTypeDeliveryTitle} as OrderType;
                
            case 2: 
                return {typeInt: 2, displayStr: appDataContent.orderTypePickupTitle} as OrderType;

            case 3: 
                return {typeInt: 3, displayStr: appDataContent.orderTypeInStoreTitle} as OrderType;

            case 4: 
                return {typeInt: 4, displayStr: appDataContent.orderTypeOtherTitle} as OrderType;

            default: 
                return {typeInt: 0, displayStr: appDataContent.orderTypeUnknownTitle} as OrderType;
        }
    }
}  