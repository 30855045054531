
import { mapState } from "vuex";
import Vue from "vue";
import UIMoneyFormat from "@/components/UI/Money/UIMoneyFormat.vue";
import UIButton from "@/components/UI/Button/UIButton.vue";
import Card from "@/components/UI/Card/CardRounded.vue";
export default Vue.extend({
  components: { "money-format": UIMoneyFormat, UIButton, card: Card },

  props: [
    "subTotal",
    "currentBranchInfo",
    "isFreeDelivery",
    "orderTotalAmount",
    "user",
    "promoCode",
  ],

  computed: {
    ...mapState("order", [ "placeOrderLoading", "promoCodeValidationLoading", "promoCodeValidation" ]),

    promoCodeValue: {
      get(): string {
        return this.promoCode;
      },
      set(value: string) {
        this.$emit("changed", value);
      },
    },
  },
  model: {
    prop: "promoCode",
    event: "changed",
  },
  methods: {
    // emit place order event
    placeOrder() {
      // if (this.currentCart.items.length != 0) this.$emit("placeOrder");
      // else
      //   this.$store.commit(
      //     "ui/snackbar",
      //     {
      //       show: true,
      //       error: true,
      //       message: "Your cart is empty!",
      //     },
      //     { root: true }
      //   );
    },
  },
});
