
import Vue from "vue";
export default Vue.extend({
  name:"ui-text-field",

  props: {
    id: {
      required: false,
      type: String
    },

    placeholder: {
      required: true,
      type: String
    },
    
    text: {
      required: true,
      type: String
    },

    disabled: {
      required: false,
      default: false,
      type: Boolean
    },
    
    readonly: {
      required: false,
      default: false,
      type: Boolean
    },
    
    clearable: {
      required: false,
      default: false,
      type: Boolean
    },

    rule: {
      required: false,    
    },

    lightMode: {
      required: false,
      type: Boolean,
      default: false
    }, 

    prefix: {
      required: false,
      type: String,
      default: ""
    },

    maxlength: {
      required: false,
      type: String,      
    },

    type: {
      required: false,
      type: String,
      default: "text"
    }
  },

  data: () => ({
    isPasswordVisisble: false,
  }),

  computed: {
    
    inputText: {
      get(): string {
        return this.text;
      },
      set(value: string) {
        this.$emit("changed", value);
      },
    },
  },

  methods: {
    onKeyPress(e: any) {
      this.$emit("onKeyPress", e)
    },

    onKeyUp(e: any) {
      this.$emit("onKeyUp", e)
    },
  },

  model: {
    prop: "text",
    event: "changed",
  },
});
