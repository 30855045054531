import { EStoreType } from "@/enums/EStoreType";
import store from "@/store";
import { StoreState } from "@/store/modules/storeModule";
import AddressCoordinates from "./AddressCoordinates";
import AddressPosition from "./AddressPosition";
import Currency from "./Currency";
import PaymentMethod from "./PaymentMethod";
import StoreDeliveryZone from "./StoreDeliveryZone";
import StorePickupZone from "./StorePickupZone";
import { BranchCategorizationsAndLayersStructureService } from "./StoreCategorizationsAndLayersStructure";

export default class StoreSettings {
    id: number = 0; // branch Id
    displayName: string = "";   // branch name      
    identifier: string = "";    // Branch identifier
    address: AddressPosition = new AddressPosition();   // address
    phoneNumbers: Array<string> = [];    // Phone number list
    whatsAppNumbers: Array<string> = []; // WhatsApp Number List
    hasDeliveryService: boolean = false;    // delivery service enabled?
    hasPickupService: boolean = false;  // takeaway service enabled?
    isPhysicalStore: boolean = false;   // tells if the user can go and shop physically in there (Showroom Available)
    isCustomerEligibleForPickup: boolean = false;   // is Customer elligible for pickup
    isCustomerElligibleForInStore: boolean = false; // is Customer elligible for inStore shopping
    isOnlineOnly: boolean = false;  // is online store only 
    distanceToShopper: number = 0;  // distance to shopper
    durationToShopper: number = 0;  // duration to shopper
    deliveryTakeawayDisplay: string = "";   // how this should be displayed in the store selection listing
    availableListOfPaymentMethods: Array<PaymentMethod> = []; // the possible/accepted payment method per Partner/store
    isOpened: boolean = false;  // is the store open or closed
    deliveryZone: StoreDeliveryZone = new StoreDeliveryZone(); // delivery zone
    pickupZone: StorePickupZone = new StorePickupZone(); // pickup zone
    isClosingSoon: boolean = false; // is closing soon
    closingOpeningTime!: Date;    // closing and opening hour
    weekOpeningHours: Array<DayOpeningHours> = [];      // week opening hours
    storeDetailsToS1: string = "";                          // store type of service 1
    storeDetailsToS2: string = "";  // store type of service 2
    storeDetailsToS3: string = "";  // store type of service 3
    storeDetailsToS4: string = "";  // store type of service 4
    storeDetailsPricingTitle: string = "";  // pricing section title
    storeDetailsPricingSubTitle: string = "";   // pricing section subtitle
    storeDetailsPricingContent: string = "";    // pricing section content
    storeDetailsAboutStoreTitle: string = "";   // about store section title
    storeDetailsAboutStoreSubTitle: string = "";    // about store section subtitle
    storeDetailsAboutStoreContent: string = ""; // about store section content
    storeDetailsDeliveryTitle: string = ""; // delivery section title
    storeDetailsDeliverySubTitle: string = "";  // delivery section subtitle
    storeDetailsDeliveryContent: string = "";   // delivery section content
    storeDetailsPickupTitle: string = "";   // pickup section title
    storeDetailsPickupSubTitle: string = "";    // pickup section subtitle
    storeDetailsPickupContent: string = ""; // pickup section content
    storeMessageTitle: string = ""; // store message title
    storeMessageContent: string = "";   // store message content
    storeMessageLastCheckDateTime!: Date;   // store message DateTime
    storeDetailsLastCheckDateTime!: Date;   // store details DateTime
    storeType: EStoreType = EStoreType.None;    // store type
    movRestrictions! : MovRestrictions; // Minimum order value restrictions
    availableListOfCurrencies: Array<Currency> = [];    // Available list of currencies
    defaultCurrency!: Currency; // Default Currency
    serviceFees: number = 0;    // servive fees
    storeStandardLogoUrl: string = "";  // store standard logo url
    storeInvertedLogoUrl: string = "";  // store alternated logo url
    storeHomepageBgImgUrl: string = ""; // Store Homepage Bg Img Url
    storeMapMarkerImgUrl: string = "";  // Store Map Marker Img Url
    isVirtual: boolean = false; // Is virtual store or not
    operatingPartnerId: number = 0; // Operating branch Partner Id (Used for virtual branches)
    storeTag: StoreTag[] = [] as StoreTag[];  
    isReusableBagsEnabled: boolean = false; // Reusable bags
    storeHomePageDrawerBackgroundImageUrl: string = "";
    categorizationAndStructureLayer: BranchCategorizationsAndLayersStructureService = new BranchCategorizationsAndLayersStructureService(BranchCategorizationsAndLayersStructureService.XXPPP)
    is24h7dService: boolean = false;

    fetchStoreDetailsObjectFromJson(storeDetails: any) : StoreSettings {
        var storeDetailsObject = {} as StoreSettings;
        storeDetailsObject.address = {} as AddressPosition;
        storeDetailsObject.address.position = {} as AddressCoordinates;
        storeDetailsObject.availableListOfPaymentMethods = [] as PaymentMethod[];
        storeDetailsObject.availableListOfCurrencies = [] as Currency[];
        storeDetailsObject.pickupZone = {} as StorePickupZone;
        storeDetailsObject.deliveryZone = {} as StoreDeliveryZone;
        storeDetailsObject.movRestrictions = {} as MovRestrictions;
        storeDetailsObject.storeTag = [] as StoreTag[];
        storeDetailsObject.weekOpeningHours = [] as DayOpeningHours[];

        storeDetailsObject.id = storeDetails.id;
        storeDetailsObject.displayName = storeDetails.displayName;
        storeDetailsObject.identifier = storeDetails.identifier;
   
        if(typeof storeDetails.deliveryZone != "undefined" && storeDetails.deliveryZone != null) {
            storeDetailsObject.deliveryZone = {
                deliveryFees: storeDetails.deliveryZone.deliveryFees,
                deliveryThreshold: storeDetails.deliveryZone.deliveryThreshold,
                freeDeliveryThreshold: storeDetails.deliveryZone.freeDeliveryThreshold,
                hasDeliveryRestrictions: storeDetails.deliveryZone.hasDeliveryRestrictions,
                hasFreeDelivery: storeDetails.deliveryZone.hasFreeDelivery,
                isCustomerEligibleForDelivery: storeDetails.deliveryZone.isCustomerElligibleForDelivery, 
                id: storeDetails.deliveryZone.id, 
                isProgrammedDeliveryEnabled: storeDetails.deliveryZone.isProgrammedDeliveryEnabled            
            };
        }
        storeDetailsObject.pickupZone = storeDetails.pickupZone;

        storeDetailsObject.distanceToShopper = storeDetails.distanceToShopper;
        
        storeDetailsObject.address.area = storeDetails.address.area;
        storeDetailsObject.address.city = storeDetails.address.city;
        storeDetailsObject.address.position.latitude = storeDetails.address.coordinatesLatitude;
        storeDetailsObject.address.position.longitude = storeDetails.address.coordinatesLongitude;
        storeDetailsObject.address.country = storeDetails.address.country;
        storeDetailsObject.address.state = storeDetails.address.state;
        storeDetailsObject.address.state = storeDetails.address.state;
        storeDetailsObject.address.streetNbre = storeDetails.address.streetNbre;



        storeDetailsObject.phoneNumbers = storeDetails.phoneNumbers ;
        storeDetailsObject.whatsAppNumbers = storeDetails.whatsAppNumbers;
        storeDetailsObject.hasDeliveryService = storeDetails.hasDeliveryService;
        storeDetailsObject.hasPickupService = storeDetails.hasTakeawayService;
        storeDetailsObject.isPhysicalStore = false;
        storeDetailsObject.isCustomerEligibleForPickup = storeDetails.isCustomerElligibleForPickup;

        storeDetailsObject.availableListOfCurrencies = storeDetails.currencies;
        storeDetailsObject.defaultCurrency = storeDetailsObject.availableListOfCurrencies.find((currency: Currency) => currency.isDefault) ?? new Currency();

        storeDetailsObject.availableListOfPaymentMethods = PaymentMethod.getListOfAvailablePaymentMethods(storeDetails.foPAndAvailableCurrencies, storeDetailsObject.defaultCurrency);
        storeDetailsObject.isOpened = storeDetails.isOpened;


        storeDetailsObject.isClosingSoon = storeDetails.isClosingSoon;
        storeDetailsObject.closingOpeningTime = new Date(storeDetails.closingOpeningTime);

        if(storeDetails.schedules != null) {
            storeDetails.schedules.forEach((schedule: any) => {
                var openingHours = {} as DayOpeningHours;
                
                openingHours.id = schedule.id;
                openingHours.openingTime = new Date(schedule.from);
                openingHours.closingTime = new Date(schedule.to);
                openingHours.isHoliday = schedule.isHoliday;
                openingHours.date = new Date(schedule.date);
                
                storeDetailsObject.weekOpeningHours.push(openingHours);
            });
        }

        
        
        storeDetailsObject.storeDetailsToS1 = storeDetails.branchContentDetails.toS1;
        storeDetailsObject.storeDetailsToS2 = storeDetails.branchContentDetails.toS2;
        storeDetailsObject.storeDetailsToS3 = storeDetails.branchContentDetails.toS3;
        storeDetailsObject.storeDetailsToS4 = storeDetails.branchContentDetails.toS4;

        storeDetailsObject.storeDetailsPricingTitle = storeDetails.branchContentDetails.pricingTitle;
        storeDetailsObject.storeDetailsPricingSubTitle = storeDetails.branchContentDetails.pricingSubTitle;
        storeDetailsObject.storeDetailsPricingContent = storeDetails.branchContentDetails.pricingContent;

        storeDetailsObject.storeDetailsAboutStoreTitle = storeDetails.branchContentDetails.aboutStoreSubTitle;
        storeDetailsObject.storeDetailsAboutStoreSubTitle = storeDetails.branchContentDetails.aboutStoreSubTitle;
        storeDetailsObject.storeDetailsAboutStoreContent = storeDetails.branchContentDetails.aboutStoreContent;

        storeDetailsObject.storeDetailsDeliveryTitle = storeDetails.branchContentDetails.deliveryTitle;
        storeDetailsObject.storeDetailsDeliverySubTitle = storeDetails.branchContentDetails.deliverySubTitle;
        storeDetailsObject.storeDetailsDeliveryContent = storeDetails.branchContentDetails.deliveryContent;

        storeDetailsObject.storeDetailsPickupTitle = storeDetails.branchContentDetails.pickupTitle;
        storeDetailsObject.storeDetailsPickupSubTitle = storeDetails.branchContentDetails.pricingSubTitle;
        storeDetailsObject.storeDetailsPickupContent = storeDetails.branchContentDetails.pricingContent;

        storeDetailsObject.storeDetailsLastCheckDateTime = new Date();
        storeDetailsObject.storeType = storeDetails.storeType;

        storeDetailsObject.movRestrictions.hasMOVRestrictions = storeDetails.movRestrictions;
        storeDetailsObject.movRestrictions.itemSubCatByTreeIdsToExcludeFromMOV = storeDetails.itemSubCatByTreeIdsToExcludeFromMOV;
        storeDetailsObject.movRestrictions.mov = storeDetails.mov; 
        storeDetailsObject.movRestrictions.subcatExcludedFromMOV = storeDetails.subcatExcludedFromMOV; 

        storeDetailsObject.serviceFees = storeDetails.serviceFees;
        storeDetailsObject.storeStandardLogoUrl = storeDetails.logoUrl;
        storeDetailsObject.storeInvertedLogoUrl = storeDetails.alternatedLogoUrl;
        storeDetailsObject.storeHomepageBgImgUrl = storeDetails.backgroundImageUrl;
        storeDetailsObject.storeMapMarkerImgUrl = storeDetails.mapMarkerImageUrl;
        storeDetailsObject.storeHomePageDrawerBackgroundImageUrl = storeDetails.wsaStoreHomePageMainDrawerBackgroundImageUrl;

        storeDetailsObject.isReusableBagsEnabled = storeDetails.isReusableBagsEnabled;
        storeDetailsObject.is24h7dService = storeDetails.is24h7dService;
        storeDetailsObject.categorizationAndStructureLayer = new BranchCategorizationsAndLayersStructureService(storeDetails.categorizationLayersAndStructure);
        
        if(storeDetails.storeTag != null) {
            storeDetails.storeTag.forEach((storeTag: any) => {
                var tag = {} as StoreTag;
                tag.imageURL = storeTag.imageURL;
                tag.description = storeTag.description;
                tag.title = storeTag.title;
                tag.id = storeTag.id;
                tag.bgImageURL = storeTag.bgImageURL;

                storeDetailsObject.storeTag.push(tag);
            });
        }

        return storeDetailsObject;
    }       
}