import Product from './Product';

export default class ProductMappingBySubcategoryAndType {
    groupId: number = 0;
    groupName: string = "";
    items: Product[] = [];


    static fetchItemMappingObjectFromJson(mappingItem: any): ProductMappingBySubcategoryAndType {
        var mapping: ProductMappingBySubcategoryAndType = {} as ProductMappingBySubcategoryAndType;
        mapping.items = [] as Product[];

        mapping.groupId = mappingItem.groupId;
        mapping.groupName = mappingItem.groupName;
        mapping.items = mappingItem.items.map((item: any) => Product.fetchItemObjectFromJson(item));

        return mapping;
    }
}